.popover > div {
    --bs-popover-max-width: auto;

}

.date_picker_container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.date_picker_container div {
    --bs-popover-max-width: 300px;
}

.popover_body {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.action_container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
