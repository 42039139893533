.container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.container:hover {
    cursor: pointer;
}

.square {
    box-sizing: content-box;
    border: 2px solid gray;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
}

.background {
    border: 0;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
}

.checkbox_label {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}
