.terms {
    margin: 30px 0;

    .term {
        height: 100px;
        border-radius: 20px !important;
        margin-bottom: 10px;
    }

    .amount {
        width: 100px;
        height: 24px;
        border-radius: 10px !important;
        margin-bottom: 10px;
    }
}
