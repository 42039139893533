.badge {
    background-color: #6362e7;
    border-radius: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    padding: 10px 10px;
}

.invert {
    background-color: white;
    color: #6362e7;
}
