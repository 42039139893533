.table {
    width: 100%;

    &__container {
        padding: 40px 25px;
    }

    thead &__row:first-child &__data {
        border-left-width: 0;
        border-top-width: 0;
    }

    thead &__row:first-child &__data:last-child {
        border-right-width: 0;
    }

    &__row:last-child &__data {
        border-bottom-width: 0;
    }

    &__row &__data:first-child {
        border-left-width: 0;
    }

    &__row &__data:last-child {
        border-right-width: 0;
    }

    &__data {
        padding: 15px 25px;
        border: 1px solid rgba(99, 98, 231, 0.7);
    }
}
