.row {
    margin-bottom: 20px;
}

.textarea {
    resize: none;
}

.input_customer {
    font-size: 1rem;
    font-weight: 400;
}

.required_star {
    color: #dd5555;
}

.image_preview {
    width: 200px;
    height: 150px;
}

.submit_button {
    font-size: 16px;
    font-weight: 500;
}
