.cards {
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    gap: 30px;
    > div,
    > span {
        flex: 1;
        min-width: 240px;
        max-width: 340px;
    }

    @media only screen and (max-width: 992px) {
        justify-content: center;
    }
}
