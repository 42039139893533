.sidebar {
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    min-width: 200px;
    min-height: 200px;
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.header {
    font-weight: 600;
}

.calendar_list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 0;
    margin: 0;
}

.add_calendar_button {
    padding: 5px 15px;
}
