.question {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 20px;
    min-height: 25vh;
    margin-bottom: 30px;
    padding: 50px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
    margin-bottom: 30px;

    .option {
        padding: 20px 34px;
        background-color: #fff;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
            background-color: #ededed;
        }

        &:active {
            background-color: #e2e2e2;
        }

        &.correct {
            background-color: #81c314;
        }

        &.wrong {
            background-color: #dd5555;
        }

        &.right_answer {
            box-shadow: inset 0 0 0 3px #81c314;
        }
    }
}
