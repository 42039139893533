.slider_container {
    margin: 20px 0 50px;
    padding: 0 130px;

    .control {
        width: 45px !important;
        height: 45px;
        border-radius: 50%;
        background-color: #fff;
        transition: 0.1s;

        &::before {
            content: none;
        }

        &:hover {
            background-color: #b3b2f3;
        }
    }

    .arrow_prev {
        transform: rotate(90deg);
    }

    .arrow_next {
        transform: rotate(270deg);
    }

    .prev {
        position: absolute;
        left: -130px;
    }

    .next {
        position: absolute;
        right: -130px;
    }
}

.page {
    width: 45px !important;
    height: 45px;
    padding: 0;
    border-radius: 50%;
    background-color: #fff;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
        background-color: #aeaef1;
    }

    &.current {
        color: #fff;
        background-color: #4f4fe7;

        &:hover {
            background-color: #30308f;
        }
    }

    &.answered {
        background-color: #aeaef1;

        &:hover {
            background-color: #9696d0;
        }
    }
}
