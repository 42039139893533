/**=====================
     33. NAV CSS Start
==========================**/
#accordionoc {
    #collapseicon,
    #collapseicon1 {
        padding-top: 0px;
    }
    li {
        button {
            display: flex;
            align-items: center;
        }
    }
}
.nav-primary {
    &.nav.nav-tabs {
        border-bottom: 0;
        &.border-tab {
            .nav-link {
                color: $theme-body-font-color;
                &.active {
                    color: $primary-color;
                }
            }
        }
        .nav-link {
            color: $primary-color;
            border: 0;
            &.active {
                color: $white;
            }
        }
    }
}
.nav {
    &.nav-pills {
        &.border-tab {
            .nav-item {
                .nav-link {
                    color: $theme-body-font-color;
                    &.active {
                        color: $primary-color;
                    }
                }
            }
        }
        .nav-item {
            .nav-link {
                color: $primary-color;
                &.active {
                    color: $white;
                }
            }
        }
    }
}
.navs-icon.default-according.style-1 {
    li {
        button {
            position: relative;
            &:hover {
                color: var(--theme-default) !important;
            }
        }
        button[aria-expanded="true"] {
            &:before {
                right: 2px;
                top: 6px;
            }
        }
        button[aria-expanded="false"]:before {
            right: 2px;
            top: 6px;
        }
    }
}
.navs-icon {
    padding: 30px;
    li {
        padding: 9px 0;
        a {
            color: $theme-body-font-color;
            &:hover {
                color: var(--theme-default);
            }
        }
        ul {
            padding-top: 9px;
        }
    }
}
.onhover-dropdown {
    &.navs-dropdown {
        &:hover {
            .onhover-show-div {
                &:before,
                &:after {
                    right: 77px;
                    left: unset;
                }
            }
        }
    }
}
.icon-lists {
    font-family: $font-roboto, $font-serif;
}
/**=====================
     33. NAV CSS Ends
==========================**/
