.article_info {
  ul, ol {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  table {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    th, tr, td {
      padding: 15px;
      border: 1px solid rgba(99, 98, 231, 0.7);
    }
  }

  td {
    > * {
      word-break: break-word;
    }
  }
}