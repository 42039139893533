.calendar_header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 15px 30px;
}

.img {
    width: 14px;
    height: 14px;
}

.today_button {
    padding: 1px 15px;
}

.header_button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}
