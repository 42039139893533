.project_info {
    .name {
        margin: 0 0 10px;
    }

    .develop_duration {
        font-weight: 400;
    }

    .description {
        font-size: 18px;
    }

    .group_container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;

        .developer_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            .avatar_container {
                position: relative;

                .develop_avatar {
                    width: 200px;
                    height: 200px;
                }

                .manager_crown {
                    position: absolute;
                    border: 2px solid #6362e7;
                    border-radius: 50%;
                    right: 10px;
                    top: 10px;
                    background-color: white;
                    padding: 3px;
                    width: 40px;
                    height: 40px;
                }
            }

            .develop_info_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: normal;
                gap: 5px;

                .link {
                    color: inherit;
                    font-size: 24px;
                    font-weight: 500;

                    &:hover {
                        color: #6362e7;
                    }
                }

                .role {
                    font-size: 20px;
                    font-weight: 400;
                    color: #707070;
                }
            }
        }
    }
}

.skeleton {
    border-radius: 15px !important;
    height: 36px;
    &.name {
        width: 35%;
    }

    &.develop_duration {
        width: 30%;
        height: 28px;
    }

    &.description {
        height: 100px;
        margin-bottom: 10px;
    }

    &.members {
        height: 300px;
    }
}

.custom_field {
    margin-bottom: 30px;
    .name {
        width: 20%;
        height: 28px;
    }

    .value {
        height: 70px;
    }
}
