.modal {
    color: #242934;

    p {
        font-size: 18px;
    }
}

.actions {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
