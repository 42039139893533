.add_project__button {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    color: #999999;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 10px;

    &.invalid {
        border-color: #dd5555;
        transition: border 0.2s;
    }

    &:hover {
        color: #6362e7;
    }
}
