.comment_form {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    align-items: center;

    .send_button {
        padding: 10px;
    }
}
