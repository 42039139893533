/**=====================
    34. RTL CSS Start
==========================**/

html[dir="rtl"] {
    body.dark-only {
        .general-widget {
            .widget-feedback {
                ul {
                    li {
                        &:nth-child(n + 2) {
                            border-right: 1px solid $dark-card-border !important;
                        }
                    }
                }
            }
            .custom-profile {
                .card-footer > div {
                    div + div {
                        border-right: 1px solid $dark-card-border !important;
                    }
                }
            }
            .social-widget-card {
                .card-footer {
                    .row {
                        .col:nth-child(n + 2) {
                            border-right: 1px solid $dark-card-border;
                        }
                    }
                }
            }
        }
        .default-dash {
            .total-transactions {
                .report-sec {
                    .report-main {
                        border-left: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .vertical-timeline.vertical-timeline--one-column-left {
            .vertical-timeline-element-content {
                .vertical-timeline-element-content-arrow {
                    border-left: 7px solid $dark-body-background !important;
                    border-right: unset !important;
                }
            }
        }
        blockquote {
            border-right: 4px solid $dark-card-border;
            border-left: unset;
            &.text-end {
                border-left: 4px solid $dark-card-border;
                border-right: unset;
            }
            &.text-center {
                border: 0;
            }
        }
        .star-ratings {
            ul.search-info {
                li {
                    + li {
                        border-right: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .list-persons {
            .profile-mail {
                .media {
                    .media-body {
                        ul {
                            li {
                                + li {
                                    border-right: 1px solid $dark-card-border;
                                }
                            }
                        }
                    }
                }
            }
        }
        .user-profile {
            .follow {
                ul.follow-list {
                    li:nth-child(n + 1) {
                        border-left: 1px solid $dark-card-border !important;
                    }
                    li:nth-child(n + 2) {
                        border-left: unset !important;
                    }
                    li:nth-child(2) {
                        border-left: 1px solid $dark-card-border !important;
                    }
                }
            }
        }
        .blog-single {
            .comment-box {
                .comment-social {
                    li {
                        &:first-child {
                            border-left: 1px solid $dark-card-border !important;
                            border-right: unset !important;
                        }
                        &:nth-child(n + 2) {
                            border-left: unset;
                        }
                    }
                }
            }
        }
        .faq-accordion {
            .card {
                .btn-link {
                    svg {
                        margin-left: 10px;
                        margin-right: unset;
                        right: 10px;
                        left: unset;
                    }
                }
            }
        }
        .page-wrapper {
            .page-body-wrapper {
                .page-body {
                    .blog-box {
                        .blog-details-second {
                            .detail-footer {
                                ul.sociyal-list {
                                    li {
                                        &:nth-child(n + 2) {
                                            border-left: unset;
                                            border-right: 1px solid $dark-card-border;
                                        }
                                    }
                                }
                            }
                        }
                        .blog-details {
                            .blog-social {
                                li:nth-child(n + 1) {
                                    border-left: 1px solid $dark-card-border !important;
                                }
                                li:last-child {
                                    border-left: unset !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ul.list-group {
        i {
            margin-left: unset;
            margin-right: 0;
        }
    }
    .slick-slider {
        direction: ltr;
    }
    .form-select {
        background-position: left 0.75rem center;
    }
    .slick-slide {
        float: left !important;
    }
    ul.list-group {
        padding-right: 0;
        i {
            margin-right: 0;
            margin-left: unset;
        }
    }
    //timeline
    .vertical-timeline.vertical-timeline--one-column-left {
        &::before {
            left: unset;
            right: 18px;
        }
        .vertical-timeline-element-icon {
            right: 0;
            left: unset;
            svg {
                right: 50%;
                left: unset;
                margin-left: unset;
                margin-right: -12px;
            }
        }
        .vertical-timeline-element-content {
            margin-right: 60px;
            margin-left: unset;
            .vertical-timeline-element-date {
                float: right;
            }
            .vertical-timeline-element-content-arrow {
                border-left: 7px solid $light-color;
                border-right: unset;
                left: 100%;
                right: unset;
            }
        }
    }
    .vertical-timeline.vertical-timeline--two-columns {
        .vertical-timeline-element {
            &:nth-of-type(even) {
                .vertical-timeline-element-content-arrow {
                    right: auto;
                    left: 100%;
                }
            }
        }
        .vertical-timeline-element-content {
            .vertical-timeline-element-content-arrow {
                border-left: 7px solid $light-color;
                border-right: unset;
            }
        }
        .vertical-timeline-element-icon {
            svg {
                right: 50%;
                left: unset;
                margin-left: unset;
                margin-right: -12px;
            }
        }
    }
    .email-right-aside {
        .email-chek {
            .checkbox_animated {
                margin-right: 0;
            }
        }
    }
    .customers {
        ul {
            li {
                + li {
                    margin-right: -10%;
                    margin-left: unset;
                }
            }
        }
    }
    .icon-hover-bottom {
        .icon-popup {
            .close-icon {
                right: unset;
                left: 10px;
            }
        }
    }
    .login-card1 {
        .login-form {
            ul.login-social {
                li {
                    &:nth-child(n + 2) {
                        margin-right: 10px;
                        margin-left: unset;
                    }
                }
            }
            .form-group {
                .small-group {
                    .input-group {
                        &:nth-child(n + 2) {
                            margin-left: unset;
                            margin-right: 10px;
                        }
                    }
                }
                .link {
                    float: left;
                }
            }
            .btn {
                margin-left: unset;
                margin-right: auto;
            }
        }
    }
    .page-wrapper {
        .page-body-wrapper {
            footer.blockquote-footer {
                margin-right: unset;
            }
        }
    }
    blockquote {
        border-right: $blockquote-border;
        border-left: unset;
        &.text-end {
            border-left: $blockquote-border;
            border-right: unset;
        }
        &.text-center {
            border: 0;
        }
    }
    .date-range-picker {
        .react-datepicker__close-icon {
            right: unset;
            left: 0;
            padding: 0 0 0 6px;
        }
    }
    .show-hide {
        right: unset;
        left: 30px;
    }
    .footer-buttons {
        .btn.pull-left {
            float: right;
        }
    }
    .has-aux {
        .form-control {
            padding-right: 12px;
            padding-left: 2rem;
        }
    }
    .rbt-aux {
        right: unset;
        left: 0;
    }
    .scrollbar-container {
        direction: ltr;
    }
    .deni-react-treeview-item-container {
        .icon-and-text {
            .icon {
                margin-right: unset;
                margin-left: 5px;
            }
        }
    }
    .deni-react-treeview-container.classic {
        padding-right: 5px !important;
        padding-left: unset !important;
    }
    .deni-react-treeview-container.classic {
        .deni-react-treeview-item-container.classic {
            .actionButton {
                margin-right: 6px;
                margin-left: unset;
            }
        }
    }
    .deni-react-treeview-container.classic {
        .deni-react-treeview-item-container.classic.level-1 {
            padding-left: unset !important;
            padding-right: 5px !important;
        }
        .deni-react-treeview-item-container.classic.level-2 {
            padding-left: unset !important;
            padding-right: 35px !important;
        }
    }
    .left-side-header {
        .input-group {
            span {
                padding-left: 12px;
                padding-right: 20px;
            }
            input {
                padding: 10px 0 10px 20px !important;
                border-radius: 25px 0 0 25px !important;
            }
        }
    }
    form {
        &.was-validated {
            .form-select {
                background-position: left 0.75rem center, center left 2.25rem !important;
                padding-right: 12px;
                padding-left: 4.125rem;
            }
            .custom-control {
                label {
                    margin-left: unset;
                    margin-right: 6px;
                }
            }
        }
    }
    .mega-inline {
        .media {
            .radio {
                margin-right: 3px !important;
            }
        }
    }
    .tagandpills {
        .height-equal {
            .card-body {
                > div {
                    button {
                        span {
                            margin-right: 5px;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
    .dismiss-text {
        .alert-dismissible {
            .btn-close {
                top: 12px !important;
                left: 20px;
                right: unset;
                span {
                    padding: 2px 5px;
                }
            }
        }
    }
    .rating-star-wrapper {
        i {
            &:last-child {
                margin-left: unset;
                margin-right: 5px;
            }
        }
    }
    .user-profile {
        .social-list {
            .media {
                img.media {
                    margin-left: 20px;
                }
            }
        }
    }
    .chat-box {
        .chat-menu {
            padding-right: 12px;
            padding-left: unset;
        }
    }
    .ProfileCard-realName {
        .realname {
            text-align: right;
        }
    }
    .ril__toolbarLeftSide {
        padding-right: 20px;
        padding-left: 0;
    }
    .ril__toolbarRightSide {
        padding-right: 0;
        padding-left: 20px;
    }
    .cripto-chat {
        .chat {
            .right-side-chat {
                .msg-box {
                    img {
                        margin-left: unset;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .dzu-inputLabelWithFiles {
        margin-right: 30px;
        margin-left: unset;
    }
    .cke_toolbar_break {
        clear: right;
    }
    .cke_toolbar_separator {
        float: right;
    }
    .dzu-dropzone {
        .dzu-previewButton {
            margin: 0 10px 2px 0;
        }
    }
    #right-history.show {
        right: unset;
        left: 0;
    }
    .contacts-tabs {
        .nav-pills {
            .nav-link {
                .media {
                    img.img-50 {
                        margin-left: 20px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .nav-tabs {
        .dropdown-menu {
            a {
                text-align: right;
            }
        }
    }
    .listings {
        ul.list-group {
            .list-group-item-action {
                img.media-body {
                    margin-right: unset;
                    margin-left: 10px;
                }
                i {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }
        }
    }
    .default-according {
        .card {
            .card-header {
                i {
                    margin-left: 5px;
                    margin-right: unset;
                }
            }
        }
    }
    .dropdown-basic {
        .dropdown {
            &:hover {
                .dropdown-content.dropdown-menu {
                    text-align: right;
                }
            }
        }
        .dropdown.separated-btn {
            .dropdown-content {
                right: unset;
                left: 0;
            }
        }
    }
    .list-persons {
        .profile-mail {
            .media {
                img {
                    margin-left: 20px;
                    margin-right: unset;
                }
            }
        }
    }
    .bookmark-tabcontent {
        .details-bookmark {
            &.list-bookmark {
                .bookmark-card.card {
                    .title-bookmark {
                        .content-general {
                            p {
                                margin-right: unset;
                                margin-left: 20px;
                            }
                        }
                        h6 {
                            &::after {
                                right: unset;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .tab-content {
            .card {
                .d-flex {
                    ul {
                        li {
                            + li {
                                margin-left: unset;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .range-slider {
        .row {
            > div {
                > div {
                    &:first-child {
                        direction: ltr;
                    }
                }
            }
        }
    }
    .pagination {
        padding-left: unset;
        padding-right: 0;
    }
    .chat-box {
        .chat-menu {
            right: unset;
            left: 10px;
        }
        .chat-right-aside {
            .chat {
                .chat-msg-box {
                    .my-message {
                        border-radius: 10px 0 10px 10px;
                    }
                    .other-message {
                        border-radius: 0 10px 10px 10px;
                        .message-data {
                            text-align: right !important;
                        }
                    }
                }
            }
        }
    }
    .edit-profile {
        .profile-title {
            .media {
                .media-body {
                    margin-left: unset;
                    margin-right: 25px;
                }
            }
        }
    }
    // calender css
    .rbc-toolbar {
        direction: ltr;
    }
    .calendar-wrap {
        .fc {
            direction: rtl;
            text-align: right;
            .fc-toolbar {
                .fc-left {
                    float: right;
                }
                .fc-right {
                    float: left;
                }
                & > * {
                    & > * {
                        margin-left: 0;
                    }
                }
            }
        }
        .fc-events-container {
            text-align: right;
        }
        .fc-scroller {
            margin-right: unset;
            margin-left: -17px;
        }
    }
    .fc-agenda-view {
        .fc-day-grid {
            .fc-row {
                margin-right: 0 !important;
            }
        }
    }
    .fc-unthemed {
        .fc-row {
            margin-right: 0 !important;
        }
    }
    .taskadd {
        table {
            tr {
                td {
                    &:first-child {
                        padding-left: unset;
                        padding-right: 30px;
                    }
                    &:last-child {
                        padding-right: unset;
                        padding-left: 30px;
                    }
                }
            }
        }
    }
    .social-app-profile {
        .new-users-social {
            .media {
                img.media {
                    margin-left: 15px;
                    margin-right: unset;
                }
            }
        }
        .job-accordion {
            .socialprofile {
                .media {
                    img.media {
                        margin-left: 20px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .email-wrap {
        .email-body {
            .attachment {
                ul {
                    li {
                        &:nth-child(n + 2) {
                            margin-left: unset;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .actions {
            text-align: left;
            li {
                &:nth-child(n + 2) {
                    margin-left: unset;
                    margin-right: 15px;
                }
            }
        }
    }
    .pricing-block {
        .pricing-list {
            ul {
                li {
                    h6 {
                        span {
                            margin-right: 5px;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
    .chat-box {
        .chat-menu {
            .user-profile {
                .user-content {
                    .social-list {
                        li {
                            &:nth-child(n + 2) {
                                margin-left: unset;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .order-history {
        .product-quantity.input-group {
            span {
                &.input-group-prepend {
                    .btn {
                        border-radius: 0 4px 4px 0;
                    }
                }
                &.input-group-append {
                    .btn {
                        border-radius: 4px 0 0 4px;
                    }
                }
            }
        }
    }
    .order-box {
        .sub-total {
            .shipping-class {
                .shopping-checkout-option {
                    text-align: left;
                }
            }
            li {
                .count {
                    text-align: left;
                }
            }
        }
        .qty {
            li {
                span {
                    text-align: left;
                }
            }
        }
        .title-box {
            span {
                text-align: left;
            }
        }
    }
    .square-product-setting {
        .icon-grid {
            margin: 0 0 0 10px;
        }
    }
    .modal {
        .modal-dialog-centered {
            .modal-header {
                .product-box {
                    .product-details {
                        padding: 25px 12px 25px 0;
                        .product-size {
                            ul {
                                li {
                                    &:nth-child(n + 2) {
                                        margin-left: unset;
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-social {
        li {
            &:nth-child(n + 2) {
                margin-left: unset;
                margin-right: 10px;
            }
        }
    }
    .invoice {
        .paypal {
            margin-left: unset;
            margin-right: auto;
        }
    }
    .order-history {
        .rdt_Table {
            .rdt_TableBody {
                .rdt_TableCell {
                    .order-process {
                        padding-right: 12px;
                        padding-left: unset;
                        .order-process-circle {
                            &::before {
                                right: -12px;
                                left: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-filter.new-products {
        .slick-slider {
            .slick-slide {
                .item {
                    .product-box {
                        .product-img {
                            margin-left: unset !important;
                            margin-right: 1rem !important;
                        }
                        .product-details {
                            text-align: left !important;
                            span {
                                i {
                                    margin-left: unset !important;
                                    margin-right: 0.25rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // react tour page
    #___reactour {
        direction: ltr;
    }
    .product-wrapper {
        .left-filter {
            .product-filter {
                &.price-range {
                    > div {
                        direction: ltr;
                    }
                }
            }
        }
    }
    .project-box {
        .customers {
            ul {
                li {
                    + li {
                        margin-left: unset;
                        margin-right: -12px;
                    }
                }
            }
        }
    }
    .customizer-links {
        .customizer-contain {
            .customizer-body {
                select {
                    text-align: right;
                }
            }
        }
    }
    .page-wrapper {
        &.compact-wrapper {
            .page-header.close_icon {
                margin-right: 0;
                margin-left: unset;
                width: 100%;
            }
            .page-body-wrapper {
                div.sidebar-wrapper.close_icon {
                    margin-right: -330px;
                    margin-left: unset;
                    ~ .page-body,
                    ~ .footer {
                        margin-right: 0;
                        margin-left: unset;
                    }
                }
            }
        }
        .page-header {
            .header-wrapper {
                .left-side-header {
                    .input-group {
                        span {
                            border-radius: 0 25px 25px 0;
                        }
                    }
                }
                .nav-right {
                    .onhover-show-div {
                        &.bookmark-flip {
                            .flip-card {
                                .flip-card-inner {
                                    .front {
                                        .bookmark-dropdown {
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.right-header {
                        ul {
                            li {
                                .profile-media {
                                    img.media {
                                        margin-left: 0;
                                        margin-right: unset;
                                    }
                                }
                            }
                        }
                    }
                    .cart-nav {
                        .cart-dropdown {
                            .close-circle {
                                left: 0;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .pro-filter-sec {
        .product-sidebar {
            margin-left: 30px;
            margin-right: unset;
        }
    }
    .product-box {
        .product-img {
            .product-hover {
                ul {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }
        }
    }
    .file-content {
        .ellips {
            right: unset;
            left: 30px;
        }
    }
    .file-manager {
        .files,
        .folder {
            li {
                &:nth-child(n + 2) {
                    margin-left: unset;
                    margin-right: 12px;
                }
            }
        }
    }
    .ongoing-project {
        table {
            thead {
                tr {
                    th {
                        &:last-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .product-filter.new-products {
        .owl-theme {
            .owl-item {
                .item {
                    .product-box {
                        .product-img {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
    caption {
        text-align: right;
    }
    .f-right {
        float: left;
    }
    .form-check-inline {
        margin-right: unset;
        margin-left: 1rem;
    }
    .icofont-heart-alt:before {
        float: right;
    }
    .search-page {
        .search-form {
            .form-group {
                .input-group-text {
                    right: 0;
                    left: unset;
                }
            }
        }
    }
    .knowledgebase-search {
        .form-inline {
            svg {
                left: unset;
                right: 20px;
            }
        }
    }
    .switch-showcase {
        .form-check {
            .form-check-input {
                float: left;
            }
        }
    }
    .pill-input-group span {
        &:last-child {
            border-radius: 1.3rem 0 0 1.3rem !important;
        }
        &:first-child {
            border-radius: 0 1.3rem 1.3rem 0 !important;
        }
    }
    .page-wrapper {
        &.compact-wrapper {
            .page-body-wrapper {
                .sidebar-wrapper {
                    text-align: right;
                    .toggle-sidebar {
                        right: unset;
                        left: 30px;
                    }
                    .sidebar-main .sidebar-links {
                        padding: 0;
                        .simplebar-wrapper {
                            .simplebar-mask {
                                .simplebar-content-wrapper {
                                    .simplebar-content {
                                        > li .badge {
                                            left: 50px !important;
                                            right: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.btn-close_icon {
                        margin-left: unset;
                        transition: all 0.3s ease;
                        margin-right: -330px;
                        .logo-icon-wrapper {
                            text-align: left;
                            img {
                                margin-left: 8px;
                            }
                        }
                        ~ .page-body,
                        ~ footer {
                            margin-left: 0;
                            margin-right: 0;
                            transition: 0.5s;
                        }
                        .sidebar-main {
                            .sidebar-links {
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    a {
                                                        svg {
                                                            margin-left: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .sidebar-list {
                                    .sidebar-title {
                                        &.active {
                                            ~ .sidebar-submenu {
                                                display: none !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .according-menu {
                            display: none;
                        }
                        &:hover {
                            .sidebar-main {
                                .sidebar-links {
                                    .simplebar-wrapper {
                                        .simplebar-mask {
                                            .simplebar-content-wrapper {
                                                .simplebar-content {
                                                    > li {
                                                        a {
                                                            svg {
                                                                margin-left: 13px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        transform: translate(0px);
                        .sidebar-main {
                            .sidebar-links {
                                .sidebar-list {
                                    .sidebar-title {
                                        &.active {
                                            ~ .sidebar-submenu {
                                                display: block !important;
                                            }
                                        }
                                    }
                                }
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    a {
                                                        svg {
                                                            float: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .according-menu {
                            display: block;
                        }
                    }
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            > li {
                                                .sidebar-submenu {
                                                    &:after {
                                                        right: 30px;
                                                        left: unset;
                                                    }
                                                    > li {
                                                        a {
                                                            padding-right: 45px !important;
                                                            padding-left: unset !important;
                                                            &:after {
                                                                right: 26px;
                                                                left: unset;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .page-body {
                    margin-left: unset;
                    margin-right: 280px;
                }
                .sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            > li {
                                                a {
                                                    svg {
                                                        margin-right: unset;
                                                        margin-left: 15px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .according-menu {
                    right: unset;
                    left: 15px;
                }
            }
        }
        &.only-body {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        right: unset;
                        left: 30px;
                    }
                }
            }
        }
    }
    .btn.btn-primary {
        z-index: 0;
    }
    // contact page css
    .contact-editform {
        &.ps-0 {
            padding-left: 20px !important;
        }
    }
    .contact-options {
        li {
            .btn-category {
                padding-left: unset !important;
                padding-right: 40px;
            }
        }
    }
    .list-persons {
        .profile-mail {
            .email-general {
                ul {
                    padding-right: unset;
                    padding-left: 20px;
                    li {
                        > span {
                            float: left;
                        }
                    }
                }
            }
            .media {
                .media-body {
                    ul {
                        li {
                            + li {
                                padding-left: unset;
                                padding-right: 10px;
                                margin-left: unset;
                                margin-right: 5px;
                                border-left: unset;
                                border-right: 1px solid $light-color;
                            }
                        }
                    }
                }
            }
        }
    }
    // file manager css
    .file-content {
        .form-inline {
            i {
                padding-right: unset;
                padding-left: 10px;
            }
        }
    }
    .file-sidebar {
        .pricing-plan {
            .bg-img {
                top: 40px;
                left: -40px;
                right: unset;
                transform: rotate(45deg);
            }
        }
        .btn {
            svg {
                margin-right: unset;
                margin-left: 8px;
            }
        }
    }
    // projects page css
    .form-select {
        background-position: left 0.75rem center;
        padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    }
    .socialprofile {
        .social-group {
            ul {
                li {
                    + li {
                        margin-left: unset;
                        margin-right: -14px;
                    }
                }
            }
        }
    }
    .project-list {
        .project-box {
            .customers {
                .d-inline-block {
                    float: unset !important;
                }
            }
        }
        .btn {
            float: left;
        }
        .border-tab {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 5px 0 5px 30px;
                    }
                }
            }
        }
    }
    .alert-copy.notify-alert {
        left: 20px;
        right: unset !important;
        .btn-close {
            left: 10px;
            right: unset !important;
        }
    }
    .project-box {
        .badge {
            right: unset;
            left: 10px;
        }
    }
    // kanban board css
    .kanban-board {
        float: right;
    }
    .kanban-item {
        .list {
            li {
                &:nth-child(n + 2) {
                    margin-right: 5px;
                    margin-left: unset;
                }
            }
        }
        .kanban-box {
            .customers {
                ul {
                    li {
                        + li {
                            margin-left: unset !important;
                            margin-right: -10px !important;
                        }
                    }
                }
            }
        }
        .list {
            li {
                i {
                    margin-right: unset;
                    margin-left: 10px;
                }
            }
        }
    }
    .icon-box {
        .onhover-show-div {
            right: unset;
            left: 0;
        }
    }
    //dashboard default
    .default-dash {
        .profile-greeting {
            .greeting-user {
                .btn {
                    i {
                        margin-right: 10px;
                        margin-left: unset;
                        transform: scale(-1);
                    }
                }
            }
            .cartoon-img {
                left: 0;
                right: unset;
                transform: scaleX(-1);
            }
        }
        .news-update {
            .table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 12px;
                                padding-right: 0;
                            }
                            &:last-child {
                                padding-right: 12px;
                                padding-left: 0;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
        .ongoing-project {
            table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 12px;
                                padding-right: 0;
                            }
                            &:last-child {
                                padding-right: 12px;
                                padding-left: 0;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
        .icon-box {
            .onhover-show-div {
                right: unset;
                left: 0;
            }
        }
        .premium-access {
            transform: scaleX(-1);
            .card-body {
                transform: scaleX(-1);
            }
        }
        .yearly-chart {
            .card-header {
                h5,
                h6 {
                    text-align: left;
                }
            }
        }
        .total-transactions {
            .report-sec {
                border-right: 1px solid rgba($dark, 0.1);
                border-left: unset;
                .report-main {
                    border-left: 1px solid rgba($dark, 0.1);
                    border-right: unset;
                }
            }
        }
    }
    // ecommerce dashboard 2
    .ecommerce-dash {
        .sale-main {
            .sale-right {
                transform: scaleX(-1);
            }
        }
        .icon-box {
            .onhover-show-div {
                right: unset;
                left: 0;
            }
        }
        .get-support {
            .support-sec-img {
                left: -15px;
                bottom: 0;
                right: unset;
                transform: scaleX(-1);
            }
        }
        .our-activities {
            table {
                tr {
                    td {
                        &:first-child {
                            padding-right: 0;
                            padding-left: 12px;
                        }
                        &:last-child {
                            padding-left: 0;
                            padding-right: 12px;
                            text-align: left;
                        }
                        .icon-wrappar {
                            margin-right: 0;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
        .ongoing-project {
            .media-body {
                padding-right: 8px;
                padding-left: unset !important;
            }
            table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 12px;
                                padding-right: 0;
                            }
                            &:last-child {
                                padding-left: 0;
                                padding-right: 12px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
        .hot-selling {
            .table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 12px;
                                padding-right: 0;
                            }
                            &:last-child {
                                padding-left: 0;
                                padding-right: 12px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
    // crypto dashboard
    .crypto-dash {
        .dropdown {
            .dropdown-toggle {
                &::after {
                    margin-left: 5px;
                    margin-right: unset;
                }
            }
        }
        .buy-sell {
            .buy-sell-form {
                .form-group {
                    .dropdown {
                        .dropdown-toggle {
                            margin-right: auto;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
        .icon-box {
            .onhover-show-div {
                right: unset;
                left: 0;
            }
        }
        .cripto-chat {
            .input-group {
                .input-group-text {
                    svg {
                        transform: scaleX(-1);
                    }
                }
            }
            .chat {
                .left-side-chat {
                    .img-msg-box {
                        img {
                            margin-left: unset;
                            margin-right: 10px;
                        }
                    }
                    .msg-box {
                        margin-left: unset;
                        margin-right: 15px;
                    }
                }
                .right-side-chat {
                    .msg-box {
                        float: left;
                        margin-right: unset;
                        margin-left: 15px;
                    }
                }
            }
        }
        .cripto-notification {
            table {
                tr {
                    td {
                        &:first-child {
                            padding-left: 12px;
                            padding-right: 0;
                        }
                        &:last-child {
                            padding-left: unset;
                            text-align: left;
                            padding-right: 12px;
                        }
                        .media-body {
                            h5 {
                                span {
                                    margin-left: 16px !important;
                                    margin-right: unset !important;
                                }
                            }
                            span {
                                margin-left: unset;
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }
        }
        .trading-activities {
            table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-left: 12px;
                                padding-right: 0;
                            }
                            &:last-child {
                                padding-right: 12px;
                                padding-left: 0;
                                text-align: left;
                            }
                            .media {
                                > svg {
                                    margin-left: 5px;
                                    margin-right: 0;
                                }
                                .table-box-img {
                                    margin-left: 15px;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .crypto-chart {
            .card-header {
                .media {
                    .media-body {
                        h5 {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                        .coin-logo-img {
                            margin-left: 10px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    //horizontal-sidebar
    .page-wrapper {
        &.horizontal-wrapper {
            .header-logo-wrapper {
                text-align: right;
            }
            .page-body-wrapper {
                .page-body {
                    margin-right: 0;
                }
                footer {
                    margin-right: 0;
                }
            }
        }
    }
    .page-header {
        .header-wrapper {
            .nav-right {
                > ul {
                    > li {
                        &:first-child {
                            margin-right: 0;
                        }
                    }
                }
                &.right-header {
                    ul {
                        li {
                            .profile-media {
                                .media-body {
                                    margin-right: 6px;
                                    margin-left: unset;
                                }
                            }
                        }
                    }
                }
            }
            .toggle-sidebar {
                margin-left: 24px;
                margin-right: unset;
            }
        }
    }
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    .notification-dropdown {
                        right: unset !important;
                    }
                }
                .left-side-header {
                    .input-group {
                        span {
                            border-left: none;
                            border-right: 1px solid #d8e0ff;
                        }
                        input {
                            border: 1px solid #d8e0ff;
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
    .apexcharts-canvas {
        direction: ltr;
    }
    .job-search {
        .media {
            .media-body {
                .job-apply-btn {
                    left: 30px;
                    right: unset;
                }
                ul.rating {
                    margin-right: 10px;
                    margin-left: unset;
                }
            }
        }
    }
    // bookmark app css
    .email-wrap {
        .btn-mail {
            text-align: right;
        }
        .email-app-sidebar {
            ul {
                li {
                    a {
                        padding-left: unset;
                        padding-right: 20px;
                    }
                }
            }
        }
    }
    .bookmark-tabcontent {
        .details-bookmark.list-bookmark {
            .bookmark-card.card {
                .details-website {
                    .hover-block {
                        text-align: left;
                        width: 95%;
                    }
                }
                .title-bookmark {
                    h6 {
                        padding-left: 15px;
                        padding-right: unset;
                        &::before {
                            position: absolute;
                            content: "-";
                            left: 0;
                            color: $dark-gray;
                        }
                    }
                }
            }
        }
        .details-bookmark {
            .bookmark-card {
                &.card {
                    .favourite-icon {
                        text-align: center;
                        left: 15px;
                        right: unset;
                    }
                    .title-bookmark {
                        text-align: right;
                    }
                }
            }
        }
    }
    .bookmark-wrap {
        .details-bookmark {
            .row {
                > div {
                    + div {
                        padding-left: 15px !important;
                    }
                }
            }
        }
    }
    .cripto-notification {
        table {
            tr {
                td {
                    .media {
                        .square-box {
                            margin-right: unset;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
    // index page css start
    .appointment-table {
        table {
            tr {
                td {
                    .status-circle {
                        left: unset;
                        right: 35px;
                    }
                }
            }
        }
    }
    .notification {
        .card {
            .media {
                .media-body {
                    h6 {
                        .dot-notification {
                            float: left;
                        }
                    }
                    p {
                        .badge {
                            margin-left: unset;
                            margin-right: 10px;
                        }
                    }
                    .inner-img {
                        &:first-child {
                            margin-right: unset;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
    // index page css end
    // ecommerce dashboard css start
    .dash-chart {
        .media {
            .media-body {
                p {
                    .font-roboto {
                        float: right;
                    }
                }
            }
        }
    }
    @each $progress-gradient-name, $progress-gradient-color in (primary, var(--theme-default)),
        (secondary, var(--theme-secondary)), (success, $success-color), (danger, $danger-color), (info, $info-color),
        (light, $light-color), (dark, $dark-color), (warning, $warning-color)
    {
        .progress-gradient-#{$progress-gradient-name} {
            background-image: linear-gradient(to right, $progress-gradient-color, $transparent-color);
            &::after {
                right: unset;
                left: -1px;
            }
            .animate-circle {
                right: unset;
                left: -8px;
            }
        }
    }
    .progress-animate {
        span {
            left: -10px;
            right: unset;
        }
    }
    .our-product,
    .best-seller-table {
        table {
            tr {
                td {
                    &:first-child {
                        padding-left: 20px;
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-right: 20px;
                        padding-left: 0;
                        text-align: left;
                    }
                }
            }
        }
    }
    .best-seller-table {
        table {
            tbody {
                tr {
                    td {
                        .align-middle {
                            .status-circle {
                                left: unset;
                                right: 32px;
                            }
                        }
                        .fa-check-circle {
                            left: unset;
                            right: 30px;
                        }
                    }
                }
            }
        }
    }
    .activity-timeline {
        .media {
            .media-body {
                margin-left: unset;
                margin-right: 20px;
            }
            .activity-line {
                left: unset;
                right: 46px;
            }
        }
    }
    .total-users {
        .goal-status {
            .progress {
                background-image: linear-gradient(
                    to right,
                    $transparent-color 15%,
                    rgba($info-color, 0.1) 25%,
                    $transparent-color
                );
            }
            .progress-gradient-primary {
                background-image: linear-gradient(
                    to right,
                    $primary-color 25%,
                    rgba($info-color, 0.1),
                    $transparent-color
                );
            }
        }
    }
    // ecommerce dashboard css end
    .cal-date-widget {
        .cal-info {
            .pe-3 {
                padding-right: 1rem !important;
            }
        }
    }
    .me-4 {
        margin-left: 1.5rem !important;
        margin-right: unset !important;
    }
    .pe-4 {
        padding-left: 1.5rem !important;
        padding-right: unset !important;
    }
    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: unset !important;
    }
    .navs-dropdown {
        .onhover-show-div {
            right: 0;
        }
    }
    .default-according {
        &.style-1 {
            button {
                text-align: right;
                &:before {
                    left: 3px;
                    right: unset;
                }
                i {
                    right: 18px;
                    left: unset;
                }
            }
        }
        .card {
            .card-header {
                i {
                    right: 18px;
                    left: unset;
                }
            }
        }
    }
    .ProfileCard-details {
        padding-right: 30px;
        padding-left: unset;
        float: right;
    }
    .theme-tab {
        .tab-title {
            li {
                a {
                    svg {
                        margin-left: 5px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .spent {
        .spent-graph {
            .project-budget {
                margin-left: 30px;
                margin-right: unset;
            }
        }
    }
    .support-table {
        table {
            tbody {
                tr {
                    td {
                        &:first-child {
                            padding-right: 0;
                            padding-left: unset;
                        }
                    }
                }
            }
        }
    }
    .widget-joins {
        .row {
            .pe-0 {
                padding-right: 15px !important;
            }
            .ps-0 {
                padding-left: 15px !important;
            }
        }
    }
    .location-checkbox {
        span {
            padding-right: 33px;
            padding-left: unset;
        }
    }
    .cke_contents {
        direction: rtl;
    }
    .CodeMirror-sizer {
        border-right: unset !important;
    }
    .order-box {
        .sub-total,
        .total,
        .qty {
            .shipping-class,
            li {
                .shopping-checkout-option,
                span {
                    float: left;
                }
            }
            li {
                .count {
                    float: left;
                }
            }
        }
    }
    .img-paypal {
        margin-right: 15px;
        margin-left: unset;
    }
    .product-qnty {
        fieldset {
            .input-group {
                .input-group-prepend {
                    .btn {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
    .grid-options {
        margin-right: 10px;
        margin-left: unset;
        ul {
            li {
                a {
                    .line-grid-1 {
                        right: 12px;
                        left: unset;
                    }
                    .line-grid-2 {
                        right: 18px;
                        left: unset;
                    }
                    .line-grid-3 {
                        right: 36px;
                        left: unset;
                    }
                    .line-grid-4 {
                        right: 42px;
                        left: unset;
                    }
                    .line-grid-5 {
                        right: 48px;
                        left: unset;
                    }
                    .line-grid-6 {
                        right: 66px;
                        left: unset;
                    }
                    .line-grid-7 {
                        right: 72px;
                        left: unset;
                    }
                    .line-grid-8 {
                        right: 78px;
                        left: unset;
                    }
                    .line-grid-9 {
                        right: 84px;
                        left: unset;
                    }
                    .line-grid-10 {
                        right: 103px;
                        left: unset;
                    }
                    .line-grid-11 {
                        right: 109px;
                        left: unset;
                    }
                    .line-grid-12 {
                        right: 115px;
                        left: unset;
                    }
                    .line-grid-13 {
                        right: 121px;
                        left: unset;
                    }
                    .line-grid-14 {
                        right: 127px;
                        left: unset;
                    }
                    .line-grid-15 {
                        right: 133px;
                        left: unset;
                    }
                }
            }
        }
    }
    .product-wrapper {
        &.sidebaron {
            .product-grid {
                .product-wrapper-grid {
                    margin-left: unset;
                }
            }
        }
    }
    .product-filter {
        &.new-products {
            .owl-theme {
                .owl-nav {
                    left: 0;
                    right: unset;
                }
                .owl-item {
                    .item {
                        .product-box {
                            .product-details {
                                justify-content: flex-end;
                                &.text-start {
                                    text-align: right !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .categories {
        ul {
            li {
                padding: 5px 30px 5px 60px;
                .badge {
                    left: 30px;
                    right: unset;
                }
            }
        }
    }
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    .onhover-show-div {
                        .dropdown-title {
                            a {
                                svg {
                                    right: 0;
                                    left: unset;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .browse {
        .browse-articles {
            ul {
                li {
                    h5 {
                        padding-right: 20px;
                        padding-left: unset;
                        svg {
                            right: 0;
                            left: unset;
                            margin-left: 10px;
                            margin-right: unset;
                        }
                    }
                }
            }
            h4 {
                padding-right: 25px;
                padding-left: unset;
                span {
                    svg {
                        right: 0;
                        left: unset;
                    }
                }
            }
        }
    }
    .product-sidebar {
        .filter-section {
            .card {
                .card-header {
                    h6 {
                        .pull-right {
                            i {
                                left: auto;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .prooduct-details-box {
        .btn {
            right: unset;
            left: 15px;
        }
        .btn-close {
            right: unset;
            left: 10px;
        }
    }
    @each $b-l-name, $b-l-color in (primary, var(--theme-default)), (secondary, var(--theme-secondary)),
        (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
        (dark, $dark-color), (warning, $warning-color)
    {
        .b-l-#{$b-l-name} {
            border-right: 1px solid $b-l-color !important;
            border-left: unset !important;
        }
    }
    @each $b-r-name, $b-r-color in (primary, var(--theme-default)), (secondary, var(--theme-secondary)),
        (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
        (dark, $dark-color), (warning, $warning-color)
    {
        .b-r-#{$b-r-name} {
            border-left: 1px solid $b-r-color !important;
            border-right: unset !important;
        }
    }
    .collection-filter-block {
        svg {
            margin-right: unset;
            margin-left: 10px;
        }
    }
    .feature-products {
        form {
            .form-group {
                i {
                    left: 30px;
                    right: unset;
                }
            }
        }
    }
    .icon-hover-bottom {
        .icon-popup {
            .btn-close-icon {
                left: 10px;
                right: unset;
            }
        }
    }
    .flag-icons,
    .feather-icons {
        div {
            .media {
                .media-body {
                    h5 {
                        margin-left: 5px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-details {
                    text-align: right;
                }
            }
        }
    }
    .page-wrapper {
        .page-body-wrapper {
            .page-title {
                .breadcrumb {
                    margin-left: unset;
                }
            }
        }
    }
    .bookmark {
        ul {
            li {
                + li {
                    margin-left: unset;
                    margin-right: 10px;
                }
                .search-form {
                    .form-control-search {
                        left: -50px;
                        right: unset;
                        &:before {
                            right: 50px;
                            left: unset;
                        }
                        &:after {
                            right: 20px;
                            left: unset;
                        }
                    }
                }
            }
        }
    }
    .me-0 {
        margin-left: 0 !important;
        margin-right: unset !important;
    }
    .footer {
        .pull-right {
            float: none;
        }
    }
    .checkbox_animated,
    .radio_animated {
        margin: 0 8px 0 16px;
    }
    .button.remove {
        left: -10px;
        right: unset;
    }
    ul {
        &.nav-menus {
            border-bottom: none;
        }
        &.notification-dropdown {
            &.onhover-show-div {
                li {
                    text-align: right;
                }
            }
        }
    }
    .badge {
        + .badge {
            margin-right: 5px;
            margin-left: unset;
        }
    }
    .offset-xl-3 {
        margin-right: 25%;
        margin-left: unset;
    }
    .m-l-10 {
        margin-right: 10px !important;
        margin-left: unset !important;
    }
    .m-r-20 {
        margin-left: 20px;
        margin-right: unset;
    }
    .m-r-30 {
        margin-left: 30px;
        margin-right: unset;
    }
    .m-r-5 {
        margin-left: 5px;
        margin-right: unset;
    }
    .text-start {
        text-align: right !important;
    }
    .m-r-10 {
        margin-left: 10px;
        margin-right: unset;
    }
    .pe-3 {
        padding-left: 1rem !important;
        padding-right: unset !important;
    }
    .p-r-0 {
        padding-left: 0;
        padding-right: 15px;
    }
    .m-l-20 {
        margin-right: 20px !important;
        margin-left: unset !important;
    }
    .ps-3 {
        padding-right: 1rem !important;
    }
    .float-end {
        float: left !important;
    }
    .float-start {
        float: right !important;
    }
    .text-end {
        text-align: left !important;
    }
    .border-right {
        border-left: 1px solid $table-border-color !important;
        border-right: unset !important;
    }
    .pe-0 {
        padding-left: 0 !important;
        padding-right: unset !important;
    }
    .ps-0 {
        padding-right: 0 !important;
        padding-left: unset !important;
    }
    .ms-1 {
        margin-left: unset !important;
        margin-right: 0.25rem !important;
    }
    .owl-carousel {
        direction: ltr;
    }
    .btn-group {
        > {
            .btn:not(:last-child):not(.dropdown-toggle),
            :not(:last-child) > .btn {
                border-radius: 0 0.25rem 0.25rem 0;
            }
            .btn:not(:first-child) {
                border-radius: 0.25rem 0 0 0.2rem;
            }
            .btn:nth-child(2) {
                border-radius: 0 !important;
            }
        }
        .btn-group > :not(:first-child) > .btn {
            border-radius: 0.25rem 0 0 0.2rem;
        }
        .btn + {
            .btn,
            .btn-group {
                margin-right: -1px;
                border-right: none;
            }
        }
        .btn-group + {
            .btn,
            .btn-group {
                margin-right: -1px;
            }
        }
        .btn-group-vertical {
            .btn + {
                .btn,
                .btn-group {
                    margin-right: -1px;
                }
            }
            .btn-group + {
                .btn,
                .btn-group {
                    margin-right: -1px;
                }
            }
        }
    }
    .me-3 {
        margin-right: unset !important;
        margin-left: 1rem !important;
    }
    .me-5 {
        margin-left: 3rem !important;
        margin-right: unset !important;
    }
    .ms-3 {
        margin-right: 1rem !important;
        margin-left: unset !important;
    }
    .m-r-15 {
        margin-left: 15px;
        margin-right: unset;
    }
    .p-l-0 {
        padding-left: unset;
        padding-right: 0px;
    }
    .ps-4 {
        padding-right: 1.5rem !important;
        padding-left: unset !important;
    }
    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: unset !important;
    }
    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: unset !important;
    }
    .pe-2 {
        padding-right: unset !important;
        padding-left: 0.5rem !important;
    }
    .alert {
        text-align: right;
    }
    .pull-right {
        float: left;
    }
    .form-inline {
        .form-group {
            margin-left: 15px;
            margin-right: 0;
        }
    }
    .card {
        text-align: right;
        .card-header {
            h5:not(.mb-0) {
                float: right;
            }
            span {
                clear: both;
            }
            .card-header-right {
                left: 35px;
                right: unset;
                .card-option {
                    text-align: left;
                }
            }
            .pull-left {
                float: right;
            }
        }
        .card-body {
            .alert {
                svg {
                    margin-left: 4px;
                    margin-right: unset;
                }
            }
            .value-left {
                margin-left: 30px;
                margin-right: unset;
            }
            .square {
                margin-left: 5px;
                margin-right: unset;
            }
        }
    }
    .modal-header {
        .btn-close {
            margin: -0.5rem auto -0.5rem -0.5rem;
        }
    }
    .modal-footer {
        > :not(:first-child) {
            margin-right: 0.5rem;
            margin-left: 0;
        }
    }
    .form-inline {
        .form-group {
            .col-form-label {
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
    .breadcrumb-item {
        & + .breadcrumb-item {
            padding-right: 0.5rem;
            padding-left: unset;
            &::before {
                padding-left: 0.5rem;
                padding-right: unset;
                float: right;
            }
        }
    }
    code[class*="language-"],
    pre[class*="language-"] {
        text-align: right;
    }
    .input-group-prepend {
        margin-left: -1px;
        margin-right: unset;
    }
    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
    .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
        border-radius: 0 0.25rem 0.25rem 0;
    }
    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: unset;
        margin-right: -1px;
        border-radius: 0.25rem 0 0 0.2rem;
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .was-validated .form-control:valid,
    .form-control.is-valid {
        background-position: left calc(0.375em + 0.1875rem) center;
        padding-left: calc(1.5em + 0.75rem);
        padding-right: 12px;
    }
    .input-group > .form-control:not(:last-child) {
        border-radius: 0;
    }
    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
        border-radius: 0 0.25rem 0.2rem 0;
    }
    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
        border-radius: 0;
    }
    .border-tab.nav-left .nav-link {
        text-align: left;
    }
    // social app page css
    .social-tab {
        ul {
            li {
                &:nth-child(n + 2) {
                    margin-right: 20px;
                    margin-left: unset;
                }
            }
        }
    }
    .social-status {
        form {
            .form-group {
                .form-control-social {
                    padding-right: 15px;
                    padding-left: unset;
                }
            }
        }
        .media {
            .social-status {
                right: 35px;
                left: unset;
            }
        }
    }
    .social-chat {
        .media-body {
            span.f-w-600 {
                display: flex;
                i.fa-reply {
                    transform: scale(-1);
                }
            }
        }
    }
    .social-tab {
        ul {
            li {
                a {
                    svg {
                        margin-left: 5px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .was-validated {
        .form-select:invalid {
            background-position: left 0.75rem center, center left 1.75rem !important;
        }
    }
    .social-chat {
        .other-msg {
            margin-right: 40px;
            margin-left: unset;
        }
        .media-body {
            &:before {
                left: 100%;
                right: unset;
                border-left: 7px solid var(-semi-dark);
                border-right: unset;
            }
            &:after {
                left: 100%;
                right: unset;
                border-left: 7px solid $white;
                border-right: unset;
            }
        }
    }
    .timeline-content {
        .comment-number {
            i {
                margin-left: 20px;
                margin-right: unset;
            }
        }
    }
    .photos {
        ul {
            li {
                margin-right: unset;
                margin-left: 10px;
            }
        }
    }
    .avatar-showcase {
        .friend-pic {
            margin-left: 8px;
            margin-right: unset;
        }
        .pepole-knows {
            ul {
                li {
                    margin-left: 21px;
                    margin-right: unset;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .user-profile {
        .profile-post {
            .post-body {
                .post-comment {
                    li {
                        &:nth-child(n + 2) {
                            margin-right: 15px;
                            margin-left: unset;
                        }
                        label {
                            a {
                                span {
                                    margin-right: 10px;
                                    margin-left: unset;
                                }
                            }
                        }
                    }
                }
                .post-react {
                    h6 {
                        margin-right: 10px;
                        margin-left: unset;
                    }
                    ul {
                        li {
                            &:nth-child(n + 2) {
                                margin-right: -20px;
                                margin-left: unset;
                            }
                        }
                    }
                }
            }
        }
        .post-about {
            ul {
                li {
                    .icon {
                        margin-left: 15px;
                        margin-right: unset;
                    }
                }
            }
        }
        .follow {
            ul.follow-list {
                li:nth-child(n + 1) {
                    margin-left: 15px;
                    padding-left: 15px;
                    border-left: 1px solid #ecf3fa;
                    &:last-child {
                        margin-left: unset;
                        margin-right: unset;
                        border-left: unset;
                    }
                }
            }
        }
        .social-media {
            ul.user-list-social {
                li:nth-child(n + 2) {
                    margin-right: 5px;
                    margin-left: unset;
                }
            }
        }
        .hovercard {
            .user-image {
                .share-icons {
                    left: 100px;
                    right: unset;
                    li {
                        margin-left: 10px;
                        margin-right: unset;
                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    // faq page css
    .faq-wrap {
        .default-according {
            .card {
                .card-header {
                    .btn-link {
                        padding-right: 48px !important;
                    }
                }
            }
        }
    }
    .faq-form {
        .search-icon {
            left: 12px;
            right: unset;
        }
    }
    .faq-widgets {
        .media-body {
            p {
                padding-left: 20px;
                padding-right: unset;
            }
        }
    }
    .modal {
        .theme-close {
            left: 0;
            right: unset;
        }
    }
    .swal-footer {
        clear: both;
        display: flex;
        justify-content: flex-end;
    }
    .header-faq,
    .faq-title {
        text-align: right;
    }
    .faq-accordion.job-accordion {
        button {
            &::before {
                left: 10px !important;
            }
        }
        .card-header {
            .btn-link {
                text-align: right;
            }
            h5 {
                float: right;
            }
        }
    }
    .faq-accordion {
        .faq-header {
            svg {
                left: 30px;
                right: unset;
            }
        }
        .card {
            .btn-link {
                svg {
                    margin-left: 10px;
                    margin-right: unset;
                    right: 16px;
                    left: unset;
                }
            }
        }
    }
    .navigation-option {
        ul {
            li {
                padding: 15px 0 15px 15px;
                a {
                    padding-right: 40px;
                    padding-left: unset;
                    svg {
                        right: 15px;
                        top: 17px;
                        left: unset;
                    }
                }
            }
        }
    }
    .default-according {
        .card {
            .card-header {
                .btn-link {
                    text-align: right;
                    padding-right: 22px !important;
                }
            }
        }
    }
    .updates-faq {
        margin-right: unset;
        margin-left: 30px;
    }
    // knowledgebase page css
    .browse {
        .browse-articles {
            span {
                svg {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }
        }
    }
    // learning app page css
    .categories {
        .learning-header {
            padding-right: 30px;
            padding-left: unset;
        }
    }
    .pricing-block {
        svg {
            direction: ltr;
        }
    }
    // avatars page css
    .avatars {
        .avatar {
            margin-left: 10px;
            margin-right: unset;
            &:last-child {
                margin-left: 0;
            }
            .status {
                left: 4px;
                right: unset;
            }
        }
    }
    .customers {
        &.avatar-group {
            margin-left: 30px;
            margin-right: unset;
        }
    }
    .card {
        .card-header {
            h5 {
                float: none !important;
            }
        }
    }
    // knob chart css
    .knob-chart {
        .chart-clock-main {
            .clock-small {
                right: 10px;
                left: unset;
            }
        }
    }
    // general widgets css
    .general-widget {
        .employee-status {
            .table {
                tbody {
                    tr {
                        td {
                            &:first-child {
                                padding-right: 0;
                                padding-left: 12px;
                            }
                            &:last-child {
                                padding-right: 12px;
                                padding-left: 0;
                                text-align: left;
                            }
                            .media {
                                img {
                                    margin-left: 15px;
                                    margin-right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
        .social-widget-card {
            .card-footer {
                .row {
                    .col:nth-child(n + 2) {
                        border-right: 1px solid $light-gray;
                        border-left: unset;
                    }
                }
            }
        }
        .custom-profile {
            .card-footer > div {
                div + div {
                    border-right: 1px solid $light-gray;
                    border-left: unset;
                }
            }
        }
        .testimonial {
            text-align: center;
        }
        .widget-feedback {
            ul {
                li {
                    &:nth-child(n + 2) {
                        border-right: 1px solid $light-gray !important;
                        border-left: unset;
                    }
                }
            }
        }
        .icon-box {
            .onhover-show-div {
                right: unset;
                left: 0;
                text-align: right;
            }
        }
        .card-header {
            .media {
                .media-body {
                    h5 {
                        text-align: right;
                    }
                }
            }
        }
        .card-body {
            .widget-card {
                .media-body {
                    text-align: left;
                    h5 {
                        direction: ltr;
                    }
                }
            }
        }
    }
    .widget-joins {
        .widget-card {
            h6 {
                margin-left: unset;
                margin-right: 15px;
            }
            .icon-bg {
                right: unset;
                left: 20px;
            }
        }
    }
    .weather-widget-two {
        .widget-list {
            .media {
                .media-body {
                    margin-left: unset;
                    margin-right: 30px;
                }
            }
        }
    }
    .activity-media {
        .media {
            .media-body {
                margin-left: unset;
                margin-right: 20px;
            }
        }
    }
    .custom-profile {
        .card-social {
            li {
                + li {
                    margin-left: unset;
                    margin-right: 10px;
                }
            }
        }
    }
    .social-widget-card {
        .media {
            .media-body {
                margin-left: unset;
                margin-right: 20px;
            }
        }
    }
    .mobile-clock-widget {
        .bg-svg {
            left: unset;
            right: -25px;
        }
    }
    // to-do css
    .todo {
        .todo-list-wrapper {
            .mark-all-tasks {
                .btn-label {
                    margin-left: 5px;
                    margin-right: unset;
                }
                left: 30px;
                right: unset;
            }
            #todo-list {
                li {
                    .task-container {
                        .task-action-btn {
                            text-align: left;
                        }
                    }
                }
            }
        }
        .notification-popup {
            left: 10px;
            right: unset;
        }
    }
    .todo-options {
        .badges-todo {
            h6 {
                margin-left: 10px;
                margin-right: unset;
                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }
    .todo-list-btns {
        .dropdown-basic {
            .btn-group {
                .form-group {
                    .checkbox {
                        padding-right: 14px;
                        border-top-right-radius: 25px;
                        border-bottom-right-radius: 25px;
                        padding-left: unset;
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                    }
                }
            }
            .separated-btn {
                margin-right: -6px;
                margin-left: unset;
                .btn {
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                }
            }
        }
    }
    .search-todo {
        .dropdown-basic {
            .btn-group {
                margin-left: 18px;
                margin-right: unset;
            }
        }
    }
    //sticky note css
    .sticky-note {
        .note {
            float: right;
        }
    }
    #testimonial {
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .slide--item {
                        .media {
                            .me-3 {
                                margin-right: 1rem !important;
                                margin-left: unset !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .needs-validation,
    .form-row {
        .input-group > .form-control:not(:last-child),
        .input-group > .custom-select:not(:last-child) {
            border-radius: 0.25rem 0 0 0.25rem;
        }
    }
    .form-check {
        padding-right: 1.25rem;
        padding-left: unset;
        .form-check-input {
            float: right;
            margin-left: unset;
            margin-right: -1.5em;
        }
    }
    .mega-menu {
        .default-according {
            .card {
                .btn-link {
                    text-align: right;
                }
            }
            &.style-1 {
                button[aria-expanded="true"],
                button[aria-expanded="false"] {
                    &:before {
                        right: unset;
                        left: 20px;
                    }
                }
            }
        }
    }
    // email css
    .email-wrap {
        .row {
            .col-xl-6 {
                padding-right: 0;
                padding-left: 15px;
            }
            .col-xl-3 {
                + .col-xl-3 {
                    padding-left: 0;
                    padding-right: 15px;
                }
            }
        }
        .email-app-sidebar {
            .main-menu {
                & > li {
                    text-align: right;
                    a {
                        i {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                }
            }
            ul {
                padding-right: 0;
            }
        }
        .email-content {
            .email-top {
                .user-emailid {
                    &:after {
                        float: left;
                    }
                }
            }
        }
        .email-right-aside {
            .email-body {
                .inbox {
                    svg {
                        margin-left: 10px;
                        margin-right: unset;
                    }
                    p {
                        padding-right: 15px;
                        padding-left: unset;
                    }
                    .media {
                        .media-body {
                            span {
                                left: 20px;
                                right: unset;
                            }
                        }
                    }
                }
                .pe-0 {
                    padding-right: unset !important;
                }
            }
            .radius-left {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-radius: 4px;
            }
        }
    }
    // buttons css
    .btn-group-showcase {
        .btn-group {
            margin-left: 20px;
            margin-right: unset;
        }
    }
    .btn-showcase {
        .btn {
            margin-right: unset;
            margin-left: 18px;
        }
    }
    .btn-square {
        &.dropdown-toggle {
            border-radius: 0.25rem 0 0 0.25rem !important;
        }
    }
    .options {
        > div {
            margin: 0 0 8px 8px;
        }
    }
    // modal page
    .modal-dialog {
        .modal-content {
            .modal-body {
                p {
                    a {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    // search page
    .search-page {
        .nav i {
            margin-left: 10px;
            margin-right: unset;
        }
    }
    .product-stts {
        display: inline-block;
        .ms-1 {
            margin-right: 0.25rem !important;
            margin-left: unset !important;
        }
    }
    .search-form input {
        padding: 10px 70px 10px 10px !important;
        text-align: right;
    }
    .needs-validation {
        select.form-select {
            option {
                color: $black;
            }
        }
    }
    // icons page
    .icon-hover-bottom {
        .icon-title {
            text-align: right;
        }
        .icon-popup {
            .icon-last {
                .form-inline {
                    .form-group {
                        .btn {
                            margin-right: 15px;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
    .icon-lists {
        div {
            i {
                margin: 0 10px 0 15px;
            }
        }
        ul {
            padding-right: 0;
        }
    }
    ul {
        &.icon-lists {
            padding-right: 30px;
        }
    }
    // customizer
    .radio {
        label {
            padding-left: 0;
            padding-right: 15px;
            &::before {
                right: 0;
                margin-right: -20px;
                left: unset;
            }
        }
    }
    // sidebar main page
    .page-wrapper {
        .page-body-wrapper {
            .page-title {
                .row {
                    h3 {
                        text-align: right;
                        padding-right: unset !important;
                    }
                    h6 {
                        text-align: right;
                    }
                }
            }
        }
        &.compact-wrapper {
            .page-header {
                margin-right: 280px;
                margin-left: unset;
                width: calc(100% - 280px);
                &.btn-close_icon {
                    margin-right: 0;
                    margin-left: unset;
                    width: 100%;
                }
            }
        }
    }
    .customizer-contain {
        .customizer-body {
            .dashboard-type {
                .slick-next {
                    left: unset;
                }
            }
        }
    }
    // page main header
    .page-header {
        .header-wrapper {
            .nav-right {
                text-align: right;
                .onhover-show-div {
                    right: unset;
                    left: 0;
                }
                .chat-dropdown {
                    li {
                        .media {
                            .status-circle {
                                left: unset;
                                right: 0px;
                            }
                        }
                    }
                }
                .cart-dropdown {
                    .btn-close-circle {
                        right: unset;
                        left: 0;
                    }
                }
                > ul {
                    > li {
                        margin-right: unset;
                        margin-left: 5px;
                        &:before {
                            right: 0;
                            left: unset;
                        }
                        .dot {
                            left: 17px;
                            right: unset;
                        }
                        &.onhover-dropdown {
                            &:hover {
                                .onhover-show-div {
                                    &:before {
                                        right: 10px;
                                        left: unset;
                                    }
                                    &:after {
                                        right: 10px;
                                        left: unset;
                                    }
                                }
                            }
                            &:last-child {
                                padding-left: 0;
                                padding-right: 20px;
                                border-right: none;
                            }
                        }
                        &:last-child {
                            padding-right: 20px;
                            padding-left: 0;
                        }
                        text-align: right;
                        i {
                            &.ms-2 {
                                margin-right: 0.5rem !important;
                                margin-left: unset !important;
                            }
                        }
                    }
                }
                .profile-dropdown {
                    li {
                        svg {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .mobile-sidebar {
                padding-left: 20px;
                padding-right: unset;
            }
        }
        &.open {
            margin-right: 0;
        }
    }
    ul {
        &.notification-dropdown {
            &.onhover-show-div {
                right: initial;
                left: -30px;
                padding: 0;
                &:before {
                    right: inherit !important;
                    left: 35px !important;
                }
                &:after {
                    right: inherit !important;
                    left: 35px !important;
                }
                li {
                    margin-right: 0 !important;
                    span {
                        &.badge {
                            margin-left: unset !important;
                        }
                        svg {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                    .notification-icon {
                        margin-left: 20px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    // alert page
    .card-body {
        .alert {
            svg {
                ~ p {
                    padding-left: unset;
                    padding-right: 20px;
                }
            }
        }
    }
    .alert {
        i {
            margin-left: 5px;
            margin-right: 0;
        }
    }
    .inverse {
        padding: 13px 65px 13px 20px;
        i {
            left: unset;
            right: 0;
        }
    }
    @each $var in $alert-name {
        $i: index($alert-name, $var);
        .alert-#{$var}.inverse {
            &:before {
                border-right: 7px solid nth($alert-color, $i);
                right: 54px;
                left: unset;
                border-left: unset;
            }
        }
    }
    .alert-dismissible {
        .btn-close {
            left: 0;
            right: unset;
            span {
                padding-left: 0;
                padding-right: unset;
            }
        }
    }
    .icofont {
        &.icofont-truck {
            display: inline-block;
        }
    }
    // blog page
    .blog-box {
        .blog-details-second {
            .detail-footer {
                ul.sociyal-list {
                    li {
                        &:nth-child(n + 2) {
                            margin-left: unset;
                            margin-right: 15px;
                            padding-right: 15px;
                            padding-left: unset;
                        }
                        i {
                            margin-left: 5px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
        .blog-details {
            padding-left: 20px;
            text-align: right;
            .blog-social {
                padding-right: 0;
                li {
                    padding-left: 40px;
                    padding-right: unset;
                    &:first-child {
                        border-left: 1px solid $light-gray;
                        border-right: unset;
                        padding-left: 40px;
                        padding-right: unset;
                    }
                    & + li {
                        margin-right: 30px;
                        margin-left: unset;
                        padding-left: 40px;
                    }
                }
            }
        }
        .blog-details-main {
            .blog-social {
                li {
                    border-left: 1px dotted;
                    border-right: unset;
                    &:last-child {
                        border-left: none;
                    }
                }
            }
        }
        .blog-date {
            span {
                font-size: 36px;
                font-weight: 500;
                padding-left: 5px;
            }
        }
    }
    .comment-box {
        .media {
            img {
                margin-left: 45px;
                margin-right: unset;
            }
            h6 {
                text-align: right;
            }
        }
        ul {
            ul {
                margin-right: 100px;
                margin-left: unset;
                padding-right: 0;
            }
        }
        .comment-social {
            text-align: left;
            li {
                padding-left: 0;
                padding-right: 20px;
                &:first-child {
                    border-left: 1px solid $light-semi-gray;
                    border-right: unset;
                    padding-right: 0;
                    padding-left: 20px;
                }
                &:nth-child(n + 2) {
                    border-left: unset;
                }
            }
        }
    }
    // chat page
    .call-chat-body {
        .chat-box {
            .chat-right-aside {
                .chat {
                    .chat-history {
                        .caller-img {
                            &.ps-0 {
                                padding-left: 15px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .chat-box {
        .user-image {
            float: right;
            margin-left: 5px;
            margin-right: 0;
        }
        .people-list {
            .search {
                i {
                    left: 10px;
                    right: unset;
                }
            }
        }
        .about {
            float: right;
            padding-right: 10px;
            padding-left: unset;
            text-align: right;
        }
        .chat-menu {
            border-right: 1px solid $light-color;
            border-left: unset;
            .nav {
                padding-right: 0;
            }
            &.ps-0 {
                padding-left: 15px !important;
            }
        }
        .chat-right-aside {
            .chat {
                .chat-message {
                    .smiley-box {
                        margin-left: 0.5rem;
                        margin-right: unset;
                    }
                    .text-box {
                        .btn {
                            border-radius: 5px 0 0 5px;
                        }
                    }
                }
            }
            &.pe-0 {
                padding-right: 15px !important;
            }
        }
        .chat {
            .chat-message {
                .text-box {
                    .input-group-append {
                        margin-right: -2px;
                    }
                }
            }
        }
    }
    .chat-msg-box {
        ul {
            padding-right: 0;
        }
    }
    .chat-left-aside {
        .status-circle {
            left: unset;
            right: 40px;
        }
    }
    .chat-right-aside {
        .chat {
            .chat-header {
                .chat-menu-icons {
                    padding-left: 0;
                    padding-right: unset;
                    &.float-sm-end {
                        float: left !important;
                    }
                }
                img {
                    float: right;
                }
            }
        }
    }
    // cke editor page
    .cke_toolbar,
    .cke_toolgroup,
    a.cke_button,
    .cke_combo_text {
        float: right;
    }
    .cke_reset_all,
    .cke_reset_all *,
    .cke_reset_all a,
    .cke_reset_all textarea {
        text-align: right;
    }
    .cke_combo_text {
        padding-left: unset;
        padding-right: 10px;
    }
    #editor1 {
        #cke_editor1 {
            #cke_1_contents {
                iframe {
                    html {
                        direction: rtl !important;
                    }
                }
            }
        }
    }
    // coming soon page
    #clockdiv {
        ul {
            padding-right: 0;
        }
    }
    // social app page
    .custom-card {
        .card-footer {
            & > div {
                & + div {
                    border-right: 1px solid $light-semi-gray;
                    border-left: unset !important;
                }
            }
        }
    }
    .card-social {
        padding-right: 0;
    }
    .card-absolute {
        .card-header {
            right: 15px;
            left: unset;
        }
    }
    // dropdown page
    .dropdown-basic {
        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
        }
        .separated-btn {
            .btn {
                border-radius: 0.25rem;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
        .btn-group {
            margin-right: unset;
            margin-left: 18px;
        }
    }
    // error page
    .error-wrapper {
        .col-md-8 {
            &.offset-md-2 {
                margin: 0 auto !important;
            }
        }
    }
    // product page
    .product-price {
        del {
            margin-right: 15px;
        }
    }
    .product-box {
        .modal {
            .modal-header {
                .btn-close {
                    right: unset;
                    left: 15px;
                }
            }
        }
        .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
        .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
            border-left-color: $info-color;
        }
        .ribbon-bookmark.ribbon-vertical-left:before,
        .ribbon-bookmark.ribbon-vertical-right:before {
            right: 0;
            left: unset;
            border-left: 15px solid #2a3142;
        }
        .ribbon-bookmark.ribbon-info:before {
            border-left-color: transparent;
        }
        .ribbon-warning.ribbon-clip:before {
            border-left-color: $warning-color;
            border-right-color: unset;
        }
        .ribbon-clip {
            right: -14px;
            left: unset;
            &:before {
                right: 0;
                left: unset;
            }
        }
    }
    .product-hover {
        ul {
            padding-right: 0;
        }
    }
    .nav {
        padding-right: 0;
    }
    .contacts-tabs {
        .nav-pills {
            border-left: 1px solid $light-color;
        }
    }
    .custom-file-label {
        &::after {
            left: 0;
            right: unset;
        }
    }
    input[type="checkbox"].tui-full-calendar-checkbox-round + span {
        margin-left: 8px !important;
        margin-right: unset;
    }
    input[type="checkbox"].tui-full-calendar-checkbox-square + span {
        margin-left: 5px !important;
        margin-right: unset;
    }
    // general widgets page
    .widget-joins {
        .media {
            .details {
                border-right: 1px solid $light-semi-gray;
                border-left: unset;
            }
            .media-body {
                text-align: right;
            }
        }
    }
    .weather-widget-two {
        .bottom-whetherinfo {
            .whether-content {
                text-align: left;
            }
        }
    }
    .btn-group {
        > .btn:not(:last-child):not(.dropdown-toggle) {
            > .btn-group:not(:last-child) > .btn {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    // authentication page
    .authentication-box {
        text-align: right;
    }
    // mega options page
    .mega-horizontal {
        .offset-sm-3 {
            margin-right: 25%;
            margin-left: unset;
        }
    }
    // navs page
    .navs-icon {
        svg {
            margin-left: 10px;
            margin-right: unset;
        }
        .fa {
            margin-left: 10px;
            margin-right: unset;
        }
    }
    .nav-list {
        .nav-list-disc {
            text-align: right;
            padding-right: 0;
        }
    }
    .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
        left: 77px;
        right: unset;
    }
    .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
        left: 77px;
        right: unset;
    }
    .navs-dropdown {
        .onhover-show-div {
            .navs-icon {
                li {
                    text-align: right;
                }
            }
        }
    }
    .navs-icon.default-according.style-1 {
        li {
            button[aria-expanded="true"] {
                &:before {
                    left: 2px;
                    right: unset;
                }
            }
            button[aria-expanded="false"]:before {
                left: 2px;
                right: unset;
            }
        }
    }
    // search page css
    .search-form {
        .form-group {
            &:after {
                right: 53px;
                left: unset;
            }
            &:before {
                right: 82px;
                left: unset;
            }
        }
    }
    // order history page css
    .order-history {
        table {
            tbody {
                tr {
                    td {
                        .product-name {
                            .order-process {
                                .order-process-circle {
                                    &:before {
                                        right: -15px;
                                        left: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            th,
            td {
                &:first-child {
                    text-align: right;
                }
            }
        }
    }
    // product-page
    .product-page-details {
        span {
            padding-right: 15px;
            padding-left: 0;
        }
    }
    .m-checkbox-inline {
        label {
            margin-left: 20px;
            margin-right: 0;
        }
    }
    .form-label-align-right {
        label {
            text-align: left;
        }
    }
    // ribbons page
    .ribbon-left {
        right: auto;
        left: -2px;
    }
    .ribbon-vertical-left {
        left: auto;
        right: 12px;
    }
    .ribbon-vertical-right {
        right: auto;
        left: 12px;
    }
    .ribbon-vertical-right-wrapper {
        p {
            text-align: left;
        }
    }
    .ribbon-vertical-left-wrapper {
        padding-right: 40px;
        padding-left: unset;
    }
    .ribbon-vertical-right-wrapper {
        padding-left: 40px;
        padding-right: unset;
    }
    .search-page {
        .blog-box {
            .blog-details {
                .blog-social {
                    li {
                        &:first-child {
                            border-left: 0;
                            padding-left: 0;
                        }
                        &:nth-child(n + 2) {
                            border-left: unset;
                            border-right: 1px solid $light-gray;
                            padding-left: unset;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
    .search-list {
        .nav-item {
            &:first-child {
                border-radius: 0 4px 4px 0;
                .nav-link {
                    border-radius: 0 4px 4px 0;
                }
            }
            &:last-child {
                border-radius: 4px 0 0 4px;
                .nav-link {
                    border-radius: 4px 0 0 4px;
                }
            }
        }
    }
    // search page
    .star-ratings {
        ul {
            &.search-info {
                padding-right: 0;
                .rating {
                    li {
                        border-right: 0;
                        padding: 0;
                        margin: 0;
                    }
                }
                li {
                    & + li {
                        border-right: 1px solid #a5afc6;
                        border-left: unset;
                        padding-right: 8px;
                        padding-left: 0;
                        margin-right: 8px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .primary-color,
    .yellow-color {
        ul {
            padding-right: 0;
        }
    }
    // steps page
    .u-step-desc {
        text-align: right;
    }
    .u-step-number {
        right: 20px;
        left: unset;
        & ~ .u-step-desc {
            margin-right: 50px;
            margin-left: unset;
        }
    }
    .u-pearl {
        &:after {
            left: 0;
            right: unset;
        }
        &:before {
            right: 0;
            left: unset;
        }
    }
    .u-step-icon {
        float: right;
        margin-left: 0.5em;
        margin-right: unset;
    }
    // input group page
    .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: unset;
    }
    // tabbed card page
    .tabbed-card {
        ul {
            left: 22px;
            right: unset;
        }
    }
    // checkbox & radios page
    .checkbox {
        label {
            padding-right: 16px;
            padding-left: unset;
            &::before {
                right: 0;
                left: unset;
                margin-right: -16px;
                margin-left: unset;
            }
        }
    }
    .radio {
        label {
            &::after {
                right: 5px;
                left: unset;
                margin-right: -20px;
                margin-left: unset;
            }
        }
    }
    .list-inline-item {
        &:not(:last-child) {
            margin-left: 0.5rem;
            margin-right: unset;
        }
    }
    .like-comment {
        ul {
            &.list-inline {
                padding-right: 0;
            }
        }
    }
    .typeahead {
        text-align: right;
    }
    .listing {
        ul {
            padding-right: 0;
            .icofont {
                float: right;
            }
        }
    }
    // gallery page
    .gallery {
        .hover-5 {
            img {
                margin-right: 30px;
            }
            &:hover {
                img {
                    margin-right: 0;
                }
            }
        }
    }
    .lg-toolbar .lg-icon {
        float: left;
    }
    #lg-counter {
        padding-right: 20px;
        padding-left: unset;
        float: right;
    }
    // chart widget page
    .chart-widget-top {
        .text-end {
            text-align: left !important;
        }
        .num {
            .ms-1 {
                margin-right: 0.25rem !important;
            }
        }
    }
    .bar-chart-widget {
        .earning-details {
            i {
                left: -240px;
            }
        }
        .bottom-content {
            .b-r-light {
                border-left: 1px solid #eeeeee !important;
                border-right: unset !important;
            }
            .num {
                .ms-1 {
                    margin-right: 0.25rem !important;
                }
            }
        }
    }
    // dashboard project page
    .chart-vertical-center {
        #myDoughnutGraph,
        #myPolarGraph {
            width: auto !important;
        }
    }
    .crm-activity {
        ul {
            &.dates {
                li {
                    + li {
                        border-right: 1px solid #ddd;
                        padding-right: 10px;
                        margin-right: 5px;
                        border-left: unset;
                        padding-left: unset;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    div {
        &.dt-buttons {
            float: right;
        }
    }
    // footer page
    .page-wrapper {
        .page-body-wrapper {
            footer {
                margin-right: 280px;
                margin-left: unset;
                p {
                    i {
                        margin-right: 5px;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    .footer-copyright {
        text-align: right;
    }
    .footer-links {
        text-align: left;
    }
    .page-item {
        &:first-child {
            .page-link {
                margin-right: 0;
                border-radius: 0 0.25rem 0.25rem 0;
                border-left: none;
            }
        }
        &:last-child {
            .page-link {
                border-radius: 0.25rem 0 0 0.25rem;
            }
        }
    }
    // popover page
    .popover.top,
    .popover.bottom {
        > .arrow:after {
            margin-right: -10px;
            margin-left: unset;
        }
    }
    // touchspin page
    .bootstrap-touchspin {
        > .input-group-append {
            > .btn,
            > .input-group-text {
                border-radius: 0 !important;
            }
        }
        > .form-control,
        > .custom-select {
            &:not(:last-child) {
                border-radius: 0;
            }
        }
    }
    // typahead page
    .typeahead {
        span {
            &.twitter-typeahead {
                .tt-suggestion {
                    text-align: right;
                }
            }
        }
        .league-name {
            text-align: right;
            margin-right: 5px;
        }
    }
    // call-chat page
    .follow {
        .text-md-end {
            text-align: left !important;
        }
    }
    //customizer rtl css
    .customizer-links {
        right: unset;
        left: 0;
        > .nav {
            padding-right: 10px;
        }
        .nav-link {
            &:after {
                right: -10px;
                left: unset;
                border-width: 5px 5px 5px 0;
                border-left-color: unset;
                border-right-color: lighten($primary-color, 25%);
            }
            span {
                right: unset;
                left: -60px;
            }
            &:hover {
                span {
                    left: 48px;
                }
            }
        }
        &.open {
            right: unset;
            left: 330px;
            border-radius: 0 8px 8px 0;
        }
    }
    .customizer-contain {
        width: 330px;
        right: unset;
        left: -330px;
        &.open {
            right: unset;
            left: 0px;
        }
        .customizer-header {
            .icon-close {
                left: unset;
                right: 30px;
            }
        }
    }
    // boxes layout rtl css
    body.dark-only {
        .widget-joins {
            .media {
                .details {
                    border-left: unset !important;
                    border-right: 1px solid $dark-card-border;
                }
            }
        }
        .custom-card {
            .card-footer {
                > div {
                    + div {
                        border-right: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .page-wrapper {
            .page-body-wrapper {
                .page-body {
                    .card:not(.email-body) {
                        .b-r-light,
                        .border-right {
                            border-right: none !important;
                            border-left: 1px solid $dark-card-border !important;
                        }
                    }
                }
            }
        }
    }
    .page-header {
        .header-wrapper {
            .nav-right {
                .resp-serch-input {
                    .search-form {
                        left: 0;
                        right: unset;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    // responsive css
    @media screen and (max-width: 1660px) {
        .chat-box {
            .chat-right-aside {
                .chat {
                    .chat-header {
                        .chat-menu-icons {
                            li {
                                margin-right: unset;
                                margin-left: 10px;
                                &:last-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        // video chat page
        .caller-img {
            left: unset;
            right: 15px;
        }
    }
    @media screen and (max-width: 1540px) {
        .search-page {
            .blog-box {
                .blog-details {
                    .blog-social {
                        li {
                            &:nth-child(n + 2) {
                                padding-left: unset;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
        }
        .default-dash {
            .profile-greeting {
                .cartoon-img {
                    left: 0;
                    animation: unset;
                    img {
                        margin-left: -80px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1365px) {
        .email-wrap {
            .actions {
                li {
                    &:nth-child(n + 2) {
                        margin-right: 10px;
                        margin-left: unset;
                    }
                }
            }
        }
        .default-dash {
            .profile-greeting {
                .cartoon-img {
                    img {
                        margin-left: -165px !important;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1366px) {
        .file-manager {
            .files,
            .folder {
                li {
                    &:nth-child(odd) {
                        margin-right: 0;
                    }
                }
            }
        }
        .profile-dropdown {
            &.onhover-show-div {
                right: unset !important;
                left: 0 !important;
            }
        }
        .product-wrapper {
            .product-grid {
                .feature-products {
                    margin-right: 0;
                }
            }
        }
        .product-grid {
            margin-right: 0;
        }
        .d-none-productlist {
            margin-left: 10px;
            margin-right: unset;
        }
        .photos {
            ul {
                li {
                    &:nth-child(3n) {
                        margin-left: 15px;
                        margin-right: unset;
                    }
                }
            }
        }
        // chat page
        .chat-left-aside {
            .status-circle {
                left: unset;
                right: 36px;
            }
        }
        .bookmark-tabcontent {
            .list-bookmark {
                .bookmark-card.card {
                    .desciption-data {
                        width: 55% !important;
                    }
                }
            }
        }
        // ecommerce dashboard css
        .best-seller-table {
            table {
                tbody {
                    tr {
                        td {
                            .fa-check-circle {
                                right: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .text-xl-end {
            text-align: left !important;
        }
        // chat page
        .chat-box {
            .pe-xl-0 {
                padding-left: 0 !important;
                padding-right: 15px !important;
            }
            .ps-xl-0 {
                padding-right: 0 !important;
                padding-left: 15px !important;
            }
        }
    }
    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }
    }
    @media only screen and (max-width: 1199.98px) {
        .user-profile {
            .photos {
                ul {
                    li {
                        &:nth-child(3n) {
                            margin-left: 10px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
        .invoice {
            .text-md-end {
                text-align: left !important;
            }
        }
        .default-dash {
            .total-transactions {
                .report-sec {
                    .report-main {
                        border-left: unset;
                    }
                }
            }
        }
        .jkanban-container {
            .btn {
                margin-left: unset;
                margin-right: 20px;
            }
        }
        .email-wrap {
            .email-sidebar {
                .email-left-aside {
                    right: 0;
                    left: unset;
                }
            }
        }
        .blog-single {
            .comment-box {
                ul {
                    .comment-social {
                        margin-right: 0;
                    }
                }
            }
        }
        .link-section > div > h6:before {
            right: unset;
            left: 10px;
        }
        .md-sidebar.job-sidebar {
            .md-sidebar-aside.job-left-aside {
                top: 40%;
                right: 10px;
                left: unset;
                .location-checkbox {
                    span {
                        padding-right: 33px;
                        padding-left: unset;
                    }
                }
            }
        }
        .md-sidebar {
            .md-sidebar-toggle {
                margin-right: 15px;
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        > ul {
                            li {
                                .submenu {
                                    li {
                                        &:first-child {
                                            padding-right: 15px !important;
                                            padding-left: 15px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 45px;
                    }
                }
            }
        }
        .email-wrap {
            .checkbox_animated {
                right: -6px;
            }
            .row {
                .col-xl-6 {
                    padding-right: 15px;
                }
                .col-xl-3 {
                    + .col-xl-3 {
                        padding-left: 15px;
                    }
                }
            }
            .email-app-sidebar {
                .main-menu {
                    & > li {
                        text-align: right;
                        a {
                            i {
                                margin-right: unset;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                ul {
                    padding-right: 0;
                }
            }
            .email-content {
                .email-top {
                    .user-emailid {
                        &:after {
                            float: left;
                        }
                    }
                }
            }
            .email-right-aside {
                .email-body {
                    .pe-0 {
                        padding-right: unset !important;
                    }
                }
                .radius-left {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-radius: 4px;
                }
            }
        }
        .chat-menu-icons {
            text-align: left;
        }
        .chat-box {
            .chat-history {
                .row {
                    .col-sm-7 {
                        padding-left: 0 !important;
                        padding-right: unset !important;
                    }
                }
            }
        }
        .chat-menu {
            left: 0;
            right: unset;
        }
        // ecommerce dashboard css
        .best-seller-table {
            table {
                tbody {
                    tr {
                        td {
                            .fa-check-circle {
                                right: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1170px) {
        .vertical-timeline--two-columns {
            .vertical-timeline-element-content {
                .vertical-timeline-element-date {
                    right: 124%;
                    left: unset;
                }
            }
        }
        .vertical-timeline--two-columns {
            .vertical-timeline-element.vertical-timeline-element--right {
                .vertical-timeline-element-content {
                    .vertical-timeline-element-date {
                        right: auto;
                        left: 124%;
                        text-align: left;
                    }
                }
            }
            .vertical-timeline-element {
                &:nth-child(even) {
                    &:not(.vertical-timeline-element--left) {
                        .vertical-timeline-element-content {
                            .vertical-timeline-element-date {
                                right: auto;
                                left: 124%;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
        .vertical-timeline--two-columns {
            .vertical-timeline-element.vertical-timeline-element--right {
                .vertical-timeline-element-content {
                    float: left;
                }
            }
            .vertical-timeline-element {
                &:nth-child(even) {
                    &:not(.vertical-timeline-element--left) {
                        .vertical-timeline-element-content {
                            float: left;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1170px) {
        .vertical-timeline {
            &::before {
                left: unset;
                right: 18px;
            }
            .vertical-timeline-element-icon {
                right: 0;
                left: unset;
                svg {
                    right: 50%;
                    left: unset;
                    margin-left: unset;
                    margin-right: -12px;
                }
            }
            .vertical-timeline-element-content {
                margin-right: 60px;
                margin-left: unset;
                .vertical-timeline-element-date {
                    float: right;
                }
                .vertical-timeline-element-content-arrow {
                    border-left: 7px solid $light-color;
                    border-right: unset;
                    left: 100%;
                    right: unset;
                }
            }
        }
    }
    @media only screen and (max-width: 991.98px) {
        .default-dash {
            .profile-greeting {
                .cartoon-img {
                    img {
                        margin-left: -200px !important;
                        margin-right: unset;
                    }
                }
            }
        }
        .todo {
            .todo-list-wrapper {
                .mark-all-tasks {
                    left: 20px;
                }
            }
        }
        .page-wrapper {
            &.compact-wrapper {
                .page-body-wrapper {
                    .sidebar-wrapper {
                        .logo-wrapper {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .chat-box {
            .chat-right-aside {
                .chat {
                    .chat-header {
                        .chat-menu-icons {
                            li {
                                margin-left: 7px;
                                margin-right: unset;
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper {
            &.compact-wrapper {
                .page-body-wrapper {
                    div.sidebar-wrapper {
                        &.btn-close_icon {
                            transform: translate(285px);
                            &:hover {
                                transform: translate(285px);
                            }
                        }
                        .logo-wrapper {
                            .back-btn {
                                left: 10%;
                                right: unset;
                            }
                        }
                    }
                }
                .page-header {
                    .header-wrapper {
                        .toggle-sidebar {
                            margin-right: 0;
                            border-right: none;
                            padding-right: 0;
                            border-left: 1px solid #ddd;
                            margin-left: 15px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
        .faq-accordion {
            .faq-header {
                svg {
                    left: 20px;
                }
            }
        }
        .categories {
            .learning-header {
                padding-right: 20px;
            }
        }
        .page-wrapper {
            .page-header {
                .header-logo-wrapper {
                    .logo-wrapper {
                        img {
                            margin-left: 15px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
        .page-body-wrapper {
            .page-body,
            footer {
                margin-right: 0 !important;
            }
        }
        .page-wrapper {
            .page-body-wrapper {
                .page-title {
                    .breadcrumb {
                        margin-right: 0;
                    }
                }
                .page-body {
                    min-height: calc(100vh - 60px);
                }
            }
            .page-header {
                margin-right: 0;
            }
            &.compact-wrapper {
                .page-header {
                    margin-right: 0 !important;
                    margin-left: unset !important;
                }
                .page-body-wrapper {
                    &.sidebar-wrapper {
                        &.btn-close_icon {
                            transform: translate(285px);
                        }
                    }
                }
            }
        }
        .card-body {
            .value-left {
                margin-left: 20px;
            }
        }
        .bookmark {
            ul {
                li {
                    .search-form {
                        .form-control-search {
                            left: -127px;
                        }
                    }
                }
            }
        }
        .card {
            .card-header {
                .card-header-right {
                    left: 15px;
                }
            }
        }
        // ecommerce dashboard css
        .activity-timeline {
            .media {
                .activity-line {
                    right: 26px;
                }
            }
        }
    }
    @media (min-width: 768px) {
        .comment-box {
            .media {
                img {
                    margin-left: 15px;
                }
            }
        }
        .float-md-end {
            float: left !important;
        }
        .text-md-end {
            text-align: left !important;
        }
        .offset-md-2 {
            margin-right: 16.66667%;
            margin-left: unset;
        }
        .offset-md-3 {
            margin-right: 25%;
            margin-left: unset;
        }
        .col-md-10 {
            flex: 0 0 auto;
            width: 83.33333%;
        }
        .text-md-start {
            text-align: right !important;
        }
        .ecommerce-widget {
            .text-md-end {
                text-align: left !important;
                ul {
                    text-align: left;
                }
            }
        }
    }
    @media only screen and (max-width: 767.98px) {
        .project-list {
            .btn {
                float: right;
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .search-form {
                        left: unset;
                        right: 0;
                        input {
                            padding: 10px !important;
                        }
                    }
                }
            }
        }
        .default-dash {
            .profile-greeting {
                .cartoon-img {
                    img {
                        margin-left: -110px !important;
                        margin-right: unset;
                    }
                }
            }
        }
        .offset-xl-3 {
            margin-right: unset;
            margin-left: unset;
        }
        .tabbed-card ul {
            position: relative;
            left: 0;
        }
        .sm-left-text {
            text-align: right !important;
        }
        .list-persons {
            .profile-mail {
                .email-general {
                    ul {
                        padding-left: 0;
                    }
                }
            }
        }
        .comment-box {
            ul {
                ul {
                    text-align: right !important;
                }
            }
        }
        .page-wrapper.compact-wrapper .mega-menu {
            right: 80px;
            .nav-link {
                border: none;
            }
        }
        .chat-box {
            .chat-right-aside {
                .chat {
                    .chat-header {
                        .chat-menu-icons {
                            li + li {
                                margin-right: unset;
                                margin-left: 10px !important;
                            }
                        }
                    }
                    .chat-message {
                        .text-box {
                            .btn {
                                &:before {
                                    transform: scale(-1) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .bookmark-tabcontent {
            .list-bookmark {
                .bookmark-card.card {
                    .desciption-data {
                        width: 48% !important;
                    }
                }
            }
        }
        .page-wrapper.compact-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        margin-right: auto;
                        margin-left: unset;
                    }
                }
            }
        }
        .mega-menu .nav-link.active {
            color: $black;
        }
        .product-wrapper {
            &.sidebaron {
                .product-grid {
                    .product-wrapper-grid {
                        margin-right: 0;
                    }
                }
            }
        }
        .feature-products {
            .filter-toggle {
                margin-right: 10px;
                margin-left: unset;
            }
        }
        .page-wrapper {
            .nav-right {
                .nav-menus {
                    margin-right: unset;
                    margin-left: 57px;
                }
            }
            &.compact-wrapper {
                .nav-right {
                    .nav-menus {
                        margin-right: unset;
                        margin-left: 0;
                    }
                }
            }
        }
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 25px !important;
                    }
                }
            }
        }
        .ecommerce-widget {
            .text-md-end {
                text-align: left !important;
            }
        }
        .bar-chart-widget {
            .earning-details {
                i {
                    left: -147px !important;
                }
            }
        }
    }
    @media (max-width: 1470px) {
        .default-dash {
            .profile-greeting .cartoon-img img {
                margin-left: -80px;
                margin-right: unset;
            }
        }
    }
    @media (min-width: 576px) {
        .offset-sm-3 {
            margin-right: 25%;
            margin-left: unset;
        }
        .text-sm-end {
            text-align: left !important;
        }
        .float-sm-end {
            float: left !important;
        }
        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: unset !important;
        }
    }
    @media only screen and (max-width: 575.98px) {
        .rating {
            .text-end {
                text-align: right !important;
            }
        }
        .border-tab.nav-left {
            .nav-link {
                text-align: center;
            }
        }
        .alert-dismissible {
            padding-right: 15px;
            padding-left: 30px;
            &.inverse {
                padding: 13px 65px 13px 20px;
            }
        }
        .login-card1 {
            .login-form {
                .form-group {
                    .link {
                        float: unset;
                    }
                }
            }
        }
        .datetime-picker {
            .theme-form {
                label.text-end {
                    text-align: right !important;
                }
            }
        }
        .date-picker {
            .text-end {
                text-align: right !important;
            }
        }
        .search-page {
            .blog-box {
                .blog-details {
                    .blog-social {
                        li {
                            &:nth-child(n + 2) {
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }
        .contacts-tabs {
            .media {
                img.m-r-20 {
                    margin-left: 15px;
                    margin-right: unset;
                }
            }
        }
        .user-profile {
            .photos {
                ul {
                    li {
                        &:nth-child(3n) {
                            margin-left: 10px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
        .product-social {
            li {
                &:nth-child(n + 2) {
                    margin-right: 5px;
                    margin-left: unset;
                }
            }
        }
        .invoice .text-xs-center {
            text-align: center !important;
        }
        .ProfileCard-details {
            padding-right: 26px;
            padding-left: unset;
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .cart-dropdown {
                        li {
                            .media {
                                .media-body {
                                    margin-right: unset !important;
                                    margin-left: 20px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .default-dash {
            .profile-greeting {
                .cartoon-img {
                    img {
                        margin-left: -170px !important;
                        margin-right: unset;
                    }
                }
            }
        }
        .todo {
            .todo-list-wrapper {
                .mark-all-tasks {
                    left: 15px;
                }
            }
        }
        .d-inline-block {
            float: unset !important;
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .cart-nav {
                            .cart-dropdown {
                                left: 78px !important;
                                right: unset !important;
                            }
                        }
                        .notification-dropdown {
                            right: unset;
                            left: 38px;
                        }
                    }
                }
            }
        }
        .faq-widgets {
            svg {
                left: 20px;
                right: unset;
            }
        }
        .job-search {
            .media {
                .media-body {
                    h6 {
                        .pull-right {
                            margin-right: 15px;
                            margin-left: unset;
                        }
                    }
                    ul.rating {
                        margin-right: 10px;
                        margin-left: unset;
                    }
                }
            }
        }
        .faq-accordion {
            .faq-header {
                .pull-right {
                    float: left !important;
                }
            }
        }
        .blog-box {
            .blog-details {
                .blog-social {
                    li {
                        &:first-child {
                            padding-left: 10px;
                        }
                        &:nth-child(n + 2) {
                            margin-right: 10px;
                            padding-left: unset;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
        .list-persons {
            .nav-pills {
                .nav-link {
                    .media {
                        .media-body {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .datetime-picker {
            .theme-form {
                .col-form-label.text-end {
                    text-align: right !important;
                }
            }
        }
        .mega-horizontal {
            .offset-sm-3 {
                margin-right: unset;
            }
        }
        .user-profile {
            .profile-post {
                .post-body {
                    .post-react {
                        ul {
                            li {
                                &:nth-child(n + 2) {
                                    margin-right: -12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .bookmark-tabcontent {
            .details-bookmark {
                .bookmark-card.card {
                    .desciption-data {
                        width: 100% !important;
                    }
                }
            }
        }
        .invoice {
            .media-body.text-end {
                text-align: left;
            }
        }
        .feature-products {
            .select-options {
                float: right !important;
            }
        }
        .mega-menu {
            padding-left: unset;
            padding-right: 15px;
        }
        .page-wrapper {
            .nav-right {
                .nav-menus {
                    margin-left: 45px;
                }
            }
            .page-header {
                .header-wrapper {
                    .nav-right {
                        > ul {
                            li {
                                margin-right: unset;
                                margin-left: 1px;
                            }
                        }
                    }
                }
            }
            &.compact-wrapper {
                .nav-right {
                    .nav-menus {
                        margin-left: 0;
                    }
                }
                .page-header {
                    .header-wrapper {
                        .toggle-sidebar {
                            margin-left: 8px;
                            padding-left: 8px;
                            margin-right: unset;
                            padding-right: unset;
                            border-right: unset;
                            border-left: 1px solid #ddd;
                        }
                    }
                }
            }
        }
        .page-header {
            .header-wrapper {
                .nav-right {
                    > ul {
                        > li {
                            &:last-child {
                                padding-right: 10px;
                            }
                        }
                    }
                    &.right-header {
                        ul {
                            &.profile-dropdown,
                            &.chat-dropdown,
                            &.notification-dropdown {
                                right: unset !important;
                                left: 15px !important;
                            }
                        }
                    }
                }
            }
        }
        .faq-accordion {
            .faq-header {
                svg {
                    left: 15px;
                }
            }
        }
        .categories {
            .learning-header {
                padding-right: 15px;
            }
        }
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 15px !important;
                        li {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .search-form {
                .form-group {
                    margin-left: 0;
                }
            }
        }
        .card-body {
            .value-left {
                margin-left: 15px;
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        > ul {
                            li {
                                .profile-media {
                                    img {
                                        margin-left: unset;
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 480px) {
        .apex-chart {
            #donutchart,
            #piechart {
                svg {
                    transform: translate(30px, 0) !important;
                }
            }
        }
        .customers.avatar-group {
            margin-left: 0;
            margin-right: unset;
        }
        .radio {
            label {
                &::before {
                    margin-right: -16px;
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .notification-dropdown {
                            li {
                                .media {
                                    .media-body {
                                        margin-left: unset !important;
                                        margin-right: 52px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .customizer-links {
            &.open {
                left: 258px;
                right: unset;
            }
        }
        .customizer-contain {
            width: 260px;
            .customizer-header {
                padding: 15px 15px;
            }
            .customizer-body {
                padding: 15px 15px;
                select {
                    width: 200px;
                }
                .main-layout {
                    > li {
                        width: 68px;
                        height: 65px;
                    }
                }
            }
        }
        .custom-profile {
            .card-social {
                li {
                    + li {
                        margin-right: 10px;
                    }
                }
            }
        }
        .icon-hover-bottom {
            .icon-first {
                margin-left: 10px;
                margin-right: unset;
            }
        }
        .knob-block {
            .chart-clock-main {
                .clock-small {
                    right: 38px;
                    top: 74px;
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .onhover-show-div {
                            right: unset;
                            left: 20px;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 375px) {
        .ecommerce-dash {
            .get-support {
                .support-sec-img {
                    left: -70px;
                }
            }
        }
    }
    @media only screen and (max-width: 420px) {
        .listings {
            small {
                text-align: left;
            }
        }
        .chat-right-aside {
            .chat {
                .chat-header {
                    .chat-menu-icons.float-sm-end {
                        float: right !important;
                    }
                }
            }
        }
        .invoice {
            .invo-profile {
                .media {
                    .media-body {
                        margin-right: 0 !important;
                    }
                }
            }
        }
        .file-manager {
            .files,
            .folder {
                li {
                    &:nth-child(n + 2) {
                        margin-right: 0;
                        margin-left: unset;
                    }
                }
            }
        }
        .project-list {
            .border-tab.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 5px 0 5px 15px;
                    }
                }
            }
        }
        .default-dash {
            .profile-greeting {
                text-align: center;
            }
        }
    }
    @media only screen and (max-width: 359.98px) {
        .sticky-header-main {
            .card-header {
                h5 {
                    a {
                        float: right;
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 360px) {
        .listings {
            ul.list-group {
                .list-group-item-action {
                    img.media-body {
                        margin-left: 8px;
                        margin-right: unset;
                    }
                }
            }
        }
        .dismiss-text {
            .alert-dismissible {
                .btn-close {
                    left: 14px;
                    top: 14px !important;
                    right: unset;
                }
            }
        }
        .btn-toolbar {
            .btn {
                &:last-child {
                    margin-right: 0 !important;
                    margin-left: unset !important;
                }
            }
        }
        .user-profile {
            .profile-post {
                .post-body {
                    .post-comment {
                        li {
                            &:last-child {
                                margin-right: 0;
                                margin-left: unset;
                            }
                        }
                    }
                }
            }
        }
        .checkout {
            .order-place {
                text-align: right !important;
            }
        }
        .kanban-item {
            .list {
                li {
                    i {
                        margin-right: unset;
                        margin-left: 3px;
                    }
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .cart-nav {
                            .cart-dropdown {
                                left: 40px !important;
                            }
                        }
                    }
                }
            }
        }
        .prooduct-details-box {
            .btn-close {
                left: 0;
            }
        }
        .social-app-profile {
            .hovercard {
                .user-image {
                    .avatar {
                        img {
                            width: 85px;
                            height: 85px;
                        }
                        margin-top: -47px;
                    }
                }
            }
        }
        .comment-box {
            ul {
                ul {
                    margin-right: 60px;
                }
            }
        }
        .icon-hover-bottom {
            .icon-title {
                text-align: center;
            }
        }
        .social-chat {
            .other-msg {
                margin-right: 15px;
            }
        }
    }
    @media screen and (max-width: 1460px) {
        .sidebar-wrapper {
            .sidebar-main {
                .sidebar-links {
                    .simplebar-wrapper {
                        .simplebar-mask {
                            .simplebar-content-wrapper {
                                .simplebar-content {
                                    > li {
                                        margin-left: 8px;
                                        margin-right: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bookmark-tabcontent {
        .details-bookmark.list-bookmark {
            .bookmark-card {
                .details-website {
                    .hover-block {
                        text-align: left;
                    }
                }
            }
        }
    }
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    .notification-dropdown {
                        li {
                            .media {
                                .notification-img {
                                    right: 0;
                                    left: unset;
                                }
                                .media-body {
                                    margin-left: 0;
                                    margin-right: 60px;
                                }
                                .notification-right {
                                    span {
                                        margin-left: 5px;
                                    }
                                    a {
                                        margin-right: 10px;
                                        margin-left: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .default-dashboard-section {
        .profile-greeting {
            .cartoon-img {
                left: 30px;
                right: unset;
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
    .ecommerce-dashboard-section {
        .support-sec.profile-greeting {
            .support-sec-img {
                right: unset;
                left: 0;
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}
/**=====================
    34. RTL CSS Ends
==========================**/
