/**=====================
    26. Table  CSS Start
==========================**/
.table-bordered {
    td,
    th {
        border-color: $light-gray !important;
    }
}
.pills-component {
    width: 20%;
}
.product-page-width {
    width: 80%;
}
.table {
    margin-bottom: $table-b-margin;
    th,
    td {
        padding: $table-padding;
    }
    th {
        color: $table-heading-color;
    }
    td {
        color: $table-row-color;
    }
    [class*="bg-"] {
        color: $white;
    }
    .bg-light {
        color: $theme-body-font-color;
    }
    tfoot {
        font-weight: $table-footer-font-weight;
    }
    thead {
        &.bg-primary {
            tr {
                th {
                    color: $white;
                }
            }
        }
        &.table-primary {
            background-color: rgba($primary-color, 0.1);
            --bs-table-bg: rgba($primary-color, 0.1);
            color: var(--theme-default);
            tr {
                th {
                    color: var(--theme-default);
                }
            }
        }
    }
}
.table-striped {
    &.table-styling {
        > tbody {
            > tr {
                &:nth-of-type(odd) {
                    &:hover {
                        > * {
                            color: $primary-color;
                        }
                    }
                    > * {
                        --bs-table-accent-bg: none;
                        color: $white;
                    }
                }
            }
        }
    }
    &.bg-primary {
        > tbody {
            > tr {
                &:nth-of-type(odd) {
                    * {
                        color: $white;
                    }
                }
            }
        }
    }
}
.table-primary {
    thead {
        tr {
            th {
                color: $white;
            }
        }
    }
    tbody {
        tr {
            th:nth-child(1) {
                color: $white;
            }
            td {
                color: $white;
            }
        }
    }
}
.table-inverse {
    th,
    td {
        color: $white;
    }
}
.table[class*="bg-"] {
    th,
    td {
        color: $white;
    }
}
.card-block.row {
    .table-responsive {
        thead {
            border-top: 2px solid $light-semi-gray;
        }
    }
}

table {
    thead {
        .border-bottom-primary {
            th {
                border-bottom: 2px solid $primary-color !important;
            }
        }
    }
}
.table-responsive {
    tfoot {
        &.table-success {
            border-top: 0;
        }
    }
    .table-striped {
        tbody {
            tr {
                &:last-child {
                    th {
                        &:first-child {
                            border-radius: 0 0 0 15px;
                        }
                    }
                    td {
                        &:last-child {
                            border-radius: 0 0 15px 0;
                        }
                    }
                }
            }
        }
    }
    .table-inverse,
    .table-striped,
    .table-borderedfor {
        border-radius: 0 0 15px 15px;
    }
    .table-borderedfor {
        .table-active {
            &:last-child {
                td {
                    &:last-child {
                        border-radius: 0 0 15px 0;
                    }
                    &:first-child {
                        border-radius: 0 0 0 15px;
                    }
                }
            }
            td {
                &:last-child {
                    border-radius: 0;
                }
            }
        }
    }
    .table-active {
        th {
            &:first-child {
                border-radius: 0 0 0 15px;
            }
        }
        td {
            &:last-child {
                border-radius: 0 0 15px 0;
            }
        }
    }
}
.table-hover {
    tbody {
        tr:hover {
            background-color: rgba($primary-color, 0.1);
            --bs-table-accent-bg: unset;
            --bs-table-hover-bg: none;
            th,
            td {
                color: var(--theme-default);
            }
        }
    }
}
.table-dark {
    th {
        color: $white;
    }
}
//Sizing class
.table-xl {
    td,
    th {
        padding: $table-xl-padding;
        font-size: $table-xl-font;
    }
}
.table-lg {
    td,
    th {
        padding: $table-lg-padding;
        font-size: $table-lg-font;
    }
}
.table-de {
    td,
    th {
        padding: $table-de-padding;
    }
}
.table-sm {
    th,
    td {
        padding: $table-sm-padding;
        font-size: $table-sm-font;
    }
}
.table-xs {
    th,
    td {
        padding: $table-xs-padding;
        font-size: $table-xs-font;
    }
}
//Table Border
.table-border-horizontal {
    tr,
    th,
    td {
        border-top: 1px solid $horizontal-border-color;
        padding: $horizontal-padding;
    }
}
.table-border-vertical {
    tbody {
        th,
        td {
            border-bottom: 0;
        }
    }
    tr,
    th,
    td {
        border-top: 0;
        border-right: 1px solid $horizontal-border-color;
    }
    tr {
        td {
            &:last-child {
                border-right: none;
            }
        }
    }
}
.table-bordernone {
    thead {
        border-top: 1px solid $horizontal-border-color;
        th {
            border-bottom: 0;
        }
    }
    tbody {
        border: 0;
        th {
            border: 0;
        }
    }
    td {
        border: 0;
    }
    .bd-t-none {
        border-top: none;
    }
    .u-s-tb {
        padding: 11px;
    }
}
.table-double {
    border-left: 4px double $table-border-color;
    border-right: 4px double $table-border-color;
    tr {
        border-style: double;
        border-width: 4px;
        border-right: 0;
        border-left: 0;
    }
}
.table-dotted {
    border-left: 2px dotted $table-border-color;
    border-right: 2px dotted $table-border-color;
    tr {
        border-style: dotted !important;
        border-width: 2px;
        border-right: 0;
        border-left: 0;
    }
}
.table-dashed {
    border-left: 2px dashed $table-border-color;
    border-right: 2px dashed $table-border-color;
    tr {
        border-style: dashed !important;
        border-width: 2px;
        border-right: 0;
        border-left: 0;
    }
}
.table-inverse {
    color: $white;
    background-color: $table-inverse-bg-color;
    th,
    td {
        border-color: $white;
    }
    &.table-bordered {
        border: 0;
    }
}
.table-striped {
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: rgba(245, 247, 250, 0.5);
            }
        }
    }
}
.table-bordered {
    td,
    th {
        border-color: $light;
    }
}
.table {
    > :not(:last-child) {
        > :last-child {
            > * {
                border-color: $light-gray;
            }
        }
    }
}
thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: $light-gray;
}
.table-styling {
    &.table-primary {
        &.table-striped {
            tbody {
                tr {
                    color: $white;
                    &:nth-of-type(odd) {
                        --bs-table-accent-bg: unset;
                        &:hover {
                            color: var(--theme-default);
                        }
                    }
                    &:nth-of-type(even) {
                        &:hover {
                            th,
                            td {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
.table-success {
    background-color: rgba($success-color, 0.1);
    color: $success-color;
    tr {
        th {
            color: var(--theme-default);
        }
    }
}
/**=====================
    26.Table CSS Ends
==========================**/
