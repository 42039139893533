.user_box {
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .avatar_preview__container {
        position: relative;
    }
}

.settings_icon__container {
    position: absolute;
    top: 20px;
    right: 30px;

    &:hover {
        cursor: pointer;
    }
}

.user_link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;

    > * {
        margin-bottom: 0 !important;
    }
}

.social_networks__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
}

.achievements__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.empty_achievement {
    color: #707070;
    font-size: 16px;
}

@media only screen and (max-width: 575px) {
    .user_box {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
