.text {
    display: flex;
    gap: 10px;
    font-size: 24px;

    span {
        color: #6362e7;
    }
}

.finish {
    border-radius: 20px;
    background: #fff;
    padding: 50px;

    .buttons {
        display: flex;
        align-items: center;
        margin-top: 30px;
        gap: 20px;

        button {
            padding: 20px 50px;
            font-size: 18px;
        }

        .secondary {
            background-color: #e3e3f3;
        }
    }
}

.passed_terms {
    border-radius: 20px;
    background: #fff;
    padding: 50px;
    margin: 40px 0;

    .result {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        gap: 30px;
        margin-top: 30px;

        .items {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .terms {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .term {
                    background: #fff;

                    &.correct {
                        border: 3px solid #81c314;
                    }

                    &.partly {
                        border: 3px solid #e7d10d;
                    }

                    &.wrong {
                        border: 3px solid #dd5555;
                    }
                }
            }
        }
    }
}
