.article {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal !important;

    .detail {
        font-size: 1.25em;
        padding-top: 17px;
        padding-bottom: 17px;

        .title {
            font-size: 1.2em;
            font-weight: 500;
            line-height: normal;
            color: #6362e7;
        }

        .author_info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;

            .link {
                color: inherit;

                &:hover {
                    color: #6362e7;
                }
            }

            li {
                color: #707070;
            }

            .span_1 {
                font-weight: 400;
            }

            .span_2 {
                font-weight: 300;
            }

            .category_and_tags_section {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .tag {
                    font-weight: 500;
                    font-size: 0.5em;
                    padding: 8px 10px;

                    @media only screen and (max-width: 1500px) {
                        font-size: 0.6em;
                    }
                }
            }
        }

        .description {
            font-size: 0.8em;
            white-space: normal;
            margin-bottom: 30px;
            line-height: normal;

            @media only screen and (max-width: 1500px) {
                font-size: 1em;
            }
        }

        .reactions {
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 1500px) {
        font-size: 0.8rem;
    }
}

.skeleton {
    border-radius: 15px !important;
    height: 300px;
    margin-bottom: 30px;
    width: 100%;
}
