.form__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.actions__container {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5px;
}

.input_title {
    height: 35px;
}

.input_title,
.input_content {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 9px 25px;
    resize: none;
    font-size: 14px;
    line-height: normal;
    width: 100%;
}

.input_content:focus,
.input_content:focus-visible {
    outline: none;
    border: 1px solid #6362e7;
}
