.container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 500;
}

.button {
    padding: 8px 25px;
}
