.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
        margin-bottom: 20px;

        &:first-child {
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .actions__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;
        margin-top: 35px;

        .action {
            font-size: 15px;
            padding: 10px 25px;
        }
    }
}
