.images_preview__container {
    display: grid;
    grid-template: 100% / auto;
    gap: 3px;
    max-height: 400px;
    margin-bottom: 20px;
    > * {
        object-fit: cover !important;
    }

    &_2 {
        grid-template: 100% / 1fr 1fr;
    }

    &_3 {
        grid-template: 50% 50% / 80% 20%;

        > * {
            object-fit: cover !important;

            &:nth-child(1) {
                grid-row-start: 1;
                grid-row-end: 3;
            }

            &:nth-child(2) {
                grid-row: 2;
                grid-column: 2;
            }
        }
    }

    &_4 {
        grid-template: repeat(3, 33%) / 75% 25%;

        > * {
            object-fit: cover !important;

            &:nth-child(1) {
                grid-row-start: 1;
                grid-row-end: 4;
                grid-column: 1;
            }

            &:nth-child(2) {
                grid-column: 2;
                grid-row: 1;
            }

            &:nth-child(3) {
                grid-column: 2;
                grid-row: 2;
            }

            &:nth-child(4) {
                grid-column: 2;
                grid-row: 3;
            }
        }
    }

    &_5 {
        grid-template: 70% 30% / repeat(6, 1fr);

        > * {
            object-fit: cover !important;

            &:nth-child(n - 2) {
                grid-row: 2;
            }

            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 4;
                grid-row: 1;
            }

            &:nth-child(2) {
                grid-column-start: 4;
                grid-column-end: 7;
                grid-row: 1;
            }

            &:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            &:nth-child(4) {
                grid-column-start: 3;
                grid-column-end: 5;
            }

            &:nth-child(5) {
                grid-column-start: 5;
                grid-column-end: 7;
            }
        }
    }

    &_6 {
        grid-template: 80% 20% / repeat(5, 1fr);

        > * {
            object-fit: cover !important;

            &:nth-child(n -1) {
                grid-row: 2;
            }

            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 6;
                grid-row: 1;
            }
        }
    }

    &_7 {
        grid-template: 80% 20% / repeat(6, 1fr);

        > * {
            object-fit: cover !important;

            &:nth-child(n -1) {
                grid-row: 2;
            }

            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row: 1;
            }
        }
    }

    &_8 {
        grid-template: 80% 20% / repeat(7, 1fr);

        > * {
            object-fit: cover !important;

            &:nth-child(n -1) {
                grid-row: 2;
            }

            &:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 8;
                grid-row: 1;
            }
        }
    }

    &_9 {
        grid-template: 35% 35% 30% / repeat(3, 1fr);

        > * {
            object-fit: cover !important;
        }
    }

    &_10 {
        grid-template: repeat(3, 33%) / repeat(12, 1fr);

        > * {
            object-fit: cover !important;

            &:nth-child(1),
            &:nth-child(4) {
                grid-column-start: 1;
                grid-column-end: 5;
            }

            &:nth-child(2),
            &:nth-child(5) {
                grid-column-start: 5;
                grid-column-end: 9;
            }

            &:nth-child(3),
            &:nth-child(6) {
                grid-column-start: 9;
                grid-column-end: 13;
            }

            &:nth-child(7) {
                grid-column-start: 1;
                grid-column-end: 4;
            }

            &:nth-child(8) {
                grid-column-start: 4;
                grid-column-end: 7;
            }

            &:nth-child(9) {
                grid-column-start: 7;
                grid-column-end: 10;
            }

            &:nth-child(10) {
                grid-column-start: 10;
                grid-column-end: 13;
            }
        }
    }
}
