.post_comments {
    margin-top: 20px;
}

.comments_content {
    margin-left: 0px;
}

.replies_container {
    margin-left: 80px;
}
