.terms {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0;

    .amount {
        color: #999;
        font-size: 16px;
        font-weight: 400;
    }

    .first_row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 16px;
        margin-bottom: 15px;

        .select_all {
            display: flex;
            gap: 30px;
        }
    }
}

.term_wrapper {
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #b3b2f3 20%, #fff 20% 80%);
    padding: 30px 0;
    border-radius: 20px;
    font-size: 16px;
    width: 97%;

    .term {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        font-weight: bold;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        text-align: center;
        padding: 0 10px;
    }

    .definition {
        width: 80%;
        padding: 0 50px;
    }
}

.save_button {
    padding: 15px 30px;
    font-size: 16px;
    max-height: 48px;
}

.message {
    margin-top: 10px;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
}
