.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    h5 {
        margin: 0;
        font-size: 16px;
    }

    > .contacts_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > .contact_el {
            color: #707070;
        }
    }
}

.skeleton {
    border-radius: 15px !important;
    height: 120px;
    margin-bottom: 30px;
}
