/**=====================
    12. Form Input CSS Start
==========================**/
%input-mixin1 {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px;
}
%input-mixin2 {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px;
}
.input-group-square {
    .input-group-text,
    .form-control {
        border-radius: 0;
    }
}
.input-group-solid {
    .input-group-text,
    .form-control {
        background: $light-color;
    }
}
.input-group-air {
    box-shadow: 0 3px 20px 0 $light-semi-gray;
}
.pill-input-group span {
    &:last-child {
        border-radius: 0 1.3rem 1.3rem 0;
    }
    &:first-child {
        border-radius: 1.3rem 0 0 1.3rem;
    }
}
/**=====================
     12. Form Input CSS Ends
==========================**/
