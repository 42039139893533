/**=====================
   73. Update  CSS Start
==========================**/
img.media {
    display: unset;
}
.list-group {
    i {
        margin-right: 0;
    }
}
// popover
.popover {
    border: none;
    box-shadow: 0 0 20px rgba($primary-color, 0.1);
    .popover-header {
        background-color: $theme-medium-color;
        color: var(--theme-default);
        border-bottom: none;
    }
    .popover-body {
        color: rgba(43, 43, 43, 0.7);
    }
}
.shadow,
.shadow-lg,
.shadow-sm {
    &.shadow-showcase {
        margin-bottom: 10px;
    }
}
.rating-container {
    margin-bottom: 6px;
}
.pagination {
    &.pagination-primary {
        .page-item.disabled {
            .page-link {
                padding: 8px 12px;
            }
        }
    }
}
.sticky-header-main {
    .sticky-note {
        .note.ui-draggable {
            margin-bottom: 0;
        }
    }
}
.files-dropzone {
    .files-gallery {
        img.media {
            width: 100%;
        }
    }
}
.carousal-page {
    .slick-slider {
        .slick-dots {
            li {
                margin: 0;
                button {
                    &::before {
                        font-size: 8px;
                    }
                }
            }
        }
    }
    .card-body {
        .slick-slider {
            .slick-slide {
                > div {
                    &:first-child {
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}
// tag and pill
.tagandpills {
    .height-equal {
        .card-body {
            > div {
                button {
                    display: flex;
                    align-items: center;
                    span {
                        margin-left: 5px;
                    }
                }
            }
        }
        h1 {
            margin-top: 0;
            margin-bottom: 8px;
        }
    }
    .icons,
    .height-equal {
        .badge {
            padding: 4px 6px 5px;
        }
    }
    .digits {
        .badge {
            padding: 6px 7px 4px;
        }
    }
    .badge {
        padding: 4px 6px 3px;
    }
    a.badge {
        &:hover {
            color: $white;
        }
    }
}
// tooltip css start
.tooltip {
    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: $theme-medium-color;
            }
        }
    }
    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-start {
        .tooltip-arrow {
            &:before {
                border-left-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-end {
        .tooltip-arrow {
            &:before {
                border-right-color: $theme-medium-color;
                border-top-color: transparent;
            }
        }
    }
    .tooltip-inner {
        background-color: $theme-medium-color;
        color: var(--theme-default);
    }
    .tooltip-arrow {
        &:before {
            border-top-color: $theme-medium-color;
        }
    }
}
// tooltip css end
// dropdown css start
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                padding: 6px 16px;
                color: $dark-editor-document;
                opacity: 0.6;
                font-size: 13px;
                border-top: 1px solid $light-semi-gray;
                background: $white;
                &:hover {
                    background-color: $white;
                }
            }
            .dropdown-header {
                padding: 8px 16px;
                font-weight: 400;
                color: $dark-color;
                font-size: 13px;
            }
        }
    }
    .dropup {
        .dropup-content {
            top: auto;
        }
    }
}
// dropdown css end
// accordian css start
.default-according {
    .card {
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            &.bg-primary,
            &.bg-secondary {
                .btn {
                    border-color: $transparent-color;
                }
            }
            i {
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;
            }
            h5 {
                margin-top: 0;
            }
            .btn-link {
                font-size: 16px;
                padding: 16px 20px;
                padding-left: 22px !important;
                width: 100%;
                text-align: left;
                letter-spacing: 0.7px;
                border: 1px solid $light-semi-gray;
                border-radius: 0;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                text-decoration: none;
            }
        }
        .card-body {
            padding: 15px 20px;
            line-height: 22px;
            font-size: 13px;
            border-color: $light-semi-gray;
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $dark-color;
        }
    }
}
// accordian css end
// tab-bootstrap css start
.nav-tabs {
    border-bottom-color: $light-semi-gray;
    .nav-bottom {
        .nav-item {
            .nav-link.active {
                border-color: $light-semi-gray;
            }
        }
    }
}
.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                font-weight: 500;
            }
        }
    }
    .nav-link {
        color: $dark-color;
        &.active {
            font-weight: 500;
        }
    }
    &.nav-bottom {
        .nav-item {
            .nav-link {
                &.active {
                    border-color: $light-gray $light-gray $white;
                }
            }
        }
    }
}
.dropdown-menu {
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
    .dropdown-item {
        color: #2b2b2b;
        opacity: 0.6;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background: $white;
        &:hover {
            background-color: $white;
        }
    }
    .dropdown-divider {
        margin: 0;
        border-top: 1px solid #cccccc;
    }
}
// tab-bootstrap css end
.border-tab {
    .nav-tabs {
        .nav-item {
            .nav-link {
                &.active {
                    border-bottom: 2px solid var(--theme-default);
                }
                &.show {
                    border-bottom: 2px solid var(--theme-default);
                }
                &:focus {
                    border-bottom: 2px solid var(--theme-default);
                }
            }
        }
    }
}
.tabbed-card {
    .nav-tabs {
        .nav-item {
            .nav-link {
                top: 17px;
            }
        }
    }
}
.component {
    .input-group {
        .btn {
            line-height: 2;
            text-transform: capitalize;
        }
    }
}
.login-card {
    .login-main {
        .theme-form {
            label {
                font-size: $body-font-size;
            }
            .show-hide {
                top: 50%;
            }
        }
    }
}
// faq css end
.job-filter {
    .faq-form {
        .form-control {
            font-size: 14px;
            border-color: $light-gray;
        }
        .search-icon {
            width: 16px;
            height: 16px;
        }
    }
}
// scrollable css start
.scrollable-para p {
    color: #4e5666;
    font-size: 16px;
}
/**=====================
   73. Update  CSS End
==========================**/
