.progress {
    height: 97%;
    border-radius: 20px !important;
}

.question {
    height: 25vh;
    border-radius: 20px !important;
    margin-bottom: 30px;
}

.option {
    height: 70px;
    border-radius: 20px !important;
    margin-bottom: 15px;
}
