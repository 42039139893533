.crop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

.actions_container {
    display: flex;
    gap: 20px;
}
