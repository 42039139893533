.post_card {
    padding: 30px;
}

.post_card > .post > .post_content > p {
    color: #2c323f;
    font-weight: 300;
    letter-spacing: normal;
    font-size: 15px;
}
