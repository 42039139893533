.slider_container {
    max-height: 100%;
    aspect-ratio: 3 / 1;

    img {
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .slider {
        height: 100%;
        max-height: 100%;
    }

    .control {
        background-color: white;
        border: 1px solid #6362e7;
        border-radius: 50%;
        color: #6362e7;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        z-index: 10;
        cursor: pointer;

        &:before {
            content: none;
        }

        &.prev_control {
            padding-right: 5px;
            left: -15px;
        }

        &.next_control {
            padding-left: 5px;
            right: -15px;
        }

        .control_image {
            width: 16px;
            color: #6362e7;
        }
    }

    .cont {
        width: 100% !important;
        display: flex !important;
        justify-content: center;

        .i {
            border-radius: 15px;
            max-height: 400px !important;
            width: 90%;
        }
    }
}


@media screen and (max-width: 1200px) {
    .control {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 991px) {
    .banner {
        padding-top: 30px;
    }

    .prev_control {
        left: -10px;
    }

    .next_control {
        right: -10px;
    }
}

@media screen and (max-width: 767px) {
    .control {
        width: 30px;
        height: 30px;
    }

    .next_control {
        padding-left: 0;
    }

    .control_image {
        width: 8px;
    }
}
