.row {
    margin-bottom: 20px;
}

.error_message {
    font-size: 14px;
}

.required_star {
    color: #dd5555;
}

.textarea {
    resize: none;
}

.submit_button {
    font-size: 16px;
}
