.developer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.avatar_container {
    position: relative;
}

.manager_crown {
    position: absolute;
    border: 2px solid #6362e7;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    background-color: white;
    padding: 3px;
    width: 40px;
    height: 40px;
}

.develop_avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 200px;
    height: 200px;
}

.develop_info_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;
    gap: 5px;
}

.link {
    color: inherit;
    font-size: 24px;
    font-weight: 500;

    &:hover {
        color: #6362e7;
    }
}

.role {
    font-size: 20px;
    font-weight: 400;
    color: #707070;
}

.add_member_item {
    cursor: pointer;
}

.delete_member_icon {
    composes: manager_crown;
    cursor: pointer;
}

.add_member_avatar {
    border: 2px solid #6362e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_member_text {
    font-size: 24px;
    font-weight: 500;
}
