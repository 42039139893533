.custom_field {
    font-size: 14px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header {
    align-items: center;
    font-weight: 400;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
}

.icon_container {
    box-sizing: border-box;
    border: 1px solid #6362e7;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
}

.rotate_45 {
    transform: rotate(45deg);
}

.header > span {
    display: inline;
    line-height: normal;
}
