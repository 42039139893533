/**=====================
    38. cart CSS start
==========================**/
.cart {
    .total-amount {
        text-align: right !important;
        margin-top: 30px;
    }
    .amount-space {
        margin-left: 40px;
    }
    .cart-btn-transform {
        text-transform: capitalize;
    }
}
.cart-buttons {
    margin-top: 30px;
    .cart-btn {
        float: right;
        padding-right: 85px;
    }
}
/**=====================
    38. cart CSS Ends
==========================**/
