.detail_info_popover > div {
    --bs-popover-max-width: 500px;
}

.action_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
}
