.flash_card {
    background-color: transparent;
    height: 60vh;
    perspective: 1800px;

    .flash_card_inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        background-color: #fff;
        border-radius: 20px;

        .spinner {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .flash_card_front,
    .flash_card_back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px;
    }

    .flash_card_back {
        transform: rotateY(180deg);
        font-size: 18px;
        justify-content: space-between;

        .form_control {
            font-size: 18px;
            font-weight: 500;
            color: #707070;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 10px;
            margin: 0;
            cursor: pointer;
            max-width: 156px;

            input {
                -webkit-appearance: none;
                appearance: none;
                width: 30px;
                height: 30px;
                border: 2px solid #707070;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                transition: 0.1s;

                &::before {
                    content: "";
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    opacity: 0;
                    background-color: #6362e7;
                    transition: 0.1s;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &:checked {
                    border: 2px solid #6362e7;
                }

                &:checked::before {
                    opacity: 1;
                }
            }
        }
    }

    .term {
        margin: 0;
    }

    .hint {
        color: #999;
        max-width: 500px;
        text-align: center;
        position: absolute;
        bottom: 50px;

        .arrow {
            margin-top: 5px;
        }
    }
}

.flipped .flash_card_inner {
    transform: rotateY(180deg);
}

.redo_icon {
    margin: 24px auto;
    background-color: #fff;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;

    .arrow_next {
        transform: rotate(270deg);
    }
}
