/**=====================
    20. React-Plugin CSS Start
==========================**/
.simple-list {
    .list-group-item {
        padding: 0;
        border: none;
        background-color: transparent;
    }
}
li.list-group-item.active {
    background-color: unset;
    border-color: unset;
    color: unset;
}
img.media {
    margin-bottom: 0 !important;
}
ul.list-group {
    display: block;
    li.list-group-item.active {
        background-color: $transparent-color;
        border-color: $transparent-color;
    }
    img {
        width: auto;
        height: auto;
    }
    i {
        margin-right: unset;
    }
}
// slick
.slick-page {
    .slick-slider {
        margin: -8px 0 20px;
    }
}
.slick-slider {
    margin: -7px 0 0;
    .item {
        &:focus {
            outline: none;
        }
    }
    .slick-dots {
        li {
            button {
                &:before {
                    font-size: 12px;
                    color: var(--theme-default);
                }
            }
            &.slick-active {
                button {
                    &:before {
                        color: var(--theme-default);
                    }
                }
            }
        }
    }
}
// date picker
.cal-date-widget {
    .react-datepicker {
        border: none;
        padding: 8px 16px 0;
    }
    .react-datepicker__navigation {
        top: 20px;
    }
    .react-datepicker__day--keyboard-selected {
        background-color: $primary-color;
    }
    .react-datepicker__navigation-icon {
        &::before {
            border-width: 2px 2px 0 0;
            width: 8px;
            height: 8px;
        }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        height: 32px;
    }
    .react-datepicker__day--outside-month {
        color: $gray-60;
    }
    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        &:hover {
            background-color: $primary-color;
            color: $white;
        }
    }
}
.react-datepicker {
    border: 1px solid $light-semi-gray;
}
.react-datepicker__header {
    background-color: $white;
    border: none;
}
.react-datepicker__navigation {
    background-color: $primary-color;
    border-radius: 5px;
    color: $white;
    top: 13px;
    &.react-datepicker__navigation--previous {
        left: 15px;
    }
    &.react-datepicker__navigation--next {
        right: 15px;
    }
}
.react-datepicker__day--selected {
    background-color: $primary-color;
    &:hover {
        background-color: $primary-color;
    }
}
.react-datepicker__navigation-icon--next {
    left: -3px;
}
.react-datepicker__navigation-icon {
    top: 0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: $primary-color;
    padding: 10px;
}
.react-datepicker__navigation-icon--previous {
    right: 0;
}
.react-datepicker__day-name {
    font-weight: bold;
}
.react-datepicker__week,
.react-datepicker__day {
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
    &::before {
        top: 7px;
        left: -3px;
        border-color: $white;
    }
}
.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle {
        margin-left: -10px;
    }
}
// tree css
.deni-react-treeview-container {
    width: 100% !important;
    .icon-and-text {
        .text {
            .text-inner {
                color: $primary-color;
                font-size: 14px;
                .treeview-item-example-text {
                    vertical-align: middle;
                }
            }
        }
    }
}
.deni-react-treeview-container.classic {
    border-color: $body-color !important;
    .deni-react-treeview-item-container.classic {
        &.selected.select-row {
            background: linear-gradient(
                to bottom,
                rgba($primary-color, 0.1) 0%,
                rgba($primary-color, 0.5) 100%
            ) !important;
        }
        &:hover.select-row {
            background-color: rgba($primary-color, 0.1) !important;
            border: unset;
        }
        .checkbox {
            border-color: $primary-color !important;
        }
        .expand-button.hasChild {
            color: $primary-color !important;
        }
        .actionButton {
            margin-left: 6px;
        }
        .icon-and-text {
            &.selected {
                background: linear-gradient(
                    to bottom,
                    rgba($primary-color, 0.1) 0%,
                    rgba($primary-color, 0.6) 100%
                ) !important;
                border: unset;
            }
            &:hover:not(.select-row) {
                background-color: rgba($primary-color, 0.1) !important;
                border: unset;
                padding-left: calc(5px - 0px);
            }
            .icon {
                opacity: 0.5;
            }
        }
    }
}
.deni-react-treeview-item-container {
    .expand-button.colapsed {
        &::before {
            height: 29px;
        }
    }
}
// tour page
.reactour__helper {
    .reactour__close {
        top: 16px;
        right: 16px;
    }
    span[data-tour-elem="badge"] {
        background-color: $primary-color;
        height: 30px;
    }
    .reactour__dot {
        &.reactour__dot--is-active {
            background-color: $primary-color;
        }
    }
}
// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
    .rangeslider__fill {
        background-color: var(--theme-default) !important;
    }
    .rangeslider__handle {
        &:focus {
            outline: none;
        }
    }
}
.slider-vertical,
.vertical-height {
    .value {
        text-align: center;
    }
}
.rangeslider-vertical {
    .rangeslider__labels {
        margin: 0 0 0 32px !important;
    }
}
.input-range__slider {
    background: var(--theme-default);
    border: 1px solid var(--theme-default);
}
.input-range__track--active {
    background: var(--theme-default);
}
.input-range {
    margin-bottom: 34px;
}
// dropzone
.dzu-dropzone {
    border: 2px dashed var(--theme-default) !important;
    background: rgba($primary-color, 0.2);
    overflow: auto !important;
    .dzu-inputLabel {
        padding: 0 14px;
    }
    .dzu-previewImage {
        max-height: 54px;
    }
    .dz-message {
        .note {
            background-color: $transparent-color;
        }
    }
}
.dzu-inputLabel {
    color: var(--theme-default) !important;
    text-align: center;
    margin-bottom: 0;
    font-family: $font-roboto;
}
.dzu-previewContainer {
    padding: 30px !important;
    border-bottom: unset;
}
.dzu-inputLabelWithFiles {
    margin-left: 30px !important;
    background-color: var(--theme-default) !important;
    color: $white !important;
}
.dzu-submitButton {
    background-color: var(--theme-default) !important;
    &:disabled {
        background-color: #e6e6e6 !important;
    }
}
// Image Cropper
.input-cropper {
    border-radius: 4px;
    width: auto;
    ~ .ReactCrop {
        margin-top: 20px;
    }
}
.ReactCrop {
    ~ div {
        > canvas {
            margin-top: 25px;
            width: 35% !important;
            height: 35% !important;
        }
    }
}
.crop-portion {
    margin-left: 30px;
    margin-top: 30px;
    vertical-align: baseline;
}
.cropper-view-box {
    outline-color: var(--theme-default);
    outline: 1px solid var(--theme-default);
}
.cropper-point {
    background-color: var(--theme-default);
}
.card {
    > .list-group {
        + .card-footer {
            border-top: 1px solid $light-gray;
        }
    }
}
// sticky
.note {
    background-color: rgba($primary-color, 0.3);
    &:nth-child(2n) {
        background: rgba($secondary-color, 0.3);
    }
}
.react-stickies-wrapper {
    .btn-close {
        &:before,
        &:after {
            background: $transparent-color !important;
        }
    }
    .react-grid-layout {
        .react-grid-item {
            &:nth-child(1n) {
                .note {
                    background: lighten($primary-color, 35%) !important;
                }
            }
            &:nth-child(2n) {
                .note {
                    background: lighten($secondary-color, 35%) !important;
                }
            }
            &:nth-child(3n) {
                .note {
                    background: lighten($success-color, 50%) !important;
                }
            }
            &:nth-child(4n) {
                .note {
                    background: lighten($info-color, 30%) !important;
                }
            }
            &:nth-child(5n) {
                .note {
                    background: lighten($warning-color, 35%) !important;
                }
            }
            &:nth-child(6n) {
                .note {
                    background: lighten($danger-color, 25%) !important;
                }
            }
        }
    }
}
// sweetalert
.lead {
    margin-bottom: 5px;
}
.swal2-container {
    .swal2-actions {
        .swal2-styled {
            font-size: 14px;
        }
    }
}
.swal2-styled {
    &.swal2-confirm {
        background-color: var(--theme-default) !important;
        &:focus {
            box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
        }
    }
}
.swal2-title {
    font-size: 20px !important;
}
.swal2-progress-steps {
    .swal2-progress-step,
    .swal2-progress-step-line {
        background: var(--theme-default) !important;
    }
    .swal2-progress-step {
        &.swal2-active-progress-step {
            background: var(--theme-default) !important;
            ~ .swal2-progress-step,
            ~ .swal2-progress-step-line {
                background: rgba($primary-color, 0.2) !important;
            }
        }
    }
}
// datetime picker
.bootstrap-datetimepicker-widget {
    tr {
        th,
        td {
            &:active,
            &.active {
                background-color: var(--theme-default);
            }
            span {
                &:active,
                &.active {
                    background-color: var(--theme-default);
                }
            }
        }
    }
}
// upload
.fileContainer {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    background: $transparent-color !important;
    .uploadPictureContainer {
        margin: 1% !important;
    }
    .chooseFileButton {
        margin-top: 0 !important;
    }
}
// daterange picker
.daterangepicker {
    td,
    th {
        &.available {
            &:hover {
                background-color: var(--theme-default);
            }
        }
    }
}
// to-do
.todo {
    .action-box {
        &.large {
            .icon {
                .icon-trash,
                .icon-check {
                    color: var(--theme-default);
                }
            }
        }
    }
    .todo-list-wrapper {
        #todo-list {
            li {
                &.completed {
                    .task-container {
                        .task-label {
                            color: var(--theme-default);
                        }
                    }
                }
            }
        }
    }
    @keyframes taskHighlighter {
        0% {
            background: rgba($primary-color, 0.5);
        }
        100% {
            background: $white;
        }
    }
}
// clock
.fcrYGG {
    background-color: transparent !important;
    .sc-htpNat {
        background: url(../../images/clock-face.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }
}
.react-clock__face {
    border: 1px solid $white !important;
}
.react-clock__mark__body,
.react-clock__hand__body {
    background-color: $white !important;
}
// scrollable page
.scrollarea {
    max-width: 800px;
    max-height: 300px;
    .scrollbar-container {
        z-index: 7 !important;
    }
}
.horz-scroll-content {
    width: 1600px;
}
//form wizard
ol {
    display: flex;
    &.progtrckr {
        list-style-type: none;
        padding: 0;
        li {
            display: inline-block;
            text-align: center;
            line-height: 4.5rem;
            cursor: pointer;
            width: 100%;
            em {
                display: none;
            }
            span {
                padding: 0 1.5rem;
            }
            &.progtrckr-todo,
            &.progtrckr-doing,
            &.progtrckr-done {
                &:before {
                    font-size: 20px !important;
                    width: 30px !important;
                    height: 30px !important;
                    line-height: 30px !important;
                }
            }
            &.progtrckr-doing {
                color: $black;
                border-bottom: 4px solid $gray-60;
                &:before {
                    content: "\2022";
                    color: $white;
                    background-color: $gray-60;
                    width: 1.2em;
                    line-height: 1.2em;
                    border-radius: 1.2em;
                }
                &:hover {
                    &:before {
                        color: $danger-color;
                    }
                }
            }
            &.progtrckr-done {
                color: $black;
                border-bottom: 4px solid $primary-color;
                &:before {
                    content: "\2713";
                    color: $white;
                    background-color: $primary-color;
                    width: 1.2em;
                    line-height: 1.2em;
                    border-radius: 1.2em;
                }
                &:hover {
                    &:before {
                        color: $white;
                    }
                }
            }
            &:after {
                content: "\00a0\00a0";
            }
            &:before {
                position: relative;
                bottom: -3.7rem;
                float: left;
                left: 50%;
            }
            &.progtrckr-todo {
                &:before {
                    content: "\039F";
                    color: silver;
                    background-color: $transparent-color;
                    width: 1.2em;
                    line-height: 1.4em;
                }
                &:hover {
                    &:before {
                        color: $danger-color;
                    }
                }
            }
        }
    }
}
.multi-step {
    position: relative;
    .footer-buttons {
        .btn-lg {
            font-size: 14px;
        }
    }
    .btnsubmit {
        position: absolute;
        bottom: -34px;
        right: 15px;
    }
}
// calender
.rbc-calendar {
    height: 1000px !important;
}
#external-events {
    position: absolute;
    z-index: 2;
    padding: 0 10px;
    border: 1px solid $gray-60;
    background: #eee;
    overflow-y: scroll;
    p {
        font-size: 14px;
    }
}
#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}
.rbc-toolbar {
    button {
        border: 1px solid var(--theme-default) !important;
        font-size: 14px;
        &:active,
        &.rbc-active {
            background-color: var(--theme-default) !important;
            box-shadow: none !important;
            color: $white !important;
        }
    }
}
.rbc-today {
    background-color: rgba($primary-color, 0.1) !important;
}
.rbc-row-content {
    z-index: 1 !important;
}
.rbc-header {
    padding: 5px !important;
}
.rbc-month-view {
    height: auto !important;
}
.rbc-event,
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    background-color: var(--theme-default) !important;
}
// draggable calender
#external-events {
    background-color: $transparent-color !important;
    overflow-y: auto !important;
    padding: 10px !important;
    width: 90%;
}
.fc-event,
.fc-event-dot {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
    padding: 7px !important;
    color: $white !important;
}
.fc {
    .fc-button {
        .fc-icon {
            vertical-align: -2px;
        }
    }
}
.fc-button-primary {
    background-color: var(--theme-default) !important;
    border-color: var(--theme-default) !important;
}
//   base-typeahead
.rbt-token {
    color: var(--theme-default) !important;
    background-color: rgba($primary-color, 0.1) !important;
    .rbt-token-remove-button {
        top: 1px !important;
    }
}
.btn-toolbar {
    margin-bottom: -8px !important;
}
.rbt-aux {
    .rbt-close {
        margin-top: 0;
        &.btn-close {
            background: unset;
        }
    }
}
// time-picker
.react_times_button {
    .wrapper {
        display: block;
    }
}
.time_picker_wrapper2 {
    display: flex;
    .time_picker_container {
        width: 44%;
    }
    .gap {
        padding: 0 5px;
    }
}
.datetime-picker {
    .input-group {
        flex-wrap: unset;
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 96px;
    }
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: $primary-color;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $primary-color;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: $primary-color;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba($primary-color, 0.2);
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: $primary-color;
}
.react-datepicker__close-icon {
    &::after {
        background-color: $primary-color;
    }
}
.date-range-picker {
    .daterange-card {
        padding-bottom: 10px;
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 96px;
    }
}
// toastr
.Toastify__close-button {
    font-weight: 500;
    svg {
        stroke: $white !important;
        fill: $white !important;
    }
}
.Toastify__toast--success {
    background: $success-color !important;
    color: $white !important;
    font-weight: 500;
    svg {
        fill: transparent !important;
        stroke: $white !important;
    }
    .Toastify__progress-bar {
        background: lighten($success-color, 35%) !important;
    }
}
.Toastify__toast--info {
    background: lighten($info-color, 15%) !important;
    color: $white !important;
    svg {
        fill: transparent !important;
        stroke: $white !important;
    }
    .Toastify__progress-bar {
        background: lighten($info-color, 35%) !important;
    }
}
.Toastify__toast--warning {
    background: lighten($warning-color, 5%) !important;
    color: $white !important;
    svg {
        fill: transparent !important;
        stroke: $white !important;
    }
    .Toastify__progress-bar {
        background: lighten($warning-color, 15%) !important;
    }
}
.Toastify__toast--error {
    background: lighten($danger-color, 10%) !important;
    color: $white !important;
    font-weight: 500;
    svg {
        fill: transparent !important;
        stroke: $white !important;
    }
    .Toastify__progress-bar {
        background: lighten($danger-color, 20%) !important;
    }
}
// draggable card
.draggable-card {
    > div {
        > div {
            width: 100%;
            display: flex;
            .react-kanban-column {
                width: 100%;
            }
            .react-kanban-card {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
}
.ui-sortable {
    .react-kanban-board {
        div[data-rbd-droppable-id="board-droppable"] {
            width: 100%;
            display: flex;
        }
        .react-kanban-column {
            width: 100%;
        }
        .react-kanban-card {
            max-width: 100%;
        }
    }
}
.jkanban-container {
    .colorfull-kanban {
        .kanban-drag {
            .react-kanban-column {
                &:nth-child(2) {
                    div[data-rbd-drag-handle-context-id="1"] {
                        background-color: $warning-color;
                        ~ div {
                            background-color: rgba($warning-color, 0.3);
                        }
                    }
                }
                &:nth-child(3) {
                    div[data-rbd-drag-handle-context-id="1"] {
                        background-color: $success-color;
                        ~ div {
                            background-color: rgba($success-color, 0.3);
                        }
                    }
                }
            }
            div[data-rbd-drag-handle-context-id="1"] {
                background-color: $info-color;
                color: $white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                ~ div {
                    background-color: rgba($info-color, 0.3);
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
                span {
                    font-weight: 500;
                }
                span.badge {
                    font-weight: 400;
                }
            }
        }
    }
    .kanban-container {
        display: flex;
        .kanban-board {
            width: 100%;
        }
        .kanban-item {
            .customers {
                ul.simple-list {
                    li {
                        background-color: $transparent-color;
                        + li {
                            img {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            &:focus {
                outline: none;
            }
            .kanban-box {
                border: 1px solid $light-semi-gray;
                background-color: $white !important;
                color: $theme-body-font-color;
                &:focus {
                    outline: none;
                }
            }
        }
        .kanban-drag {
            width: 100%;
            .react-kanban-column-header {
                font-weight: 500;
                &:focus {
                    outline: none;
                }
                > div {
                    &:focus {
                        outline: none;
                    }
                }
            }
            > div {
                padding: 0;
                > div {
                    display: flex;
                    width: 100%;
                    margin-bottom: -25px;
                    .react-kanban-column {
                        width: 98%;
                        background-color: $body-color;
                        border-radius: 10px;
                        padding: 0;
                        margin-bottom: 30px;
                        > div {
                            width: 100%;
                            padding: 20px;
                            font-size: 16px;
                            border-bottom: 1px solid rgba($primary-color, 0.1);
                            + div {
                                border-bottom: unset;
                            }
                            p {
                                font-weight: 400;
                            }
                            > div {
                                padding-bottom: 0;
                            }
                        }
                        .react-kanban-board {
                            > div {
                                width: 100%;
                            }
                            + .react-kanban-board {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .react-kanban-column {
        background-color: $body-color;
        > div {
            > div {
                > div {
                    display: block !important;
                }
            }
        }
        div[tabindex="0"] {
            + div[tabindex="0"] {
                margin-top: 20px;
            }
        }
    }
}
//DragNDrop
.ui-sortable {
    .container {
        display: block;
        width: 100%;
        padding: 10px;
        background-color: var(--theme-default);
        div {
            padding: 10px;
            background-color: lighten($primary-color, 4%);
            transition: opacity 0.4s ease-in-out;
            color: $white;
        }
        div {
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.ex-moved {
            background-color: #e74c3c;
        }
        &.ex-over {
            background-color: var(--theme-default);
        }
        &:nth-child(odd) {
            background-color: var(--theme-default);
        }
    }
}
.gh-fork {
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
}
.gu-mirror {
    padding: 10px;
    background-color: darken($primary-color, 5%);
    transition: opacity 0.4s ease-in-out;
    color: $white;
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}
.gu-hide {
    display: none !important;
}
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}
.wrapper {
    display: table;
}
.gu-mirror {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.handle {
    padding: 0 5px;
    margin-right: 5px;
    background-color: darken($primary-color, 15%);
    cursor: move;
}
// timeline
.vertical-timeline {
    &::before {
        background: $light-color;
        width: 2px;
    }
    .vertical-timeline-element-subtitle {
        font-weight: 600;
    }
    .vertical-timeline-element {
        p {
            font-size: 13px !important;
        }
        .vertical-timeline-element-icon {
            color: $white;
            border-radius: 4px;
            box-shadow: none;
        }
        &:first-child,
        &:nth-child(7) {
            .vertical-timeline-element-icon {
                background-color: var(--theme-default);
            }
        }
        &:nth-child(2) {
            .vertical-timeline-element-icon {
                background-color: $secondary-color;
            }
        }
        &:nth-child(3),
        &:last-child {
            .vertical-timeline-element-icon {
                background-color: $success-color;
            }
        }
        &:nth-child(4) {
            .vertical-timeline-element-icon {
                background-color: $info-color;
            }
        }
        &:nth-child(5) {
            .vertical-timeline-element-icon {
                background-color: $warning-color;
            }
        }
        &:nth-child(6) {
            .vertical-timeline-element-icon {
                background-color: $danger-color;
            }
        }
    }
}
.vertical-timeline-element-content {
    box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
    background-color: $light;
    p {
        font-weight: 400;
    }
    audio {
        width: 100%;
        margin-top: 15px;
    }
    .vertical-timeline-element-date {
        font-size: 14px !important;
        padding-bottom: 0;
    }
    .vertical-timeline-element-content-arrow {
        border-right: 7px solid $light-color;
    }
}
.vertical-timeline--two-columns {
    .vertical-timeline-element-content {
        .vertical-timeline-element-date {
            top: 10px !important;
        }
    }
}
.vertical-timeline--one-column {
    &:before {
        left: 28px !important;
    }
    .vertical-timeline-element-icon {
        width: 60px;
        height: 60px;
    }
    .vertical-timeline-element-content {
        margin-left: 90px;
        img {
            width: 500px;
        }
        .vertical-timeline-element-date {
            float: none;
            display: block;
        }
    }
}
// leafleft map
.leaflet-top,
.leaflet-bottom {
    z-index: 7 !important;
}
.leaflet-pane {
    z-index: 1 !important;
}
// vector map
#gmap-simple {
    svg {
        path {
            fill: var(--theme-default) !important;
        }
    }
}
#usa {
    svg {
        path {
            fill: var(--theme-secondary) !important;
        }
    }
}
#canada {
    svg {
        path {
            fill: $success-color !important;
        }
    }
}
#asia {
    svg {
        path {
            fill: $warning-color !important;
        }
    }
}
#europe {
    svg {
        path {
            fill: $danger-color !important;
        }
    }
}
#america {
    svg {
        path {
            fill: $dark-color !important;
        }
    }
}
// rating
.rating {
    align-items: center;
    span {
        .fa {
            color: var(--theme-default);
        }
        span {
            span {
                color: var(--theme-default);
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .jkanban-container {
        .kanban-container {
            .kanban-drag {
                padding: 0;
                > div {
                    width: 100% !important;
                    > div {
                        div[data-rbd-drag-handle-context-id="1"] {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}
// form wizard
@media (max-width: 485px) {
    ol {
        &.progtrckr {
            li {
                &:before {
                    bottom: -63px !important;
                }
                span {
                    padding: 0 !important;
                }
                &.progtrckr-todo,
                &.progtrckr-doing,
                &.progtrckr-done {
                    &:before {
                        font-size: 18px !important;
                        width: 20px !important;
                        height: 20px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }
    }
}
/**=====================
    20. React-Plugin CSS Ends
==========================**/
