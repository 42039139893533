.user_avatar__container {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative !important;
    width: 100px;
    height: 100px;
}

.user_avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.edit_icon__container {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.04);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
}
