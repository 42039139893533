/**=====================
    47.  Ecommerce  CSS Start
==========================**/
/**====== Product Start ======**/
.toggle-data {
    cursor: pointer;
}
.product-sidebar {
    .filter-section {
        .card {
            border-radius: 5px;
            .card-header {
                padding: 16px 30px;
                border-radius: 5px;
                h6 {
                    position: relative;
                    .pull-right {
                        i {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            font-size: 10px;
                            width: 100%;
                            height: 10px;
                            text-align: right;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
// wishlist css
.wishlist {
    table {
        tr {
            td {
                svg {
                    color: $danger-color;
                    cursor: pointer;
                }
            }
        }
    }
}
.product-wrapper {
    .page-bottom {
        padding: 20px !important;
        .page-link:focus {
            box-shadow: none;
        }
    }
    .pagination-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .product-sidebar {
        .filter-section {
            .card {
                .left-filter {
                    z-index: 0;
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                    transition: 0.3s;
                    .card-body {
                        padding: 20px;
                    }
                }
            }
        }
    }
    .product-grid {
        .product-wrapper-grid {
            margin-left: 0;
            transition: 0.3s;
        }
    }
    &.sidebaron {
        .product-sidebar {
            .filter-section {
                .card {
                    .left-filter {
                        z-index: 3;
                        opacity: 1;
                        visibility: visible;
                        position: absolute;
                        width: 100%;
                        top: 53px;
                        height: auto;
                        border: 1px solid $light-gray;
                        background-color: $white;
                    }
                }
            }
        }
    }
}
.product-filter {
    .checkbox-animated {
        label {
            color: $light-text;
        }
    }
}
.product-table {
    img {
        height: 40px;
    }
    span,
    p {
        color: $semi-dark;
    }
    tbody {
        tr {
            td {
                &:first-child {
                    text-align: center;
                }
            }
        }
    }
}
.product-table {
    th {
        &:last-child {
            min-width: 120px;
        }
    }
    h6 {
        font-weight: 600;
        color: $dark-color;
    }
    .rdt_TableHead {
        .rdt_TableCol {
            font-weight: 500 !important;
            > div {
                font-size: 15px;
                color: $dark;
            }
            &:nth-child(2) {
                justify-content: flex-start;
            }
        }
    }
    .rdt_TableBody {
        .rdt_TableRow {
            .rdt_TableCell {
                &:first-child {
                    background-color: $auth-bg-color;
                }
                &:nth-child(n + 2) {
                    background-color: $auth-bg-color;
                }
            }
            &:hover {
                .rdt_TableCell {
                    &:first-child {
                        background-color: $auth-bg-color;
                    }
                }
            }
            &:nth-child(even) {
                .rdt_TableCell {
                    &:nth-child(n + 2) {
                        background-color: $white;
                    }
                }
            }
            &:nth-child(odd) {
                .rdt_TableCell {
                    &:first-child {
                        background-color: $body-color;
                    }
                }
            }
        }
        .rdt_TableCell {
            padding: 12px;
        }
    }
}
.d-none-productlist {
    display: none;
    svg {
        vertical-align: middle;
        cursor: pointer;
        width: 16px;
        height: 14px;
    }
}
.product-wrapper-grid {
    &.list-view {
        .product-box {
            display: flex;
            align-items: center;
            .product-img {
                width: 20%;
            }
            .product-details {
                text-align: left;
            }
        }
        [class*="col-"] {
            width: 100%;
        }
    }
}
.slider-product {
    padding: 15px 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 15px;
}
.products-total {
    display: flex;
    align-items: center;
}
.grid-options {
    height: 33px;
    margin-left: 10px;
    ul {
        li {
            a {
                position: relative;
                .line-grid {
                    position: absolute;
                    width: 4px;
                    height: 15px;
                    top: 9px;
                }
                .line-grid-1 {
                    left: 12px;
                }
                .line-grid-2 {
                    left: 18px;
                }
                .line-grid-3 {
                    left: 36px;
                }
                .line-grid-4 {
                    left: 42px;
                }
                .line-grid-5 {
                    left: 48px;
                }
                .line-grid-6 {
                    left: 66px;
                }
                .line-grid-7 {
                    left: 72px;
                }
                .line-grid-8 {
                    left: 78px;
                }
                .line-grid-9 {
                    left: 84px;
                }
                .line-grid-10 {
                    left: 103px;
                }
                .line-grid-11 {
                    left: 109px;
                }
                .line-grid-12 {
                    left: 115px;
                }
                .line-grid-13 {
                    left: 121px;
                }
                .line-grid-14 {
                    left: 127px;
                }
                .line-grid-15 {
                    left: 133px;
                }
            }
        }
    }
}
.square-product-setting {
    height: 36px;
    vertical-align: middle;
    a {
        color: $theme-body-font-color;
    }
    .icon-grid {
        padding: 7px;
        background-color: rgba($primary-color, 0.05);
        display: flex;
        align-items: center;
        margin: 0 10px 0 0;
        svg {
            width: 20px;
            height: 20px;
            color: var(--theme-default);
        }
    }
}
.product-filter {
    .banner-product {
        margin-top: 15px;
    }
    h6 {
        margin-bottom: 15px;
        font-size: 18px;
    }
    .checkbox-animated {
        label {
            font-weight: 500;
        }
    }
    .color-selector {
        line-height: 0.9;
        ul {
            li {
                display: inline-block;
                width: 25px;
                height: 25px;
                cursor: pointer;
                border: 1px solid $light-gray;
                position: relative;
                border-radius: 0;
                &.active {
                    &:before {
                        content: "\f00c";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-right: -50%;
                        transform: translate(-50%, -50%);
                        font-family: $font-awesome;
                        color: $white;
                    }
                }
                &.white {
                    background-color: $white;
                    &.active {
                        &:before {
                            color: $light-text;
                        }
                    }
                }
                &.gray {
                    background-color: $dark-gray;
                }
                &.orange {
                    background-color: #ffb17a;
                }
                &.green {
                    background-color: #6fb866;
                }
                &.purple {
                    background-color: $primary-color;
                }
                &.pink {
                    background-color: pink;
                }
                &.yellow {
                    background-color: #f2f896;
                }
                &.black {
                    background-color: $black;
                }
                &.blue {
                    background-color: #63b4f2;
                }
                &.red {
                    background-color: #ff647f;
                }
            }
        }
    }
    &.new-products {
        margin-top: 30px;
        button {
            width: auto;
        }
        .slick-slider {
            .slick-slide {
                .item {
                    .product-box {
                        .media {
                            display: flex;
                            align-items: center;
                        }
                        .product-img {
                            width: 40%;
                            img {
                                border-radius: 0;
                            }
                        }
                        .product-details {
                            padding: 25px 0;
                            ul {
                                li {
                                    display: inline-block;
                                }
                            }
                        }
                        + .product-box {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
.select2-drpdwn-product {
    .form-control {
        margin-bottom: 10px;
        height: 36px;
        background-color: rgba($primary-color, 0.05);
        color: var(--theme-default);
        font-weight: 600;
        background-image: unset;
        padding-right: 12px;
    }
}
.feature-products {
    .filter-toggle {
        color: #6362e7;
        background-color: rgba(99, 98, 231, 0.05);
    }
    form {
        .form-group {
            position: relative;
            input {
                margin-bottom: 15px;
                height: 50px;
                padding-left: 30px;
            }
            i {
                position: absolute;
                top: 17px;
                right: 30px;
                color: $dark-gray;
            }
        }
    }
}
.modal {
    .modal-dialog-centered {
        .modal-header {
            background-color: $white;
            .product-box {
                .product-details {
                    padding: 25px 0 25px 12px;
                    h6 {
                        text-transform: capitalize;
                    }
                    .product-price {
                        margin-bottom: 10px;
                    }
                    .product-view {
                        padding: 20px 0;
                        border-top: 1px solid rgba($primary-color, 0.2);
                        border-bottom: 1px solid rgba($primary-color, 0.2);
                    }
                    .product-size {
                        margin: 20px 0;
                        ul {
                            li {
                                display: inline-block;
                                background-color: rgba($primary-color, 0.1);
                                button {
                                    color: var(--theme-default);
                                    width: 35px;
                                    height: 35px;
                                    font-size: 16px;
                                    padding: 0;
                                    font-weight: 500;
                                }
                                &:nth-child(n + 2) {
                                    margin-left: 15px;
                                }
                                &.active {
                                    button {
                                        background-color: var(--theme-default) !important;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    .product-qnty {
                        .input-group {
                            margin-bottom: 20px;
                            .btn {
                                padding: 5px 12px;
                            }
                        }
                        .addcart-btn {
                            .btn {
                                font-weight: 600;
                            }
                        }
                    }
                }
                .product-img {
                    img {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
.product-box {
    background-color: $white;
    align-items: center;
    border-radius: 15px;
    .product-details {
        padding: 25px;
        h4 {
            font-weight: 600;
            color: $theme-body-font-color;
            font-size: 20px;
            line-height: 1;
            margin-bottom: 5px;
            text-transform: capitalize;
        }
        p {
            margin-bottom: 5px;
            color: $light-text;
            font-weight: 500;
        }
        h6 {
            color: $theme-body-font-color;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        span {
            color: $dark-gray;
        }
        .rating {
            margin-bottom: 6px;
            i {
                font-size: 16px;
                letter-spacing: 3px;
                color: #ffa800;
            }
        }
    }
    .product-img {
        position: relative;
        img {
            border-radius: 15px 15px 0 0;
        }
        .ribbon {
            z-index: 0;
        }
        .product-hover {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            background-color: rgba($black, 0.2);
            opacity: 0;
            border-radius: 100%;
            transform: scale(0);
            transition: all 0.3s ease;
            ul {
                margin-right: 10px;
                i {
                    font-size: 14px;
                }
                li + li {
                    margin-top: 10px;
                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
                li {
                    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
                    background-color: $white;
                    font-size: 18px;
                    border-radius: 100%;
                    line-height: 1.6;
                    height: 45px;
                    width: 45px;
                    margin: 0 3px;
                    display: flex;
                    align-items: center;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    a {
                        color: var(--theme-default);
                    }
                    .btn {
                        padding: 0;
                        height: 18px;
                        i {
                            color: var(--theme-default);
                        }
                    }
                    &:hover {
                        background-color: var(--theme-default);
                        .btn,
                        i {
                            color: $white;
                        }
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    &:hover {
        .product-hover {
            opacity: 1;
            border-radius: 15px 15px 0 0;
            transform: scale(1);
            transition: all 0.3s ease;
        }
    }
}
.pro-filter-sec {
    display: flex;
    justify-content: space-between;
    .product-sidebar {
        width: 375px;
        min-width: 375px;
        margin-right: 30px;
    }
    .product-search {
        width: 100%;
    }
}
.product-page-main {
    padding: 30px;
    .border-tab.nav-tabs {
        border-bottom: unset;
    }
    .owl-item {
        .item {
            border: 1px solid $light-gray;
            border-radius: 5px;
        }
        &.current {
            .item {
                border: 1px solid var(--theme-default);
            }
        }
    }
    .product-slider {
        margin-bottom: 20px;
    }
    .product-color {
        margin-top: 10px;
        margin-bottom: 10px;
        li {
            &:first-child {
                border-radius: 50%;
            }
            &:last-child {
                border-radius: 50%;
            }
        }
    }
    .pro-group {
        padding-top: 15px;
        padding-bottom: 15px;
        tr {
            line-height: 2;
            td {
                padding: 0;
                color: $dark;
            }
        }
        &:nth-child(n + 2) {
            border-top: 1px solid $light-gray;
        }
        .btn {
            font-weight: 600;
        }
    }
    .pro-slide-right {
        margin-bottom: -20px;
        img {
            width: auto;
            height: 140px;
        }
        .slick-slide {
            margin-bottom: 20px;
        }
    }
}
.product-page-details {
    display: flex;
    justify-content: space-between;
    h4 {
        color: $theme-body-font-color;
        text-transform: uppercase;
        font-size: 18px;
    }
    span {
        padding-left: 15px;
    }
    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}
.product-price {
    font-size: 16px;
    font-weight: 700;
    color: var(--theme-default);
    del {
        color: $light-font;
        margin-left: 15px;
        font-weight: 600;
        font-size: 14px;
    }
}
.product-color {
    line-height: 1;
    li {
        display: inline-block;
        border-radius: 100%;
        height: 20px;
        width: 20px;
    }
}
.product-page-main {
    p {
        font-size: 15px;
        font-weight: 400;
        color: $light-font;
    }
    .nav-link {
        text-transform: uppercase;
        font-weight: 600;
    }
    .pro-group {
        h6 {
            text-transform: capitalize;
            font-weight: 500;
        }
    }
}
.payment-details {
    .form-control {
        &::-webkit-input-placeholder {
            color: #898989 !important;
            font-weight: 400 !important;
        }
    }
    .e-commerce-form {
        label {
            font-weight: 500;
        }
    }
}
.product-social {
    li {
        a {
            font-size: 15px;
            border: 1px solid $light-gray;
            border-radius: 100%;
            height: 35px;
            width: 35px;
            display: inline-block;
            text-align: center;
            line-height: 2.3;
            transition: all 0.3s ease;
            &:hover {
                background-color: var(--theme-default);
                i {
                    color: $white;
                }
            }
        }
        &:nth-child(n + 2) {
            margin-left: 10px;
        }
    }
}
.filter-block {
    h4 {
        margin-bottom: 20px;
        font-weight: 700;
    }
    ul {
        li {
            color: $light-text;
            .form-check {
                margin-bottom: 0;
                label {
                    margin-bottom: 0;
                    vertical-align: middle;
                }
                .form-check-input {
                    &:focus {
                        box-shadow: none;
                    }
                    &:checked {
                        background-color: var(--theme-default);
                    }
                }
            }
            &:nth-child(n + 2) {
                margin-top: 15px;
            }
        }
    }
}
ul.pro-services {
    li {
        svg {
            color: var(--theme-default);
            margin-right: 15px;
        }
        h5 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 5px;
            color: $dark;
        }
        p {
            font-size: 14px;
        }
        &:nth-child(n + 2) {
            margin-top: 20px;
        }
    }
}
.support-ticket {
    .pro-gress {
        .total-num {
            margin-bottom: 0;
            font-weight: 600;
        }
        .progress-showcase {
            margin-top: 30px;
        }
        span {
            font-weight: 500;
            color: $light-font;
        }
        ul {
            li {
                font-weight: 600;
                color: $light-font;
                font-size: 12px;
                span {
                    i {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
.list-products {
    .table-responsive {
        overflow-x: hidden;
    }
    .product-table {
        table {
            th {
                min-width: 150px;
            }
        }
    }
}
/**====== Product  Ends ======**/
.payment-opt {
    li {
        display: inline-block;
        img {
            height: 20px;
            margin: 15px 15px 0 0;
        }
    }
}
@media (max-width: 1600px) {
    .list-products {
        .table-responsive {
            overflow-x: auto;
        }
    }
}
@media (max-width: 1500px) {
    .product-page-main {
        .pro-slide-right {
            img {
                height: 130px;
            }
        }
    }
}
@media (max-width: 1470px) {
    .product-table {
        .rdt_Table {
            .rdt_TableHead {
                .rdt_TableHeadRow {
                    .rdt_TableCol {
                        min-width: 210px;
                    }
                }
            }
            .rdt_TableBody {
                .rdt_TableCell {
                    &:last-child {
                        min-width: 210px;
                    }
                }
            }
        }
    }
    .product-filter {
        .color-selector {
            ul {
                li {
                    height: 22px;
                }
            }
        }
    }
    .pro-filter-sec {
        .product-sidebar {
            width: 320px;
            min-width: 320px;
        }
    }
    .payment-details {
        .theme-form {
            select {
                padding: 12px 10px;
            }
        }
    }
}
@media (max-width: 1366px) {
    .product-filter.new-products {
        .slick-slider {
            .slick-slide {
                .item {
                    .product-box {
                        + .product-box {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .support-ticket {
        .pro-gress {
            .card-body {
                padding: 20px;
            }
        }
    }
    .pro-filter-sec {
        .product-sidebar {
            width: 290px;
            min-width: 290px;
        }
    }
}
@media (max-width: 1280px) and (min-width: 1200px) {
    .product-page-main {
        .xl-50 {
            max-width: 50% !important;
            flex: 0 0 50% !important;
        }
        .pro-slide-right {
            img {
                height: 120px;
            }
        }
    }
}
@media (max-width: 1280px) {
    .filter-block {
        ul {
            li {
                &:nth-child(n + 2) {
                    margin-top: 10px;
                }
            }
        }
    }
    ul {
        &.pro-services {
            li:nth-child(n + 2) {
                margin-top: 10px;
            }
        }
    }
}
@media (max-width: 1199px) {
    .grid-options {
        display: none !important;
    }
    .product-page-main {
        .pro-slide-right {
            .slick-slide {
                margin-top: 20px;
                > div {
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
@media (max-width: 575px) {
    .payment-details {
        .theme-form {
            select,
            input {
                padding: 8px 12px;
            }
            .p-r-0 {
                padding-right: 5px;
            }
        }
    }
    .product-sidebar {
        .filter-section {
            .card {
                overflow: unset !important;
                box-shadow: none !important;
            }
        }
    }
}
@media (max-width: 767px) {
    .credit-card {
        .card-body {
            div.order-first {
                margin-bottom: 20px;
            }
        }
        .mega-form {
            > div {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .support-ticket {
        .card {
            .card-body {
                padding: 15px;
            }
        }
        .pro-gress {
            span {
                font-size: 12px;
            }
            .card-body {
                padding: 15px;
            }
            ul {
                li {
                    font-size: 10px;
                    span {
                        i {
                            margin-left: 5px;
                        }
                    }
                }
            }
            .progress-showcase {
                margin-top: 15px;
            }
        }
    }
}
@media (max-width: 1199px) {
    .xl-cs-35 {
        order: -1;
    }
    .product-page-main {
        .card {
            .card-body {
                padding: 20px;
            }
        }
    }
}
@media (max-width: 991px) {
    .product-box {
        .modal {
            .modal-header {
                .product-box {
                    .product-details {
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 25px;
                        padding-bottom: 0;
                        .product-view {
                            padding-top: 15px;
                            padding-bottom: 15px;
                        }
                        .product-size {
                            margin-top: 15px;
                            margin-bottom: 15px;
                            ul {
                                li {
                                    button {
                                        width: 30px;
                                        height: 30px;
                                        font-size: 14px;
                                    }
                                    &:nth-child(n + 2) {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-page-main {
        .pro-group {
            h6 {
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 767px) {
    .product-page-main {
        .singale-pro-slide {
            img {
                width: 50%;
                height: auto;
                margin-right: auto;
                margin-left: auto;
            }
        }
        .pro-group {
            .btn {
                padding: 7px 14px;
            }
        }
    }
}
@media (max-width: 480px) {
    .product-sidebar.open {
        .filter-section {
            .card {
                .left-filter {
                    opacity: 1;
                    z-index: 2;
                    visibility: visible;
                    .filter-cards-view {
                        background-color: $white;
                        .product-box {
                            display: block;
                            .product-details {
                                padding: 16px 16px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal {
        .modal-dialog-centered {
            .modal-header {
                .product-box {
                    .product-details {
                        .product-qnty {
                            .input-group {
                                width: 45% !important;
                            }
                            .addcart-btn {
                                .btn {
                                    padding: 6px 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn .learning-box {
        .details-main {
            padding: 15px;
            p {
                font-size: 13px;
            }
        }
    }
    .blog-box.blog-list {
        .blog-details {
            hr {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
    .product-page-main {
        .pro-group {
            .btn {
                padding: 7px 8px;
                font-size: 12px;
            }
        }
    }
}
@media (max-width: 420px) {
    ul {
        &.pro-services {
            li {
                h5 {
                    margin-bottom: 0;
                }
            }
        }
    }
    .product-page-main {
        .singale-pro-slide {
            img {
                width: 100%;
            }
        }
    }
    .product-box {
        .modal {
            .modal-header {
                .product-box {
                    .product-details {
                        .product-qnty {
                            fieldset {
                                .input-group {
                                    width: 50%;
                                }
                            }
                            .addcart-btn {
                                .btn {
                                    padding: 5px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-page-details {
        display: block;
        h3 {
            margin-bottom: 5px;
        }
    }
    .product-page-main {
        .pro-slide-right {
            .slick-slide {
                margin-top: 15px;
            }
        }
        .pro-group {
            .btn {
                &:nth-child(n + 2) {
                    margin-top: 5px;
                }
            }
        }
    }
}
/**=====================
    47. Ecommerce  CSS End
==========================**/
