.login_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    > * {
        margin-bottom: 20px;

        &:first-child {
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .actions__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;
        row-gap: 10px;
        margin-top: 35px;

        .action {
            font-size: 15px;
            padding: 10px 25px;
        }
    }
}

.gitlab {
    border: none;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: #874ac7;

    &:hover {
        background-color: #7a20d0;
    }
}
