.checkbox {
    position: absolute;
    opacity: 0;

    + .label {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin: 0;

        .check {
            position: absolute;
            left: 8px;
            height: 12px;
            width: 6px;
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg) scale(1.75);
            opacity: 0;
            transition: 0.1s;

            &.show {
                transform: rotate(45deg) scale(1) translate(-1px);
                opacity: 1;
            }
        }

        &::before {
            content: "";
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: inset 0 0 0 0.05em #cbcbcb;
            transition: 0.1s;
        }
    }

    &:checked {
        + .label::before {
            box-shadow: inset 0 0 0 10px #6362e7;
        }
    }
}
