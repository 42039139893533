.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
}

.tag__container {
    position: relative;

    &:hover {
        .tag_delete__button {
            display: flex;
        }
    }
}

.tag_delete__button {
    background-color: white;
    border: 1px solid #6362e7;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
}

.del {
    width: 10px;
}

.tag {
    font-size: 16px;
}

.add_tag_button {
    border: none;
    border-radius: 50%;
    width: 40px !important;
    height: 40px;
    padding: 0;
}

.plus {
    width: 16px;
    height: 16px;
}

.new_tag_container {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
}

.action {
    background-color: white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0;

    &:disabled {
        svg {
            path {
                stroke: rgba(99, 98, 231, 0.4);
            }
        }
    }
}

.icon {
    width: 15px;
    height: 15px;
}
