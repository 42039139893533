.container {
  padding: 0px !important;
}

.nav {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 15px 10px 10px;
  border-radius: 20px;
  border: 1px solid #6362E7;
  background: #FFF;

  color: #6362E7;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;

  cursor: pointer;

  transition: background-color 0.3s ease;

  &.active {
    background: #6362E7;
    color: #FFFFFF;
  }
}