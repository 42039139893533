.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .media {
        display: flex;
        gap: 20px;
        align-items: center;

        .avatar {
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                transform: scale(1.1);
            }
        }

        .media_body {
            flex: 1 1;

            .user_name {
                color: #242934;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 3px;

                &:hover {
                    color: #6362e7;
                }
            }

            .publish_date {
                color: #999999;
                font-size: 14px;
                margin-bottom: 0;
                text-align: left;
            }
        }
    }

    .action_menu {
        position: relative;

        &:hover {
            .post_settings__container {
                padding: 0;

                .post_settings {
                    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.25);
                    padding: 18px 15px 16px 15px;

                    &::after {
                        background-color: white;
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: -4px;
                        right: 0;
                        width: 38px;
                        height: 4px;
                    }

                    > div {
                        background-color: #6362e7;
                    }
                }
            }

            .post_menu {
                padding: 20px 15px;
                opacity: 1;
                height: auto;
                width: auto;
                right: 0;
                top: 60px;

                &::before {
                    background-color: white;
                    display: block;
                    content: "";
                    position: absolute;
                    top: -4px;
                    right: 0;
                    width: 38px;
                    height: 4px;
                }
            }
        }

        .post_settings__container {
            padding: 18px 15px 16px 15px;
            .post_settings {
                background-color: white;
                border-radius: 10px 10px 0 0;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2px;

                > div {
                    background-color: #707070;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                }
            }
        }

        .post_menu {
            background-color: white;
            border-radius: 10px 0 10px 10px;
            box-shadow: 3px 2px 5px 1px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 30px;
            right: 20px;
            opacity: 0;
            height: 0;
            width: 0;

            > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;

                &:hover {
                    color: #6362e7;
                }

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
