.project_item {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: white;
    color: #2c323f;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
        color: #6362e7;
    }
}

.delete_icon {
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    transition: 0.2s;

    &:hover {
        background-color: lightgray;
    }
}
