.name_input__container {
    margin-bottom: 5px;
}

.value_input__container {
    margin-bottom: 15px;
}

.buttons_container {
    display: flex;
    gap: 15px;
}
