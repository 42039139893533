/**=====================
    58. Authentication CSS Start
==========================**/
// login page //
.login-card {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: rgba($primary-color, 0.1);
    background-position: center;
    padding: 30px 0;
    .btn-showcase {
        .btn {
            line-height: 1;
            padding: 10px 15px;
            margin: 0;
            & + .btn {
                margin-left: 5px;
            }
            svg {
                height: 16px;
                vertical-align: bottom;
            }
            .icon-social-google {
                margin: 0 6px;
            }
        }
    }
    .login-main1 {
        width: 450px;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
        margin: 0 auto;
        background-color: $white;
        .theme-form {
            h4 {
                margin-bottom: 5px;
            }
            label {
                font-size: 15px;
                letter-spacing: 0.4px;
            }
            .checkbox {
                label {
                    color: $light-text;
                    &::before {
                        border: 1px solid #dfdfdf;
                        top: 1px;
                        background-color: $transparent-color;
                    }
                }
            }
            input {
                background-color: #f3f3ff;
                transition: all 0.3s ease;
                &::-webkit-input-placeholder {
                    color: $light-text;
                }
            }
            p {
                margin-bottom: 25px;
                font-size: 14px;
                color: $dark-gray;
            }
            .login-btn {
                margin-bottom: 10px;
                position: relative;
            }
            .link {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }
        &.login-tab1 {
            position: relative;
            border-top-left-radius: 0;
            height: 100%;
            .nav-tabs {
                border-bottom: none;
                position: absolute;
                background-color: $white;
                box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
                left: -70px;
                top: 0;
                margin-bottom: 0;
                .nav-item {
                    .nav-link {
                        position: relative;
                        img {
                            filter: grayscale(100%);
                            width: 20px;
                            height: 20px;
                            transition: 0.5s;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-color: transparent;
                            border-style: solid;
                            top: 12px;
                            border-width: 5px 0 5px 5px;
                            border-left-color: lighten($primary-color, 25%);
                            opacity: 0;
                            transform: scale(0);
                            color: #fff;
                            left: 14px;
                            z-index: 2;
                            transition: all 0.3s ease;
                        }
                        span {
                            position: absolute;
                            right: 0;
                            width: max-content;
                            text-align: right;
                            padding: 7px 10px;
                            display: block;
                            top: 0;
                            border-radius: 5px;
                            transform: scale(0);
                            transition: all 0.3s ease;
                            opacity: 0;
                            color: var(--theme-default);
                            background-color: lighten($primary-color, 25%);
                            box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
                        }
                        &:hover {
                            &:after {
                                opacity: 1;
                                transform: scale(1);
                                transition: all 0.3s ease;
                            }
                            span {
                                opacity: 1;
                                right: 56px;
                                transform: scale(1);
                                transition: all 0.3s ease;
                            }
                        }
                        &.active {
                            img {
                                filter: initial;
                                transition: 0.5s;
                            }
                        }
                    }
                }
            }
            .tab-content {
                height: 458px;
            }
            .border-tab {
                border-radius: 0;
                &.nav-tabs {
                    .nav-item {
                        .nav-link {
                            border-bottom: none;
                            border-right: 2px solid transparent;
                            border-top-right-radius: 0;
                            &.active,
                            &.show,
                            &:focus {
                                border-right: 2px solid var(--theme-default);
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .auth-content {
                text-align: center;
                img {
                    height: 300px;
                }
            }
        }
    }
}
.login-card1 {
    margin: 0 auto;
    padding: 30px 12px;
    .login-form {
        padding: 30px;
        width: 450px;
        background-color: $white;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        .input-group {
            .input-group-text {
                svg {
                    width: 18px;
                    stroke: $primary-color;
                }
            }
        }
    }
}
.show-hide {
    position: absolute;
    top: 24px;
    right: 30px;
    transform: translateY(-50%);
    text-transform: capitalize;
    z-index: 2;
    span {
        cursor: pointer;
        font-size: 13px;
        color: var(--theme-default);
        .show-hide span:before {
            content: "hide";
        }
        &.show {
            &:before {
                content: "show";
            }
        }
    }
}
.needs-validation {
    label {
        margin-bottom: 10px;
    }
    .invalid-tooltip {
        right: 10px;
    }
}
.login-form {
    background-color: $white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    .input-group {
        input {
            background-color: $white !important;
        }
        span.input-group-text {
            background-color: rgba($primary-color, 0.1);
            border: unset;
            i {
                color: $primary-color;
            }
        }
    }
    h4 {
        text-transform: capitalize;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 22px;
    }
    h6 {
        font-size: 14px;
        margin-bottom: 25px;
        color: $light-text;
    }
    .btn {
        text-transform: uppercase;
        font-weight: 700;
        margin-left: auto;
        display: block;
    }
    p {
        font-weight: 600;
        text-align: center;
        margin-bottom: unset;
    }
    .form-group {
        position: relative;
        label {
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 5px;
        }
        .input-group-text {
            background-color: rgba($primary-color, 0.1);
            border: none;
            color: var(--theme-default);
        }
        input {
            transition: all 0.3s ease;
        }
        .form-control {
            &::placeholder {
                font-weight: 400;
            }
            &:focus {
                box-shadow: none;
            }
        }
        .checkbox {
            display: inline-block;
            label {
                padding-top: 0;
                margin-top: 0;
                color: $light-text;
                span {
                    color: rgba($primary-color, 0.5);
                }
            }
        }
        .link {
            float: right;
            font-weight: 500;
        }
        .small-group {
            display: flex;
            align-items: center;
            .input-group {
                &:nth-child(n + 2) {
                    margin-left: 10px;
                }
            }
        }
    }
    ul.login-social {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            display: inline-block;
            width: 35px;
            height: 35px;
            background-color: rgba($primary-color, 0.08);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            a {
                line-height: 1;
                svg {
                    width: 16px;
                    height: auto;
                }
            }
            &:nth-child(n + 2) {
                margin-left: 10px;
            }
        }
    }
    .login-social-title {
        position: relative;
        margin-bottom: 20px;
        z-index: 2;
        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $light-text;
            z-index: -1;
        }
        h5 {
            font-weight: 600;
            font-size: 16px;
            color: $light-text;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            padding-left: 10px;
            padding-right: 10px;
            background-color: $white;
        }
    }
}
@media (max-width: 575px) {
    .login-form {
        .form-group {
            .link {
                float: unset;
            }
        }
        .btn {
            margin-left: unset;
        }
    }
}
@media (max-width: 480px) {
    .login-card1 {
        .login-form {
            .form-group {
                .checkbox {
                    display: block;
                }
            }
        }
    }
    .login-form {
        width: 100%;
        padding: 20px;
    }
}
/**==========================
    58. Authentication CSS Ends
==========================**/
