.body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px !important;

    .header {
        display: flex;
        gap: 10px;
        align-items: center;

        .main_info {
            display: flex;
            flex-direction: column;
            width: 100%;

            > * {
                line-height: normal;
                margin: 0;
            }

            .title {
                color: var(--theme-default);
            }

            .model {
                font-weight: 500;
            }
        }
    }

    .hp_section {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;

        .state {
            background-color: #d9d9d9;
            border-radius: 20px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;

            > span {
                z-index: 1;
            }

            .hp_bar {
                border-radius: 20px;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;

                &.good {
                    background-color: #81c314;
                }

                &.normal {
                    background-color: #efc048;
                }

                &.low {
                    background-color: #cfa330;
                }

                &.danger {
                    background-color: #c33e14;
                }
            }
        }
    }

    .bold {
        font-weight: 500;
    }

    .item_info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .label {
            font-weight: 500;
            line-height: normal;
        }

        .value {
            font-weight: 500;
            color: #999999;
            line-height: normal;
        }
    }
}

.skeleton {
    &.title {
        width: 100%;
        height: 22px;
    }

    &.model {
        width: 60%;
        height: 17px;
    }

    &.hp {
        width: 100%;
        height: 21px;
    }

    &.info {
        width: 100%;
        height: 17px;
    }
}
