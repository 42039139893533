.root {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    font-size: 15px;
    font-family: Rubik, sans-serif;
}

.label {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.wrap {
    position: relative;
}

.input {
    background-color: white;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    width: 100%;
    height: 44px;
    padding: 10px 15px;
    outline: none;
    cursor: pointer;

    &.focus {
        border-bottom: none;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &.invalid {
        border-color: #dd5555;
        transition: border 0.2s;
    }
}

.options__container {
    background-color: white;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    visibility: hidden;
    position: absolute;
    transform: translate(0, 100%);
    bottom: 3px;
    overflow: auto;
    max-height: 170px;
    width: 100%;
    padding: 8px;
    z-index: 2;

    -ms-scrollbar-track-color: transparent;
    scrollbar-color: #fff;

    &.opened {
        visibility: visible;
    }

    &.invalid {
        border-color: #dd5555;
        transition: border 0.2s;
    }

    .options__item {
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding: 10px 15px;
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;

        &:hover {
            background-color: #e3e3f3;
            border: 1px solid #e3e3f3;
            cursor: pointer;
        }
    }
}

.icon__container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        transition: 0.2s;
        &.rotated {
            transform: rotate(180deg);
        }
    }
}
