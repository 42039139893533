/**=====================
     64. Search CSS Start
==========================**/
.search-page {
    .tab-pane {
        .row {
            .card {
                box-shadow: none;
                margin-bottom: 0;
            }
        }
    }
    .blog-box.blog-shadow {
        height: auto;
    }
    .gallery-with-description {
        a {
            img.media {
                border-bottom: 0;
                padding: 10px;
            }
            > div.caption {
                border-top: 0;
                margin-bottom: 0;
                h4 {
                    margin-top: 0;
                }
            }
        }
    }
    .search-form {
        input {
            &:focus {
                outline: none;
            }
        }
        .form-group {
            position: relative;
            border-radius: 50px;
            overflow: hidden;
            .input-group-text {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 20px;
                border-color: var(--theme-default);
                background-color: var(--theme-default);
                padding: 0 20px;
                z-index: 1;
                i {
                    color: $white;
                    font-weight: 600;
                }
            }
        }
    }
    .info-block {
        + .info-block {
            padding-top: 30px;
            border-top: 1px solid $light-gray;
            margin-top: 30px;
        }
    }
    .border-tab.nav-tabs {
        align-items: left;
        justify-content: end;
        .nav-item {
            width: auto;
        }
    }
    .search-links {
        h6 {
            margin-bottom: 0;
            font-size: 18px;
            line-height: 1.6;
        }
    }
    p {
        margin-bottom: 0;
        color: $light-text;
    }
    ul.search-info {
        > li {
            display: inline-block;
            font-size: 12px;
            line-height: 1;
            color: #586082;
            + li {
                border-left: 1px solid lighten($dark-color, 50%);
                padding-left: 8px;
                margin-left: 8px;
            }
            i {
                color: $warning-color;
            }
        }
        .rating {
            li {
                display: inline-block;
            }
        }
    }
    #video-links {
        .embed-responsive + .embed-responsive {
            margin-top: 30px;
        }
    }
    .search-vid-block {
        > div {
            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }
    }
}
.search-list {
    margin-bottom: 30px;
    width: auto;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--theme-default);
    border: none;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    .nav-item {
        background-color: rgba($primary-color, 0.1);
        &:first-child {
            border-radius: 4px 0 0 4px;
            .nav-link {
                border-radius: 4px 0 0 4px;
            }
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
            .nav-link {
                border-radius: 0 4px 4px 0;
            }
        }
        .nav-link {
            border: none;
            padding: 0.7rem 1.5rem;
            opacity: 0.5;
            &.active {
                background-color: rgba($primary-color, 0.1);
                opacity: 1;
            }
        }
        &.show,
        &.active {
            background-color: rgba($primary-color, 0.1);
        }
    }
}
@media (max-width: 1660px) {
    .search-page {
        .search-vid {
            order: -1;
        }
        .search-vid-block {
            margin-bottom: 30px;
            & div {
                &:nth-child(n + 2) {
                    margin-top: 0;
                }
            }
        }
    }
}
@media (max-width: 1199px) {
    .search-page {
        .search-banner {
            margin-top: 30px;
            + .search-banner {
                order: -1;
            }
            .card {
                margin-bottom: unset;
            }
        }
    }
    .search-page {
        .search-vid-block {
            margin-bottom: 20px;
            & div {
                &:nth-child(n + 2) {
                    margin-top: 20px;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .search-page {
        .search-links {
            h6 {
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 575px) {
    .search-page {
        .search-vid-block {
            margin-top: 15px;
        }
        .search-banner {
            + .search-banner {
                margin-top: 15px;
            }
        }
        .search-form {
            .form-group {
                input {
                    padding: 8px 8px 8px 60px;
                }
                .input-group-text {
                    padding: 0 15px;
                }
            }
        }
        .info-block {
            + .info-block {
                margin-top: 15px;
                padding-top: 15px;
            }
        }
    }
    .search-list {
        display: inline-flex;
        margin-bottom: 15px;
        .nav-item {
            display: inline-block;
            margin-bottom: 5px;
            .nav-link {
                padding: 8px 20px;
            }
        }
    }
}
/**=====================
    64. Search CSS Ends
==========================**/
