// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
    padding-left: 0;
    margin-left: $fa-li-width;
    list-style-type: none;
    > li {
        position: relative;
    }
}
.#{$fa-css-prefix}-li {
    position: absolute;
    left: -$fa-li-width;
    width: $fa-li-width;
    top: (2em / 14);
    text-align: center;
    &.#{$fa-css-prefix}-lg {
        left: -$fa-li-width + (4em / 14);
    }
}
