/**=====================
  70. theme layout CSS Start
==========================**/
body {
    &.dark-only {
        .comingsoon {
            .for-light {
                display: block;
                margin: 0 auto;
            }
        }
        .login-card1 {
            .login-form {
                background-color: $dark-card-background;
                .checkbox {
                    label {
                        &::before {
                            background-color: $dark-body-background;
                            border: 1px solid $dark-card-border;
                        }
                    }
                }
            }
        }
        .tabbed-card {
            .nav-tabs {
                &.border-tab.nav-success {
                    border-bottom: 0 !important;
                }
            }
        }
        .listings {
            .tab-javascript {
                ul.list-group {
                    + ul.list-group {
                        li {
                            border-top: 0;
                        }
                    }
                }
            }
            ul.list-group-flush.list-group {
                .list-group-item {
                    border: 0;
                    + .list-group-item {
                        border-top: 1px solid $dark-card-border;
                    }
                }
            }
            ul.list-group {
                .list-group-item.d-flex {
                    color: $dark-all-font-color;
                }
                .list-group-item {
                    background-color: $dark-card-background;
                    border: 1px solid $dark-card-border;
                    + .list-group-item {
                        border-top: 0;
                    }
                    &.list-group-item-primary {
                        color: #004085;
                        background-color: #b8daff;
                    }
                    &.list-group-item-secondary {
                        color: #383d41;
                        background-color: #d6d8db;
                    }
                    &.list-group-item-success {
                        color: #155724;
                        background-color: #c3e6cb;
                    }
                    &.list-group-item-danger {
                        color: #721c24;
                        background-color: #f5c6cb;
                    }
                    &.list-group-item-warning {
                        color: #856404;
                        background-color: #ffeeba;
                    }
                    &.list-group-item-info {
                        color: #0c5460;
                        background-color: #bee5eb;
                    }
                    &.list-group-item-light {
                        color: #818182;
                        background-color: #fdfdfe;
                    }
                    &.list-group-item-dark {
                        color: #1b1e21;
                        background-color: #c6c8ca;
                    }
                }
            }
        }
        .chart-block {
            .chart-overflow {
                #reactgooglegraph-15,
                #reactgooglegraph-10,
                #reactgooglegraph-11 {
                    svg {
                        g {
                            rect {
                                fill: $dark-card-background;
                            }
                        }
                    }
                }
            }
        }
        .support-table {
            .rdt_TableBody div {
                div,
                span {
                    border-color: $dark-border-color;
                }
            }
            .kSJejC,
            .rdt_Pagination {
                background-color: $dark-card-background;
                svg {
                    stroke: $dark-small-font-color;
                }
                div,
                span {
                    color: $dark-small-font-color;
                }
            }
        }
        .border-top {
            border-top: 1px solid $dark-card-border !important;
        }
        .modal-footer {
            border-top: 1px solid $dark-card-border;
        }
        .ProfileCard-avatar {
            svg {
                path {
                    stroke: $dark-small-font-color;
                }
            }
        }
        ol {
            &.progtrckr {
                li {
                    color: $dark-all-font-color;
                    &.progtrckr-doing {
                        border-bottom: 4px solid $dark-card-border;
                        &::before {
                            background-color: $dark-body-background;
                            color: $dark-card-background;
                        }
                    }
                    &.progtrckr-todo {
                        &::before {
                            color: $dark-card-border;
                        }
                    }
                }
            }
        }
        .project-list {
            .border-tab.nav.nav-tabs {
                border-bottom: none !important;
            }
        }
        .contact-form {
            .theme-form {
                input.form-control,
                textarea.form-control {
                    border: 1px solid $dark-card-border;
                }
            }
        }
        .search-page {
            .search-form {
                .form-group {
                    .input-group-text {
                        background-color: $primary-color;
                    }
                }
            }
        }
        .table-responsive {
            table {
                tbody {
                    tr {
                        &:last-child {
                            th,
                            td {
                                border-bottom: unset;
                            }
                        }
                    }
                }
            }
        }
        .project-cards {
            .project-box {
                .customers {
                    ul {
                        li {
                            background-color: $transparent-color;
                        }
                    }
                }
            }
        }
        .cal-date-widget {
            .cal-datepicker {
                .datepicker-here {
                    .react-datepicker {
                        background-color: $dark-card-background;
                        border: 0;
                    }
                    .react-datepicker__day-name,
                    .react-datepicker__day,
                    .react-datepicker__time-name {
                        color: $dark-all-font-color;
                    }
                    .react-datepicker__day--outside-month {
                        color: $dark-small-font-color;
                    }
                    .react-datepicker__header {
                        background-color: $dark-card-background;
                    }
                }
            }
        }
        .react-datepicker__day,
        .react-datepicker__month-text,
        .react-datepicker__quarter-text,
        .react-datepicker__year-text {
            &:hover {
                background-color: $dark-card-background;
            }
        }
        .react-datepicker__day--selected {
            background-color: $primary-color;
            &:hover {
                background-color: $primary-color;
            }
        }
        .react-datepicker__time-container {
            .react-datepicker__time {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
                .react-datepicker__time-box {
                    ul.react-datepicker__time-list {
                        li.react-datepicker__time-list-item {
                            &:hover {
                                background-color: $dark-card-background;
                            }
                        }
                    }
                }
            }
        }
        .product-box {
            background-color: $dark-card-background;
        }
        .product-filter {
            .color-selector {
                ul {
                    li {
                        border: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .modal {
            .modal-dialog-centered {
                .modal-header {
                    background-color: $dark-card-background;
                    .product-box {
                        .product-details {
                            .product-qnty {
                                .input-group {
                                    input {
                                        background-color: $dark-body-background;
                                        color: $dark-all-font-color;
                                    }
                                }
                            }
                            .product-size {
                                ul {
                                    li {
                                        button.btn-outline-light {
                                            border-color: $dark-card-border;
                                            &:hover {
                                                background-color: $dark-body-background;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-box {
            .product-details {
                background-color: $dark-card-background;
                border-radius: 15px;
                h4 {
                    color: $dark-all-font-color;
                }
            }
        }
        ul.pro-services {
            li {
                h5 {
                    color: $dark-all-font-color;
                }
            }
        }
        .data-tables {
            .rdt_TableHead {
                .rdt_TableHeadRow {
                    background-color: $dark-body-background;
                    border-bottom: 1px solid $dark-card-border;
                    .rdt_TableCol {
                        > div {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            .rdt_TableBody {
                .rdt_TableRow {
                    border-bottom: 1px solid $dark-card-border;
                    &:last-child {
                        border-bottom: 0;
                    }
                    .rdt_TableCell {
                        background-color: $dark-body-background;
                        color: $dark-all-font-color;
                    }
                }
                .rdt_TableRow:nth-child(odd) {
                    .rdt_TableCell {
                        background-color: $dark-card-background;
                    }
                }
            }
        }
        .chat-box {
            .chat-right-aside {
                .chat-history {
                    ul {
                        li {
                            &:hover {
                                z-index: 0;
                            }
                        }
                    }
                }
            }
            .people-list {
                ul.list {
                    li {
                        background-color: $transparent-color !important;
                    }
                }
            }
        }
        .product-social {
            li {
                a {
                    border: 1px solid $dark-card-border;
                }
            }
        }
        // timeline css
        .vertical-timeline-element-content {
            background-color: $dark-body-background;
            .vertical-timeline-element-content-arrow {
                border-right: 7px solid $dark-body-background;
            }
        }
        .vertical-timeline {
            &::before {
                background: $dark-body-background;
            }
        }
        .vertical-timeline-element-icon {
            box-shadow: 0 0 0 4px $dark-body-background, inset 0 2px 0 rgba($black, 0.08),
                0 3px 0 4px rgba($black, 0.05);
        }
        // draggable cards
        .draggable-card {
            .react-kanban-board {
                .react-kanban-column {
                    background-color: $dark-body-background;
                    .react-kanban-card {
                        background-color: $dark-card-background;
                        .react-kanban-card__title {
                            border-bottom: 1px solid $dark-card-border;
                        }
                    }
                }
            }
        }
        .note {
            textarea {
                background-color: $transparent-color !important;
            }
        }
        .basic-cards {
            .card {
                .card-header {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .user-profile {
            &.tour {
                .post-about {
                    ul {
                        li {
                            background-color: $dark-card-background;
                        }
                    }
                }
            }
        }
        .range-slider {
            output {
                ~ div {
                    > div[role="slider"] {
                        background-color: $dark-body-background !important;
                        box-shadow: rgba($primary-color, 0.1) 0px 2px 6px !important;
                        > div {
                            background-color: $dark-card-border !important;
                        }
                    }
                }
            }
        }
        .scrollbar-container {
            p {
                color: $dark-all-font-color;
            }
        }
        .u-pearl.error {
            .u-pearl-icon {
                background-color: $dark-body-background;
            }
        }
        .deni-react-treeview-item-container {
            .checkbox.selected {
                background-color: $dark-body-background;
            }
        }
        .deni-react-treeview-container.classic {
            .deni-react-treeview-item-container.classic {
                &.selected {
                    outline-color: $dark-card-border !important;
                }
            }
        }
        .deni-react-treeview-container.classic {
            border-color: $dark-card-border !important;
            .deni-react-treeview-item-container.classic {
                color: $dark-all-font-color !important;
                &:hover.select-row {
                    background-color: $dark-body-background;
                    border-color: $dark-card-border;
                }
                .checkbox {
                    border-color: $dark-card-border !important;
                }
            }
        }
        .deni-react-treeview-container {
            background-color: $dark-card-background;
        }
        .nav.nav-pills {
            &.border-tab {
                .nav-item {
                    .nav-link {
                        color: $dark-all-font-color;
                        &.active {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .nav-primary.nav.nav-tabs {
            &.border-tab {
                .nav-link {
                    &.active {
                        background-color: $transparent-color;
                    }
                }
            }
            .nav-link {
                &.active {
                    background-color: $primary-color;
                }
            }
        }
        .accordian-page {
            .default-according {
                .card {
                    .btn-link {
                        border: 1px solid $dark-card-border !important;
                    }
                    .card-body {
                        background-color: $dark-body-background;
                        border-radius: 0 0 15px 15px;
                    }
                }
            }
        }
        .card-body {
            .modal-dialog.modal-lg {
                .modal-content {
                    border: 1px solid $dark-card-border;
                    .modal-header {
                        border-bottom: 1px solid $dark-card-border;
                    }
                    .modal-footer {
                        border-top: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .table-striped.table-bordered {
            > tbody {
                > tr {
                    &:nth-of-type(odd) {
                        > * {
                            background-color: $dark-body-background;
                        }
                    }
                }
            }
        }
        .mega-form,
        .checkout {
            .form-select {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
            }
        }
        .order-history {
            .title-orders {
                background-color: $dark-body-background;
            }
            table {
                tr {
                    td,
                    th {
                        border-top: none !important;
                    }
                }
            }
            .rdt_Table {
                .rdt_TableBody {
                    .rdt_TableRow {
                        &:hover {
                            background-color: $dark-body-background;
                            border-bottom: 1px solid $dark-body-background;
                            &:nth-child(even) {
                                .rdt_TableCell {
                                    background-color: $dark-body-background !important;
                                }
                            }
                            &:nth-child(odd) {
                                .rdt_TableCell {
                                    background-color: $dark-body-background !important;
                                }
                            }
                            .rdt_TableCell {
                                background-color: $dark-body-background;
                            }
                        }
                        &:nth-child(odd) {
                            .rdt_TableCell {
                                &:first-child {
                                    background-color: rgba($dark-card-background, 0.5);
                                }
                                &:nth-child(n + 2) {
                                    background-color: $dark-card-background;
                                }
                            }
                        }
                        &:nth-child(even) {
                            .rdt_TableCell {
                                &:first-child {
                                    background-color: $dark-card-background;
                                }
                                &:nth-child(n + 2) {
                                    background-color: rgba($dark-card-background, 0.5);
                                }
                            }
                        }
                    }
                }
            }
            .rdt_Table,
            .rdt_TableHeadRow,
            .rdt_TableRow,
            .rdt_Pagination {
                background-color: $dark-body-background;
                div,
                span {
                    color: $dark-all-font-color;
                }
                button {
                    fill: $dark-all-font-color;
                }
            }
        }
        .product-table {
            .rdt_TableHead {
                .rdt_TableHeadRow {
                    background-color: $dark-body-background;
                    border-bottom: 1px solid $dark-card-border;
                    .rdt_TableCol {
                        > div {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            .rdt_TableBody {
                .rdt_TableRow {
                    border-bottom: 1px solid $dark-card-border;
                    .rdt_TableCell:first-child {
                        background-color: $dark-body-background;
                    }
                    .rdt_TableCell:nth-child(n + 2) {
                        background-color: $dark-card-background;
                        color: $dark-all-font-color;
                    }
                }
                .rdt_TableRow:nth-child(odd) {
                    .rdt_TableCell:first-child {
                        background-color: $dark-card-background;
                    }
                    .rdt_TableCell:nth-child(n + 2) {
                        background-color: $dark-body-background;
                    }
                }
            }
            .rdt_Pagination {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                > div {
                    button {
                        fill: $dark-small-font-color;
                        svg {
                            fill: $dark-small-font-color;
                        }
                    }
                }
            }
        }
        blockquote {
            border-left: 4px solid $dark-card-border;
            &.text-end {
                border-right: 4px solid $dark-card-border;
                border-left: unset;
            }
            &.text-center {
                border: 0;
            }
        }
        p.txt-dark {
            color: $dark-all-font-color !important;
        }
        .product-page-main {
            .pro-group {
                &:nth-child(n + 2) {
                    border-top: 1px solid $dark-card-border;
                }
            }
            .border-tab.nav-tabs {
                border-bottom: unset !important;
            }
        }
        .pro-slide-right {
            .slick-arrow {
                display: none !important;
            }
        }
        .status-circle {
            border: 2px solid $dark-border-color;
        }
        .custom-card {
            .card-social {
                border: 0;
                li {
                    &:nth-child(1) {
                        background-color: $fb;
                    }
                    &:nth-child(2) {
                        background-color: $google-plus;
                    }
                    &:nth-child(3) {
                        background-color: $twitter;
                    }
                    &:nth-child(4) {
                        background-color: #f05142;
                    }
                    &:nth-child(5) {
                        background-color: $linkedin;
                    }
                }
            }
        }
        // select2 css
        .page-wrapper {
            .page-body-wrapper {
                .page-body {
                    .select2-drpdwn {
                        @each $form-control-name, $form-control-color in (primary, var(--theme-default)),
                            (secondary, var(--theme-secondary)), (success, $success-color), (danger, $danger-color),
                            (info, $info-color), (inverse, $dark-color), (warning, $warning-color)
                        {
                            .form-control-#{$form-control-name} {
                                border-color: $form-control-color;
                                color: $form-control-color;
                            }
                        }
                        .form-control-inverse {
                            color: $dark-all-font-color;
                        }
                        @each $form-control-name-fill, $form-control-color-fill in (primary, var(--theme-default)),
                            (secondary, var(--theme-secondary)), (success, $success-color), (danger, $danger-color),
                            (info, $info-color), (inverse, $dark-color), (warning, $warning-color)
                        {
                            .form-control-#{$form-control-name-fill}-fill {
                                background-color: $form-control-color-fill;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .user-profile {
            .profile-post {
                .post-body {
                    .post-react {
                        ul {
                            li {
                                background-color: $transparent-color;
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .notification-dropdown {
                            li {
                                background-color: $dark-body-background;
                            }
                        }
                        .bookmark-flip {
                            .front {
                                .bookmark-dropdown {
                                    li {
                                        background-color: $dark-body-background;
                                    }
                                }
                            }
                        }
                        .cart-nav {
                            .cart-dropdown {
                                li {
                                    background-color: $dark-body-background;
                                }
                            }
                        }
                        .language-dropdown.onhover-show-div {
                            li {
                                background-color: $dark-body-background;
                            }
                        }
                        .profile-dropdown {
                            background-color: $dark-card-background !important;
                        }
                    }
                }
            }
        }
        .form-bookmark {
            input,
            select {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
            }
        }
        .special-discount {
            .discount-detail {
                .timer-sec {
                    li {
                        background-color: $dark-body-background;
                        span.font-dark {
                            color: $dark-all-font-color !important;
                        }
                    }
                }
            }
        }
        .our-activities {
            table {
                tr {
                    td {
                        .icon-wrappar {
                            background-color: $dark-body-background;
                        }
                    }
                }
            }
        }
        // calender
        .calendar-wrap {
            .fc-unthemed {
                td,
                th {
                    border-color: $dark-body-background;
                }
            }
        }
        .rbc-month-view,
        .rbc-time-view {
            border: 1px solid $dark-card-border;
        }
        .rbc-toolbar {
            button {
                color: $dark-small-font-color;
                &:hover,
                &:focus {
                    background-color: var(--theme-default);
                    color: $white;
                }
            }
        }
        .rbc-month-row {
            + .rbc-month-row {
                border-top: 1px solid $dark-card-border;
            }
        }
        .rbc-off-range-bg {
            background: $dark-body-background;
        }
        .rbc-day-bg {
            + .rbc-day-bg {
                border-left: 1px solid $dark-card-border;
            }
        }
        .rbc-header,
        .rbc-timeslot-group {
            border-bottom: 1px solid $dark-card-border;
            + .rbc-header {
                border-left: 1px solid $dark-card-border;
            }
        }
        .rbc-time-content {
            border-top: 2px solid $dark-card-border;
        }
        .rbc-agenda-view {
            table {
                &.rbc-agenda-table {
                    border: 1px solid $dark-card-border;
                    tbody {
                        > tr {
                            > td {
                                + td {
                                    border-left: 1px solid $dark-card-border;
                                }
                            }
                            + tr {
                                border-top: 1px solid $dark-card-border;
                            }
                        }
                    }
                    thead {
                        > tr {
                            > th {
                                border-bottom: 1px solid $dark-card-border;
                            }
                        }
                    }
                }
            }
        }
        .rbc-day-slot {
            .rbc-time-slot {
                border-top: 1px solid $dark-card-border;
            }
        }
        .rbc-time-header-content,
        .rbc-time-content > * + * > * {
            border-left: $dark-card-border;
        }
        #external-events {
            border: 1px solid $dark-card-border;
        }
        .fc-unthemed {
            th,
            td,
            thead,
            tbody,
            .fc-divider,
            .fc-row,
            .fc-content,
            .fc-popover,
            .fc-list-view,
            .fc-list-heading td {
                border-color: $dark-card-border;
            }
            td {
                &.fc-today {
                    background: $dark-body-background;
                }
            }
            .fc-divider,
            .fc-popover,
            .fc-list-heading {
                background-color: $dark-card-border;
                .fc-header,
                td {
                    background-color: $dark-card-border;
                }
            }
        }
        .fc-theme-standard {
            td,
            th,
            .fc-scrollgrid {
                border: 1px solid $dark-card-border;
            }
        }
        .square-box {
            background-color: $dark-body-background;
        }
        .ProfileCard-details {
            .realname {
                color: $dark-all-font-color;
            }
        }
        .apexcharts-yaxistooltip-left {
            &::before,
            &::after {
                border-left-color: $dark-card-border;
                border-width: 7px;
            }
        }
        .apexcharts-xaxistooltip-bottom {
            &::before,
            &::after {
                border-bottom-color: $dark-card-border;
                border-width: 7px;
            }
        }
        .apexcharts-canvas {
            span {
                color: $dark-all-font-color;
            }
            .apexcharts-xaxistooltip,
            .apexcharts-yaxistooltip {
                color: $dark-all-font-color;
                background: $dark-body-background;
                border: 1px solid $dark-card-border;
            }
            .apexcharts-tooltip {
                border: 1px solid $dark-card-border;
                background: $dark-body-background;
            }
            .apexcharts-tooltip-title {
                background: $dark-card-background;
                border-bottom: 1px solid $dark-card-border;
            }
        }
        .form-control {
            border-color: $dark-card-border;
        }
        .theme-form select.form-control:not([size]):not([multiple]) {
            border-color: $dark-card-border;
        }
        .dzu-previewButton {
            filter: brightness(0) invert(0.7);
        }
        .social-tab {
            .input-group {
                .input-group-text {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
        .page-wrapper {
            &.horizontal-wrapper {
                .page-body-wrapper {
                    .sidebar-wrapper {
                        .sidebar-main {
                            .sidebar-links {
                                .sidebar-list {
                                    a {
                                        svg {
                                            path {
                                                stroke: $dark-all-font-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .notification-dropdown {
                            li {
                                .media {
                                    a {
                                        color: $dark-all-font-color;
                                    }
                                }
                            }
                        }
                        .onhover-show-div.bookmark-flip {
                            .flip-card {
                                .flip-card-inner {
                                    .back {
                                        .flip-back-content {
                                            input {
                                                border: 1px solid $dark-card-border;
                                            }
                                        }
                                    }
                                    .front {
                                        a {
                                            svg {
                                                path {
                                                    stroke: $dark-small-font-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .bookmark-flip {
                            .bookmark-dropdown {
                                .bookmark-content {
                                    .bookmark-icon {
                                        border: 1px solid $dark-card-border;
                                    }
                                }
                            }
                        }
                        > ul {
                            > li {
                                h6.txt-dark {
                                    color: $dark-small-font-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .customizer-contain {
            .customizer-header {
                h5 {
                    color: $dark-all-font-color;
                }
                p {
                    color: $dark-small-font-color;
                }
            }
            .customizer-body {
                .layout-grid.customizer-mix {
                    .color-layout {
                        border: 1px solid $dark-card-border;
                    }
                }
                .tab-pane {
                    > h6 {
                        border-top: 1px solid $dark-card-border;
                        color: $dark-all-font-color;
                        &:first-child {
                            border-top: unset;
                        }
                    }
                }
            }
        }
        .react-datepicker {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
                color: $dark-all-font-color;
            }
            .react-datepicker__day--outside-month {
                color: $dark-small-font-color;
            }
        }
        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
            border-bottom-color: $dark-body-background;
        }
        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
            border-top-color: $dark-body-background;
        }
        .react-datepicker__header {
            background-color: $dark-body-background;
        }
        img.for-dark.media {
            display: block;
        }
        .order-widget {
            .heder-widget {
                h3 {
                    span {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .for-light {
            display: none;
        }
        .login-form {
            .input-group {
                input {
                    background-color: $dark-body-background !important;
                    border: unset;
                }
            }
        }
        .login-card {
            background: $dark-body-background;
            .login-main1 {
                .theme-form {
                    .checkbox {
                        label {
                            &::before {
                                border: 1px solid $dark-card-border;
                            }
                        }
                    }
                }
            }
            .login-main1.login-tab1 {
                background-color: $dark-card-background;
                .border-tab.nav-tabs {
                    .nav-item {
                        background-color: $dark-card-background;
                        .nav-link {
                            &.active {
                                background-color: rgba($primary-color, 0.1);
                            }
                        }
                    }
                }
            }
            .theme-form {
                background: $dark-card-background;
            }
        }
        .login-form {
            .login-social-title {
                h5 {
                    background-color: $dark-card-background;
                }
                &::before {
                    background-color: $dark-card-border;
                }
            }
        }
        .customizer-contain {
            .customizer-body {
                .layout-grid.customizer-mix {
                    .color-layout[dataattr="light-only"] {
                        background-color: $white;
                        .header,
                        .body,
                        .sidebar {
                            background-color: $light !important;
                        }
                        .body {
                            ul {
                                background-color: $white;
                            }
                        }
                    }
                    .color-layout[dataattr="dark-sidebar"] {
                        background-color: $white;
                        .header,
                        .body {
                            background-color: $light !important;
                        }
                        .body {
                            ul {
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper.compact-wrapper {
            .page-body-wrapper {
                div.sidebar-wrapper {
                    .logo-wrapper {
                        img {
                            height: 42px;
                        }
                    }
                }
            }
        }
        color: $dark-all-font-color;
        background-color: $dark-body-background;
        .lighticon {
            opacity: 0;
            transition: all 0.3s ease;
        }
        .darkicon {
            opacity: 1 !important;
            transition: all 0.3s ease;
        }
        .activity-media {
            .media {
                .media-body {
                    span {
                        color: $dark-small-font-color;
                    }
                }
                + .media {
                    border-top: 1px solid $dark-card-border;
                }
            }
        }
        .page-wrapper.compact-wrapper {
            .page-body-wrapper {
                div.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content > li {
                                            a {
                                                svg {
                                                    * {
                                                        stroke: $dark-small-font-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .cart-nav {
                            .cart-dropdown {
                                li {
                                    .media {
                                        .media-body {
                                            h5 {
                                                a {
                                                    color: $dark-all-font-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .language-dropdown.onhover-show-div {
                            li {
                                a {
                                    color: $dark-all-font-color;
                                }
                            }
                        }
                        ul {
                            li {
                                svg {
                                    path {
                                        stroke: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .alert-notify-section {
            .card-footer {
                .btn-light {
                    color: $dark-card-background !important;
                }
            }
        }
        .prooduct-details-box {
            .media {
                .media-body {
                    .product-name {
                        h6 {
                            a {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
        }
        .faq-accordion.default-according#accordionoc {
            .card {
                .faq-header {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .social-list {
            .media {
                .media-body {
                    span {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .edit-profile {
            .profile-title {
                border-bottom: 1px solid $dark-card-border;
            }
        }
        .user-profile {
            .social-media {
                ul.user-list-social {
                    li:nth-child(n + 2) {
                        margin-left: 5px !important;
                        margin-right: unset;
                    }
                }
            }
            .profile-post {
                .post-header {
                    .media {
                        h5 {
                            color: $dark-all-font-color;
                        }
                    }
                }
                .post-body {
                    .post-react {
                        h6 {
                            color: $dark-all-font-color;
                        }
                    }
                    .post-comment {
                        li {
                            label {
                                a {
                                    color: $dark-all-font-color;
                                }
                            }
                        }
                    }
                }
            }
            .post-about {
                ul {
                    li {
                        h5 {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            .follow {
                ul.follow-list {
                    border-top: 1px solid $dark-card-border;
                    li:nth-child(n + 2) {
                        border-left: 1px solid $dark-card-border !important;
                    }
                }
            }
            .profile-header {
                .userpro-box {
                    background-color: $dark-card-background;
                    .icon-wrapper {
                        background-color: $dark-body-background;
                    }
                    h4 {
                        color: $dark-all-font-color;
                    }
                    .avatar {
                        border: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .apexcharts-yaxis {
            .apexcharts-text {
                fill: $dark-all-font-color;
            }
        }
        .static-widget {
            h6 {
                color: $dark-all-font-color;
            }
        }
        // deafult dashboard page css  start
        .default-dash {
            .card.profile-greeting {
                background-color: var(--theme-default) !important;
            }
            .total-transactions {
                .report-sec {
                    .report-content {
                        h5 {
                            color: $dark-small-font-color;
                        }
                    }
                }
            }
            .recent-activity {
                table {
                    tr {
                        td {
                            .media {
                                .media-body {
                                    .activity-msg {
                                        background-color: $dark-body-background;
                                        span {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                    h5 {
                                        color: $dark-all-font-color;
                                    }
                                    p {
                                        color: $dark-small-font-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ongoing-project {
                .table-responsive {
                    tr {
                        th {
                            background-color: $dark-card-inbox;
                        }
                    }
                }
                table {
                    tbody {
                        tr {
                            td {
                                h6 {
                                    color: $dark-all-font-color;
                                }

                                span {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                }
            }
            .table-responsive {
                .badge-light-theme-light {
                    background-color: $dark-body-background;
                    color: $dark-small-font-color !important;
                }
            }
            .news-update {
                h5 {
                    color: $dark-all-font-color;
                }
                p {
                    color: $dark-small-font-color;
                }
            }
            .icon-box {
                .onhover-show-div {
                    ul {
                        li {
                            background-color: $dark-body-background;
                            border-bottom: 1px solid $dark-card-background;
                            &:last-child {
                                border-bottom: none;
                            }
                            &:hover a {
                                color: $primary-color;
                            }
                            a {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
            .earning-card {
                h4,
                h6 {
                    color: $dark-all-font-color;
                }
                span {
                    color: $dark-small-font-color;
                }
            }
            .profile-greeting {
                .greeting-user {
                    .btn {
                        color: $dark-all-font-color;
                        border-color: $dark-all-font-color;
                        &:hover {
                            color: $primary-color;
                        }
                    }
                    h1 {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        // dashboard 2
        .ecommerce-dash {
            .total-sale {
                .card-header {
                    background-color: transparent !important;
                    .animat-block {
                        ul {
                            li {
                                border: 12px solid $dark-card-inbox;
                                background-color: $dark-card-background;
                            }
                        }
                    }
                }
            }
            .ongoing-project {
                table {
                    tbody {
                        tr {
                            td {
                                h6 {
                                    color: $dark-all-font-color;
                                }
                                span {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                }
            }
            .our-activities {
                table {
                    tr {
                        td {
                            h5 {
                                color: $dark-all-font-color;
                            }
                            p {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                }
            }
            .table-responsive {
                tr {
                    th {
                        background-color: $dark-card-inbox;
                    }
                }
                .badge-light-theme-light {
                    background-color: $dark-body-background;
                    color: $dark-small-font-color !important;
                }
            }
            .hot-selling {
                .table {
                    tbody {
                        tr {
                            td {
                                h5 {
                                    color: $dark-all-font-color;
                                }
                                p {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                }
            }
            .icon-box {
                .onhover-show-div {
                    ul {
                        li {
                            background-color: $dark-body-background;
                            border-bottom: 1px solid $dark-card-background !important;
                            &:last-child {
                                border-bottom: none;
                            }
                            &:hover a {
                                color: $primary-color;
                            }
                            a {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
            .sales-state {
                .sales-small-chart {
                    &:after {
                        background-color: $dark-card-border;
                    }
                    h6 {
                        color: $dark-all-font-color;
                    }
                    span {
                        color: $dark-small-font-color;
                    }
                }
            }
        }
        .crypto-dash {
            .buy-sell {
                .buy-sell-form {
                    .form-group {
                        .input-group {
                            background-color: $dark-body-background;
                            input.form-control {
                                border: 0;
                                background-color: $dark-body-background;
                            }
                            span {
                                color: $dark-all-font-color;
                            }
                        }
                        label {
                            color: $dark-all-font-color;
                        }
                        .dropdown {
                            .dropdown-toggle {
                                background-color: $dark-body-background;
                                &:hover {
                                    background-color: $primary-color;
                                }
                            }
                        }
                    }
                }
                .card-header {
                    .media {
                        ul {
                            background-color: $dark-body-background;
                        }
                    }
                }
            }
            .candlestick-chart {
                .candlestick-detail {
                    h3 {
                        color: $dark-all-font-color;
                    }
                    h4 {
                        color: $dark-small-font-color;
                    }
                }
            }
            .cripto-chat {
                .chat {
                    .right-side-chat {
                        .msg-box {
                            background-color: $dark-body-background;
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            .table-responsive {
                .badge-light-theme-light {
                    background-color: $dark-body-background;
                    color: $dark-small-font-color !important;
                }
            }
            .cripto-notification {
                table {
                    tr {
                        td {
                            .media {
                                .media-body {
                                    h5 {
                                        color: $dark-all-font-color;
                                        span {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                    p {
                                        color: $dark-small-font-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ongoing-project {
                table {
                    tbody {
                        tr {
                            td {
                                h6 {
                                    color: $dark-all-font-color;
                                }
                                span {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .icon-box {
            .onhover-show-div {
                ul {
                    li {
                        background-color: $dark-body-background;
                        border-bottom: 1px solid $dark-card-background !important;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover a {
                            color: $primary-color;
                        }
                        a {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        // general widget
        .general-widget {
            .widget-feedback {
                h3 {
                    color: $dark-all-font-color;
                }
                h6 {
                    color: $dark-small-font-color;
                }
                ul {
                    border-top: 1px solid $dark-card-border;
                    li {
                        &:nth-child(n + 2) {
                            border-left: 1px solid $dark-card-border;
                        }
                        h4 {
                            color: $dark-small-font-color;
                        }
                    }
                }
            }
            .contact-form {
                .form-icon {
                    i {
                        color: $primary-color;
                    }
                }
            }
            .social-widget-card {
                .card-footer {
                    .row {
                        .col:nth-child(n + 2) {
                            border-left: 1px solid $dark-card-border;
                        }
                    }
                }
            }
            .custom-profile {
                .card-footer > div {
                    div + div {
                        border-left: 1px solid $dark-card-border;
                    }
                }
            }
            .widget-joins {
                .widget-card {
                    border: 1px solid $dark-card-border;
                    background-color: $dark-body-background;
                }
            }
        }
        // state color page
        .colors-palette-section {
            > .card-body {
                .card {
                    .card-body {
                        div.color-name {
                            background-color: $dark-body-background;
                            h6 {
                                color: $light-1;
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .page-title {
                .breadcrumb {
                    .breadcrumb-item {
                        a {
                            background-color: $dark-card-background;
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .resp-serch-input {
                            .search-form {
                                input {
                                    background-color: $dark-card-background;
                                    border-color: $dark-card-border;
                                    color: $white;
                                }
                            }
                        }
                        .cart-dropdown {
                            li {
                                .media {
                                    .cart-quantity {
                                        border-color: $dark-card-border;
                                        input {
                                            color: $dark-all-font-color;
                                        }
                                        span {
                                            i {
                                                color: $dark-all-font-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .customizer-links {
            background-color: $dark-body-background;
            > .nav {
                background-color: $dark-body-background;
            }
        }
        .progress-showcase {
            .progress {
                background-color: $light-1;
            }
        }
        .loader-box {
            .loader-30 {
                border-top-color: aliceblue;
                border-bottom-color: aliceblue;
                &::after,
                &::before {
                    background: aliceblue;
                }
            }
            .loader-22 {
                border: 1px solid aliceblue;
                &::after,
                &::before {
                    background: aliceblue;
                }
            }
            .loader-4 {
                border: 1px solid aliceblue;
                &::before {
                    background: aliceblue;
                }
            }
        }
        .knowledgebase-search {
            .form-inline {
                background-color: $dark-card-background;
            }
        }
        .browse {
            .browse-articles {
                background-color: $dark-card-background;
                h4 {
                    color: $dark-all-font-color;
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .loader-10 {
            &::after,
            &::before {
                background: aliceblue;
            }
        }
        .custom-profile {
            .card-social {
                .social-link {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                }
            }
        }
        .apexcharts-tooltip.light {
            .apexcharts-tooltip-title {
                background: $dark-card-background;
                color: $white;
            }
        }
        .card {
            background-color: $dark-card-background;
        }
        .ecommerce-widget {
            border: 1px solid $dark-card-border !important;
        }
        .plain-style {
            .card {
                border: 0 !important;
            }
        }
        .form-select {
            color: $dark-all-font-color;
            option {
                .form-select {
                    color: $black;
                }
            }
        }
        .add-project {
            .text-inherit {
                color: $dark-all-font-color;
            }
        }
        .knowledgebase-search {
            color: $theme-body-font-color;
            .form-control-plaintext {
                color: $theme-body-font-color;
            }
        }
        .react-datepicker__time-container {
            border-left: 1px solid $dark-card-border;
        }
        .select2-drpdwn {
            span:empty {
                background-color: $dark-card-border;
            }
            .css-1s2u09g-control {
                background-color: $dark-body-background;
                border-color: $dark-card-border;
            }
        }
        .datetime-picker {
            .input-group {
                .input-group-text {
                    border-right: 1px solid $dark-card-border;
                }
            }
        }
        .date-range-picker {
            .react-datepicker {
                color: $dark-all-font-color;
            }
            .react-datepicker__month-select,
            .react-datepicker__year-select {
                background-color: $dark-card-background;
                border-color: $dark-card-border;
            }
        }
        .rbt-token {
            .rbt-token-remove-button.btn-close {
                filter: unset;
            }
        }
        .btn-close {
            filter: brightness(0.8) invert(1);
        }
        .prooduct-details-box {
            .media {
                border: 1px solid $dark-card-border;
            }
        }
        .theme-form {
            input[type="file"] {
                background-color: $dark-body-background;
            }
        }
        .product-price {
            del {
                color: $dark-small-font-color;
            }
        }
        .form-control-plaintext {
            color: $dark-all-font-color;
        }
        .form-select {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
        }
        .box-layout {
            background-color: $dark-body-background;
            .page-wrapper,
            &.page-wrapper {
                .page-header {
                    .header-wrapper {
                        border: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .btn.btn-primary {
            z-index: 0;
        }
        .left-side-header {
            .input-group {
                span {
                    border-color: $dark-card-border !important;
                    svg {
                        stroke: $dark-all-font-color;
                    }
                }
                input {
                    border-color: $dark-card-border !important;
                    color: $white;
                }
            }
        }
        .form-check-input {
            background-color: $dark-body-background;
        }
        .switch-state {
            &:before {
                background-color: $dark-card-background;
            }
        }
        .bootstrap-touchspin {
            .dropdown-basic {
                .dropdown {
                    .dropbtn {
                        background-color: $dark-body-background !important;
                        color: $dark-all-font-color !important;
                    }
                }
            }
        }
        // daterangepicker css start
        .daterangepicker {
            &:before {
                border-bottom-color: $dark-card-background;
            }
            .ranges {
                li {
                    background-color: $dark-body-background;
                    border-color: $dark-card-background;
                    color: $dark-gray;
                }
            }
            .daterangepicker_input {
                .form-control {
                    background-color: $dark-body-background;
                    border-color: $dark-card-background !important;
                }
            }
            .calendar-time {
                select {
                    border-color: $dark-card-background;
                    background-color: $dark-body-background;
                }
            }
            &.dropdown-menu {
                background-color: $dark-card-background;
                box-shadow: 0 0 14px $dark-body-background;
            }
            .calendar-table {
                background-color: $dark-card-background;
                border-color: $dark-card-background;
            }
            &.ltr {
                td {
                    &.in-range,
                    &.off {
                        background-color: $dark-body-background;
                        color: $white;
                    }
                }
            }
        }
        // daterangepicker css end
        // tooltip css start
        .tooltip {
            &.bs-tooltip-top {
                .tooltip-arrow {
                    &:before {
                        border-top-color: $dark-card-inbox;
                    }
                }
            }
            &.bs-tooltip-bottom {
                .tooltip-arrow {
                    &:before {
                        border-bottom-color: $dark-card-inbox;
                        border-top-color: $transparent-color;
                    }
                }
            }
            &.bs-tooltip-start {
                .tooltip-arrow {
                    &:before {
                        border-left-color: $dark-card-inbox;
                        border-top-color: $transparent-color;
                    }
                }
            }
            &.bs-tooltip-end {
                .tooltip-arrow {
                    &:before {
                        border-right-color: $dark-card-inbox;
                        border-top-color: $transparent-color;
                    }
                }
            }
            .tooltip-inner {
                background-color: $dark-card-inbox;
                color: $white;
            }
            .tooltip-arrow {
                &:before {
                    border-top-color: $dark-card-inbox;
                }
            }
        }
        // tooltip css end
        .page-wrapper {
            &.only-body {
                .page-body-wrapper {
                    .page-title {
                        > .row {
                            .col-6 {
                                &:first-child {
                                    h6 {
                                        color: rgba($dark-all-font-color, 0.5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .profile-dropdown li span {
                            color: $white;
                        }
                        .flip-card .flip-card-inner {
                            .back {
                                background-color: $dark-card-background !important;
                                .flip-back-content {
                                    input {
                                        background-color: $dark-body-background;
                                        border: 1px solid $dark-card-border;
                                        color: $dark-all-font-color;
                                    }
                                }
                            }
                            .front {
                                background-color: $dark-body-background !important;
                                .bookmark-dropdown {
                                    li {
                                        .bookmark-content {
                                            .bookmark-icon {
                                                border-color: $dark-card-border;
                                                svg {
                                                    stroke: $dark-all-font-color;
                                                }
                                            }
                                            &:hover {
                                                svg {
                                                    stroke: var(--theme-default);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .bookmark-tabcontent {
            .details-bookmark {
                .bookmark-card.card {
                    .title-bookmark {
                        border-top: unset;
                    }
                }
            }
            .tab-content {
                .card {
                    .d-flex {
                        border-bottom: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .customizer-contain {
            color: $dark-all-font-color;
            background-color: $dark-card-background;
            li {
                &.active {
                    box-shadow: 0 0 11px 5px rgba(29, 30, 38, 1);
                }
            }
            .customizer-header {
                border-color: $dark-card-border;
            }
            .customizer-body {
                .sidebar-type,
                .sidebar-setting {
                    > li {
                        border-color: $dark-card-border;
                    }
                }
                .layout-grid {
                    li {
                        border-color: $dark-card-border;
                        .header {
                            background-color: $dark-body-background !important;
                        }
                        .body {
                            background-color: $dark-card-background !important;
                            ul {
                                background-color: $dark-card-background;
                                li {
                                    background-color: $dark-body-background !important;
                                }
                            }
                        }
                    }
                    &.customizer-mix {
                        .color-layout[data-attr="light-only"],
                        [data-attr="dark-sidebar"] {
                            background-color: $white;
                            .header {
                                background-color: $light !important;
                            }
                            .body {
                                background-color: $light !important;
                                ul {
                                    background-color: $white;
                                    li {
                                        background-color: $light !important;
                                    }
                                }
                            }
                        }
                        .color-layout[data-attr="dark-sidebar"] {
                            .body {
                                ul {
                                    .sidebar {
                                        background-color: $dark-card-background !important;
                                    }
                                }
                            }
                        }
                        .color-layout[data-attr="dark-only"] {
                            border-color: $dark-card-border;
                        }
                    }
                }
            }
        }
        .learning-box {
            .details-main {
                background-color: $dark-card-background;
                p {
                    color: $dark-small-font-color;
                }
            }
        }
        .blog-single {
            .comment-box {
                .comment-social {
                    li {
                        &:nth-child(n + 2) {
                            border-left: 1px solid $dark-card-border;
                        }
                    }
                }
                h4 {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .add-post {
            .needs-validation {
                label {
                    color: $dark-all-font-color !important;
                }
            }
        }
        .blog-list {
            .blog-details {
                h6 {
                    color: $dark-all-font-color !important;
                }
            }
        }

        .blog-box {
            .blog-details {
                .blog-social {
                    li {
                        &:nth-child(n + 2) {
                            border-left: 1px solid $dark-card-border;
                        }
                    }
                }
                hr {
                    background-color: $dark-card-background;
                }
            }
            .blog-details-second {
                h6 {
                    color: inherit;
                }
                .detail-footer {
                    border-top: 1px solid $dark-card-border;
                    ul.sociyal-list {
                        li {
                            &:nth-child(n + 2) {
                                border-left: 1px solid $dark-card-border;
                            }
                        }
                    }
                }
            }
        }
        .page-wrapper {
            .page-header {
                .header-wrapper {
                    .nav-right {
                        .profile-dropdown {
                            li {
                                border-bottom: 1px solid $dark-card-border;
                                &:last-child {
                                    border-bottom: unset;
                                }
                                span {
                                    color: $dark-all-font-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .job-search {
            .job-description {
                border-top: 1px solid $dark-card-border;
                .theme-form {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .login-card {
            .btn-showcase .btn {
                background-color: $dark-card-background !important;
                color: $white;
                border-color: $dark-card-background !important;
            }
            .login-main {
                box-shadow: 0 0 37px rgba($white, 0.05);
                .theme-form {
                    input {
                        background-color: $dark-card-background !important;
                    }
                    .or {
                        &:before {
                            background-color: $dark-card-background;
                        }
                    }
                    .checkbox label::before {
                        background-color: $dark-card-background !important;
                        border-color: $dark-card-background !important;
                    }
                }
            }
        }
        .swal-icon--success:after,
        .swal-icon--success:before {
            background: $dark-card-background;
        }
        .swal-icon--success__hide-corners {
            background-color: $dark-card-background;
        }
        .note-editor.note-frame {
            .note-status-output {
                border-top: 1px solid $dark-card-background;
            }
            .note-statusbar {
                border-top: 1px solid $dark-card-background;
                .note-resizebar {
                    background-color: $dark-card-background;
                }
            }
        }
        .light-font {
            color: rgba(255, 255, 255, 70%);
        }
        .page-link {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
        }
        .b-r-light {
            border-right: 1px solid $dark-card-border !important;
        }
        .history-details {
            .media {
                border-top: 1px solid $dark-card-border;
            }
        }
        .modal-header {
            border-bottom: 1px solid $dark-card-border;
        }
        #right-history {
            background-color: $dark-card-background;
            box-shadow: 0 0 9px $dark-body-background;
            h6 {
                span {
                    a {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .toggle-sidebar {
            svg {
                stroke: var(--theme-default);
            }
        }
        .page-wrapper {
            .page-body-wrapper {
                background-color: $dark-body-background;
                .page-title {
                    background-color: transparent;
                }
            }
            .sidebar-main-title {
                p {
                    color: $dark-all-font-color;
                }
            }
            &.compact-wrapper {
                .page-body-wrapper {
                    div.sidebar-wrapper {
                        background-color: $dark-card-background;
                        .sidebar-main {
                            .sidebar-links {
                                .sidebar-link.active {
                                    svg {
                                        stroke: var(--theme-default);
                                    }
                                }
                                li {
                                    a {
                                        span {
                                            color: $dark-all-font-color;
                                        }
                                        svg {
                                            stroke: $dark-all-font-color;
                                        }
                                    }
                                }
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    .sidebar-link {
                                                        &.active {
                                                            background-color: $dark-body-background;
                                                            svg {
                                                                color: var(--theme-default);
                                                                stroke: var(--theme-default);
                                                            }
                                                        }
                                                    }
                                                    .mega-menu-container {
                                                        .mega-box {
                                                            .link-section {
                                                                .submenu-title {
                                                                    h5 {
                                                                        color: $dark-all-font-color;
                                                                    }
                                                                }
                                                                .submenu-content {
                                                                    &.opensubmegamenu {
                                                                        li {
                                                                            a {
                                                                                color: $dark-all-font-color;
                                                                                font-weight: 400;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &::after {
                                                            background-color: $light-all-font-color;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .sidebar-list {
                                    &:hover {
                                        > a {
                                            &:hover {
                                                background-color: $dark-body-background !important;
                                            }
                                        }
                                    }
                                    ul.sidebar-submenu {
                                        li {
                                            a {
                                                span {
                                                    color: $dark-all-font-color;
                                                }
                                                &.active {
                                                    color: var(--theme-default);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .according-menu {
                        i {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            &.horizontal-wrapper {
                .page-body-wrapper {
                    .sidebar-wrapper {
                        background: $dark-card-background;
                        border-top: 1px solid $dark-card-border;
                        .sidebar-main {
                            .sidebar-links {
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    > a {
                                                        color: $dark-all-font-color;
                                                    }
                                                    .sidebar-submenu {
                                                        background: $dark-card-background;
                                                        li {
                                                            a {
                                                                color: $dark-all-font-color;
                                                                &.active,
                                                                &:hover {
                                                                    color: var(--theme-default);
                                                                }
                                                            }
                                                            .nav-sub-childmenu {
                                                                background: $dark-card-background;
                                                            }
                                                        }
                                                    }
                                                    .mega-menu-container {
                                                        background: $dark-card-background;
                                                        .mega-box {
                                                            .link-section {
                                                                .submenu-content {
                                                                    li {
                                                                        a {
                                                                            color: $dark-all-font-color;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .left-arrow,
                            .right-arrow {
                                background-color: $dark-card-background;
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
        }
        ::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }
        .customers {
            ul {
                li {
                    img {
                        border: 2px solid $dark-card-border;
                    }
                }
            }
        }
        .kanban-board-header {
            background-color: $dark-body-background !important;
        }
        .kanban-item {
            .kanban-box {
                h6 {
                    color: $dark-all-font-color;
                }
            }
        }
        .kanban-board .kanban-drag {
            background: $dark-body-background !important;
        }
        .kanban-container {
            .kanban-item {
                background-color: $dark-body-background;
                .kanban-box {
                    border: 1px solid rgba($primary-color, 0.15);
                    background-color: $dark-body-background;
                }
            }
        }
        .project-box {
            border: 1px solid rgba($primary-color, 0.15);
            background-color: $dark-body-background;
        }
        .file-sidebar {
            .pricing-plan {
                h5 {
                    color: $dark-all-font-color;
                }
            }
        }
        .react-kanban-column {
            background-color: $dark-body-background;
            .react-kanban-card {
                background-color: $dark-card-background;
                .react-kanban-card__title {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .kanban-board-header {
            background-color: $dark-body-background !important;
            border-bottom: 1px solid $dark-card-background !important;
        }
        .kanban-container {
            .kanban-item {
                .kanban-box {
                    border: 1px solid rgba($primary-color, 0.15);
                    background-color: $dark-body-background;
                }
            }
        }
        .jkanban-container {
            .kanban-container {
                .kanban-item {
                    .kanban-box {
                        background-color: $dark-card-background !important;
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .jkanban-container {
            .kanban-container {
                .kanban-drag {
                    > div {
                        > div {
                            .react-kanban-column {
                                background-color: $dark-body-background;
                            }
                        }
                    }
                }
            }
        }
        .react-kanban-card-adder-button {
            border: 1px solid $dark-card-border;
            color: $dark-all-font-color;
            &:hover {
                background-color: $dark-card-background;
            }
        }
        .file-manager {
            .files,
            .folder {
                h6 {
                    color: $dark-all-font-color;
                }
            }
        }
        .file-content {
            .form-inline {
                border: 1px solid $dark-body-background;
                input.form-control-plaintext {
                    border: unset;
                }
            }
            .files {
                .file-box {
                    border: 1px solid rgba($primary-color, 0.15);
                    background-color: $dark-body-background;
                    .file-top {
                        background-color: $dark-card-background;
                        border: 1px solid rgba($primary-color, 0.15);
                    }
                }
            }
            .folder .folder-box {
                border: 1px solid rgba($primary-color, 0.15);
                background-color: $dark-body-background;
            }
        }
        .file-sidebar {
            h6 {
                color: $dark-gray;
            }
            .pricing-plan {
                border: 1px solid rgba($primary-color, 0.15) !important;
            }
            .btn-light {
                color: $dark-all-font-color;
                background-color: $dark-body-background !important;
                border: 1px solid rgba($primary-color, 0.15) !important;
            }
        }
        // index page css end
        .blockquote {
            border-left: 4px solid $dark-card-border;
        }
        .figure {
            &.text-end {
                blockquote {
                    border-right-color: $dark-card-border;
                }
            }
        }
        .contacts-tabs {
            .nav-pills {
                border-left: 1px solid $dark-card-border !important;
                border-right: 1px solid $dark-card-border;
                .nav-link {
                    + .nav-link {
                        border-top: 1px solid $dark-card-border;
                    }
                }
            }
        }
        .list-persons {
            .profile-mail {
                .media {
                    .media-body {
                        ul {
                            li {
                                + li {
                                    border-left: 2px solid $dark-card-border;
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-wrapper {
            &.sidebaron {
                .product-sidebar {
                    .filter-section {
                        .card {
                            .left-filter {
                                background-color: $dark-card-background;
                                border: 1px solid $dark-card-border;
                                .new-products {
                                    .product-box {
                                        .product-details {
                                            .p {
                                                color: $dark-small-font-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .apexcharts-gridline {
            stroke: $dark-border-color;
        }
        .apexcharts-tooltip-title,
        .apexcharts-tooltip-series-group,
        .apexcharts-tooltip.light,
        .apexcharts-menu-item {
            color: $dark-all-font-color;
        }
        // index page css start
        .appointment-table {
            table {
                tr {
                    td {
                        &.img-content-box {
                            .font-roboto {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
        }
        .notification {
            .card {
                .media {
                    .media-body {
                        p {
                            span {
                                color: $dark-all-font-color;
                            }
                        }
                        span {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        .cal-date-widget {
            .datepicker {
                background-color: $dark-card-inbox;
            }
        }
        // index page css end
        // ecommerce dashboard css start
        .dash-chart {
            .media {
                .media-body {
                    p {
                        color: $dark-all-font-color;
                        .font-roboto {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        .our-product {
            table {
                tr {
                    td {
                        p {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        .cke_contents {
            background-color: $dark-card-background;
        }
        .activity-timeline {
            .media {
                .activity-line {
                    background-color: $light-all-font-color;
                }
                .media-body {
                    p {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .total-users {
            .goal-status {
                ul {
                    &:last-child {
                        li {
                            border-top-color: $dark-card-border;
                        }
                    }
                }
            }
        }
        .best-seller-table {
            table {
                tbody {
                    tr {
                        td {
                            .label {
                                color: $dark-all-font-color;
                            }

                            p {
                                color: $light-all-font-color;
                            }
                        }
                    }
                }
            }
        }
        [class*="activity-dot-"] {
            background-color: $dark-card-background;
        }
        // ecommerce dashboard css end
        $alert-name: primary, secondary, success, danger, warning, info, light, dark;
        $alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
            $light-color, $dark-color;
        @each $var in $alert-name {
            $i: index($alert-name, $var);
            .alert-#{$var} {
                background-color: rgba(nth($alert-color, $i), 0.8) !important;
                border-color: rgba(nth($alert-color, $i), 0.9) !important;
                color: $white;
                .progress {
                    height: 5px;
                    background-color: darken(nth($alert-color, $i), 1%);
                    border-radius: 0;
                }
                .progress-bar {
                    background-color: lighten(nth($alert-color, $i), 50%);
                }
            }
            .alert-#{$var}.inverse {
                background-color: $transparent-color !important;
                .btn-close {
                    filter: brightness(1) invert(1);
                }
            }
            .alert-#{$var}.outline,
            .alert-#{$var}.outline-2x {
                .btn-close {
                    filter: brightness(1) invert(1);
                }
            }
            .dismiss-text {
                .alert-dismissible {
                    .btn-close {
                        filter: unset;
                        .bg-light {
                            color: $theme-body-font-color !important;
                        }
                    }
                }
            }
        }
        .options {
            > div {
                border: 1px solid $dark-card-border;
            }
        }
        .was-validated {
            .custom-control-input {
                &:invalid {
                    ~ .custom-control-label {
                        &::before {
                            background-color: $dark-body-background;
                        }
                    }
                }
            }
        }
        .pricing-simple {
            box-shadow: 1px 1px 2px 1px $dark-body-background !important;
        }
        .pricing-block {
            box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
        }
        .search-list {
            .nav-item {
                .nav-link {
                    &.active {
                        background-color: rgba($primary-color, 0.1);
                    }
                }
            }
        }
        .search-page {
            .tab-content {
                .card {
                    border: none !important;
                }
            }
            .gallery-with-description {
                a {
                    img.media {
                        background-color: $dark-card-background !important;
                    }
                    > div {
                        border: 1px solid $dark-card-border !important;
                        border-top: 0 !important;
                    }
                }
            }
            .nav-tabs {
                border-bottom: unset;
            }
            ul {
                &.search-info {
                    li {
                        + li {
                            border-left: 1px solid $dark-card-border;
                        }
                    }
                }
            }
        }
        .browse {
            .browse-articles {
                h6 {
                    border-bottom: 1px solid $dark-card-border;
                }
            }
        }
        .job-search {
            .job-description {
                .theme-form {
                    .row {
                        div[class^="col-"] {
                            .input-group {
                                .datepicker-here {
                                    border: 1px solid $dark-body-background;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mega-inline,
        .mega-horizontal,
        .mega-vertical {
            .card {
                box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
            }
            &.plain-style,
            &.border-style,
            &.offer-style {
                .card {
                    box-shadow: none !important;
                }
            }
            &.border-style {
                .card {
                    border: 1px solid $dark-card-border !important;
                }
            }
            &.offer-style {
                .card {
                    border: 1px dashed $dark-card-border !important;
                }
            }
        }
        .setting-dot {
            .setting-bg {
                background-color: var(--theme-secondary);
            }
        }
        .bookmark.pull-right {
            border: none;
        }
        // popover css start
        .popover {
            &.bs-popover-bottom {
                .popover-arrow {
                    &:after {
                        border-bottom-color: $dark-body-background;
                        border-right-color: $transparent-color;
                    }
                }
            }
            &.bs-popover-top {
                .popover-arrow {
                    &:after {
                        border-right-color: $transparent-color;
                        border-top-color: $dark-body-background;
                    }
                }
            }
            &.bs-popover-start {
                .popover-arrow {
                    &:after {
                        border-left-color: $dark-body-background;
                    }
                }
            }
            background-color: $dark-body-background;
            .popover-header {
                background-color: $dark-card-background;
            }
            .popover-arrow {
                &:after {
                    border-right-color: $dark-body-background;
                }
            }
            .popover-body {
                color: rgba(255, 255, 255, 0.6);
            }
            code {
                background-color: unset;
            }
        }
        // popover css end
        // apex chart css start
        .apex-chart-container {
            .apexcharts-legend {
                .apexcharts-legend-series {
                    span {
                        color: $dark-all-font-color !important;
                    }
                }
            }
        }
        .apexcharts-canvas {
            svg {
                .apexcharts-title-text {
                    fill: $white;
                }
                .apexcharts-subtitle-text {
                    fill: $white;
                }
                .apexcharts-yaxis {
                    .apexcharts-yaxis-texts-g {
                        .apexcharts-yaxis-label {
                            fill: $dark-all-font-color;
                        }
                    }
                }
                .apexcharts-xaxis {
                    .apexcharts-xaxis-texts-g {
                        .apexcharts-xaxis-label {
                            fill: $dark-all-font-color;
                        }
                    }
                }
                .apexcharts-legend-series {
                    span {
                        color: $dark-all-font-color !important;
                    }
                }
                .apexcharts-datalabels-group {
                    .apexcharts-datalabel-label {
                        fill: $white;
                    }
                    .apexcharts-datalabel-value {
                        fill: $white;
                    }
                }
            }
        }
        // apex chart css end
        .Typeahead-menu {
            background-color: $dark-body-background;
        }
        .ecommerce-widget {
            border: 1px solid $dark-card-border;
        }
        .bookmark {
            ul {
                li {
                    .search-form {
                        .form-control-search {
                            input {
                                background-color: $dark-body-background;
                                border: 1px solid $dark-card-border;
                                color: $dark-all-font-color;
                            }
                            &:before {
                                background: $dark-card-border;
                            }
                        }
                    }
                }
            }
        }
        // checkout css start
        .checkout {
            .checkout-details {
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
                padding: 40px;
                .animate-chk {
                    .radio_animated {
                        &:after {
                            border: 2px solid $dark-card-background;
                        }
                    }
                }
            }
        }
        // checkout css end
        .order-box {
            .title-box {
                color: $dark-all-font-color;
                border-bottom: 1px solid $dark-card-border;
            }
            .qty {
                li {
                    color: $dark-all-font-color;
                    background-color: $dark-body-background;
                    span {
                        color: $dark-small-font-color;
                    }
                }
                border-bottom: 1px solid $dark-card-border;
            }
            .sub-total {
                li {
                    color: $dark-all-font-color;
                    background-color: $dark-body-background;
                }
            }
            .total {
                li {
                    color: $dark-all-font-color;
                    background-color: $dark-body-background;
                }
            }
        }
        .prooduct-details-box {
            .price {
                span {
                    color: $dark-all-font-color;
                }
            }
        }
        .pricing-block {
            .pricing-list {
                ul {
                    li {
                        h6 {
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                }
            }
        }
        .shopping-checkout-option {
            .checkbox_animated {
                &:after {
                    border: 2px solid $dark-card-background;
                }
            }
        }
        .animate-chk {
            .radio_animated {
                &:after {
                    border: 2px solid $dark-card-border;
                }
            }
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(41, 50, 64, 0.52);
        }
        .product-box {
            .product-img {
                .product-hover {
                    ul {
                        li {
                            .btn {
                                color: $dark-small-font-color;
                            }
                            &:hover {
                                .btn {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
        .radio_animated,
        .checkbox_animated {
            &:after {
                background: $dark-body-background;
                border: 2px solid $dark-body-background;
            }
        }
        .slider-product {
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;
        }
        .square-product-setting {
            .icon-grid {
                background-color: $dark-card-background;
                svg {
                    color: $dark-all-font-color;
                }
            }
        }
        .flot-chart-container {
            .legend {
                .table {
                    tbody {
                        background-color: $dark-card-background;
                        color: $white;
                        .legendLabel {
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
        .google-visualization-tooltip {
            text {
                fill: rgba(0, 0, 0, 0.7) !important;
            }
        }
        .nav-dark,
        .nav-pills.nav-dark {
            .nav-link {
                color: $white;
            }
        }
        .loader-wrapper {
            background-color: $dark-body-background;
            .loader {
                background-color: $dark-body-background !important;
            }
        }
        .page-wrapper {
            .page-header {
                form {
                    background-color: $dark-body-background;
                }
                .header-wrapper {
                    background-color: $dark-card-background;
                    li {
                        i {
                            color: $light-color;
                        }
                    }
                    .nav-right {
                        > ul {
                            > li {
                                svg {
                                    stroke: $dark-small-font-color;
                                    path {
                                        stroke: $dark-small-font-color;
                                    }
                                }
                            }
                        }
                        &.right-header {
                            > ul {
                                > li {
                                    .profile-media {
                                        .media-body {
                                            p {
                                                color: $dark-all-font-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .nav-menus {
                            li {
                                .media {
                                    .media-body {
                                        .txt-dark {
                                            color: $dark-all-font-color !important;
                                        }
                                    }
                                }
                                .onhover-show-div {
                                    background-color: $dark-body-background;
                                    box-shadow: 0 0 14px $dark-body-background;
                                    &.cart-dropdown {
                                        li:not(:last-child) {
                                            border-bottom: 1px solid $dark-card-border !important;
                                        }
                                    }
                                    p {
                                        color: $dark-all-font-color;
                                    }
                                    &:before {
                                        border-bottom: 7px solid $dark-body-background;
                                    }
                                    &:after {
                                        border-bottom: 7px solid $dark-body-background;
                                    }
                                }
                                .notification-dropdown.onhover-show-div {
                                    li {
                                        span {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                    .bg-light {
                                        background-color: #282e38 !important;
                                        color: $dark-all-font-color !important;
                                    }
                                }
                            }
                        }
                        .profile-dropdown {
                            li {
                                a {
                                    color: $dark-all-font-color;
                                    svg {
                                        path,
                                        line,
                                        circle {
                                            color: $dark-all-font-color !important;
                                        }
                                    }
                                    &:hover {
                                        color: var(--theme-default);
                                        svg {
                                            line,
                                            path,
                                            circle {
                                                color: var(--theme-default) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        > ul {
                            > li {
                                .media {
                                    .media-body {
                                        .txt-dark {
                                            color: $dark-all-font-color !important;
                                        }
                                    }
                                }
                                .onhover-show-div {
                                    background-color: $dark-body-background;
                                    box-shadow: 0 0 2px 2px $dark-card-background;
                                    &:before {
                                        border-bottom: 7px solid $dark-body-background;
                                    }
                                    &:after {
                                        border-bottom: 7px solid $dark-body-background;
                                    }
                                }
                                .notification-dropdown.onhover-show-div {
                                    li {
                                        border-color: $dark-card-background;
                                        span {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                }
                            }
                        }
                        .chat-dropdown {
                            li {
                                color: $white;
                                border-color: $dark-card-background;
                            }
                        }
                    }
                }
                .header-logo-wrapper {
                    background-color: $dark-card-background;
                    .logo-wrapper {
                        a {
                            .image-dark {
                                display: none;
                            }
                            .image-light {
                                display: block;
                            }
                        }
                    }
                }
            }
            .page-body-wrapper {
                .page-body {
                    .topper-lists {
                        table {
                            tbody {
                                tr {
                                    td {
                                        p {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header-small {
                        color: $dark-small-font-color;
                    }
                    .statistics {
                        p {
                            color: $dark-small-font-color;
                        }
                    }
                    .feather-main,
                    .professor-table {
                        .media-body,
                        .professor-block {
                            p {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .logs-element {
                        span {
                            + span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .progress-block {
                        .progress-title {
                            span {
                                + span {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                    .ecommerce-icons {
                        div {
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .new-users,
                    .recent-notification {
                        .media {
                            .media-body {
                                p {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                    .progress-media {
                        .media {
                            .media-body {
                                span {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                        .progress-change {
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .topper-lists {
                        table {
                            tbody {
                                tr {
                                    td {
                                        border-top: none !important;
                                        border-bottom: 1px solid $dark-card-border;
                                    }
                                    &:last-child {
                                        td {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .notifiaction-media {
                        .media {
                            .media-body {
                                .circle-left {
                                    border: 5px solid #334154;
                                }
                            }
                        }
                    }
                    .upcoming-event {
                        .upcoming-innner {
                            border-bottom: 1px solid $dark-card-border;
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                    .professor-table {
                        table {
                            tbody {
                                tr {
                                    td {
                                        border-top: none !important;
                                    }
                                }
                            }
                        }
                    }
                    .number-widgets {
                        .media {
                            .media-body {
                                h6 {
                                    color: $dark-all-font-color;
                                }
                            }
                        }
                    }
                    .card.custom-card {
                        .card-header {
                            border-bottom: none !important;
                        }
                    }
                }
                .custom-card .card-header img {
                    background-color: $black;
                    opacity: 0.8;
                }
                .page-title {
                    .row {
                        h3 {
                            small {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .breadcrumb {
                        li {
                            color: var(--theme-default);
                        }
                        .breadcrumb-item {
                            & + .breadcrumb-item {
                                &:before {
                                    color: $dark-all-font-color;
                                    font-weight: 500;
                                }
                            }
                            a {
                                svg {
                                    color: var(--theme-default);
                                }
                            }
                        }
                    }
                    .breadcrumb-item {
                        &.active {
                            color: var(--theme-default);
                        }
                    }
                }
                .page-body {
                    background-color: $dark-body-background;
                    .default-according {
                        .card:not(.email-body) {
                            border: none;
                        }
                    }
                    .card:not(.email-body) {
                        background-color: $dark-card-background;
                        box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
                        border: 1px solid $dark-card-border;
                        .chart-block {
                            #bar-chart2 {
                                svg {
                                    > rect {
                                        fill: $dark-card-background;
                                    }
                                    > g {
                                        text {
                                            fill: $dark-all-font-color;
                                        }
                                    }
                                }
                            }
                            .word-tree {
                                svg {
                                    > g {
                                        > rect {
                                            fill: $dark-card-background;
                                        }
                                        > text {
                                            fill: $dark-all-font-color;
                                        }
                                    }
                                }
                            }
                        }
                        .card-header {
                            background-color: $dark-card-background;
                            > span {
                                color: $dark-all-font-color;
                            }
                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6 {
                                color: $dark-all-font-color;
                            }
                            .card-header-right {
                                background-color: $dark-card-background;
                                i {
                                    color: $dark-all-font-color;
                                    &.fa-cog {
                                        color: var(--theme-default);
                                    }
                                }
                            }
                        }
                        .alert-dark {
                            color: $dark-small-font-color;
                            a {
                                color: $dark-small-font-color;
                            }
                        }
                        .alert-light.outline,
                        .alert-light.outline-2x,
                        .alert-light.inverse {
                            color: $dark-all-font-color;
                        }
                        .grid-item {
                            img {
                                background-color: $dark-card-background;
                                border: 1px solid $dark-card-border;
                            }
                        }
                        .line {
                            color: $dark-all-font-color;
                        }
                        thead,
                        tbody,
                        tfoot,
                        tr,
                        td,
                        th {
                            border-color: $dark-card-border;
                        }
                        .table {
                            th,
                            td {
                                color: $dark-all-font-color;
                            }
                            thead {
                                th {
                                    border-bottom: 1px solid $dark-card-border;
                                }
                                .border-bottom-primary {
                                    th {
                                        border-bottom: 1px solid var(--theme-default);
                                    }
                                }
                            }
                            .table-primary,
                            .table-secondary,
                            .table-success,
                            .table-info,
                            .table-warning,
                            .table-light,
                            .table-danger {
                                th,
                                td {
                                    color: $primary-color;
                                }
                            }
                            .bg-primary,
                            .bg-secondary,
                            .bg-success,
                            .bg-info,
                            .bg-warning,
                            .bg-danger {
                                color: $white;
                            }
                            .bg-light {
                                color: $black;
                            }
                            .thead-light {
                                th {
                                    color: $black;
                                }
                            }
                            tbody {
                                .border-bottom-primary {
                                    th,
                                    td {
                                        border-bottom: 1px solid var(--theme-default);
                                    }
                                }
                            }
                        }
                        .table[class*="bg-"] {
                            th,
                            td {
                                color: $white;
                            }
                        }
                        .table-striped {
                            tbody {
                                tr {
                                    &:nth-of-type(odd) {
                                        --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
                                        &:hover {
                                            th,
                                            td {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .table-double,
                        .table-dotted,
                        .table-dashed {
                            border-left-color: $dark-card-border;
                            border-right-color: $dark-card-border;
                        }
                        .table-bordered {
                            border-color: $dark-card-border !important;
                            td,
                            th {
                                border-color: $dark-card-border !important;
                            }
                        }
                        .table-inverse {
                            tbody {
                                tr {
                                    &:nth-of-type(odd) {
                                        &:hover {
                                            td {
                                                color: $dark-all-font-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .table-border-vertical {
                            tr,
                            th,
                            td {
                                border-right: 1px solid $dark-card-border;
                            }
                        }
                        .table-styling {
                            thead,
                            tbody {
                                th,
                                td {
                                    color: $white;
                                }
                            }
                        }
                        .card-footer {
                            background-color: $dark-card-background;
                            border-top: 1px solid $dark-card-border;
                        }
                        .switch {
                            .switch-state {
                                background-color: $dark-body-background;
                                &:after {
                                    color: $dark-all-font-color;
                                }
                            }
                            input {
                                &:checked {
                                    + .switch-state {
                                        background-color: var(--theme-default);
                                    }
                                }
                            }
                        }
                        .bg-white {
                            background-color: $white !important;
                        }
                        .b-l-light {
                            border-left: 1px solid $dark-border-color !important;
                        }
                        .ct-grid {
                            stroke: $dark-border-color;
                        }
                        .ct-label {
                            color: $dark-small-font-color;
                        }
                        hr {
                            border-top: 1px solid $dark-card-border;
                        }
                        .text-muted {
                            color: $sidebar-submenu-font-color !important;
                        }
                        .calender-widget {
                            .cal-date {
                                background-color: $dark-card-background;
                                border: 1px solid $dark-card-border;
                            }
                        }
                        .contact-form {
                            .theme-form {
                                border: 1px solid $dark-card-border;
                                .form-icon {
                                    background-color: $dark-card-background;
                                    border: 1px solid $dark-card-border;
                                    color: $dark-all-font-color;
                                }
                            }
                        }
                        .btn-outline-light,
                        .btn-outline-dark,
                        .btn-outline-light-2x {
                            color: $white !important;
                            border: 1px solid $dark-card-border;
                        }
                        .btn-outline-light {
                            &:hover {
                                color: $black !important;
                            }
                        }
                        .border-right {
                            border-right: 1px solid $dark-border-color !important;
                        }
                        .flot-chart-placeholder {
                            .flot-text {
                                color: $dark-all-font-color !important;
                            }
                            svg {
                                text {
                                    fill: $dark-all-font-color;
                                }
                            }
                        }
                        .chart-overflow {
                            &#gantt_chart {
                                svg {
                                    g {
                                        rect {
                                            &:first-child {
                                                fill: $dark-body-background;
                                            }
                                        }
                                    }
                                }
                                rect {
                                    &:nth-child(6) {
                                        fill: $dark-body-background;
                                    }
                                }
                            }
                            &#line-chart,
                            &#column-chart1,
                            &#column-chart2 {
                                rect {
                                    fill: $dark-card-background;
                                }
                            }
                            svg {
                                > rect {
                                    fill: $dark-card-background;
                                }
                                > g {
                                    > g {
                                        > g {
                                            text {
                                                fill: rgb(177, 177, 178);
                                            }
                                        }
                                    }
                                    > text {
                                        fill: $dark-all-font-color;
                                    }
                                }
                            }
                        }
                        .bar-chart-widget {
                            .bottom-content {
                                .num {
                                    color: $dark-all-font-color;
                                    .color-bottom {
                                        color: $dark-all-font-color;
                                    }
                                }
                            }
                        }
                        .b-r-light {
                            border-right: 1px solid $dark-card-border !important;
                        }
                        .chart-container {
                            .live-products,
                            .turnover,
                            .monthly,
                            .uses {
                                .ct-labels {
                                    .ct-label {
                                        color: $white;
                                    }
                                }
                                .ct-grid {
                                    stroke: $white;
                                }
                            }
                            #browser-uses-chart,
                            #website-visiter-chart {
                                svg {
                                    text {
                                        fill: $dark-all-font-color;
                                    }
                                }
                            }
                        }
                        .status-details {
                            h4 {
                                color: $dark-all-font-color;
                                span {
                                    color: $dark-all-font-color;
                                }
                            }
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                        .clipboaard-container {
                            .form-control {
                                background-color: $dark-body-background;
                                color: $dark-small-font-color;
                                border: 1px solid $dark-card-border;
                            }
                        }
                        .img-thumbnail {
                            background-color: $dark-body-background;
                            border: 1px solid $dark-card-border;
                        }
                        .page-link {
                            border: 1px solid $dark-card-border;
                        }
                        .page-item.disabled {
                            .page-link {
                                background-color: $dark-card-background;
                                border-color: $dark-border-color;
                            }
                        }
                        .page-link {
                            color: $dark-all-font-color;
                            background-color: $dark-card-background;
                        }
                        .page-item {
                            &:hover {
                                .page-link {
                                    background-color: $dark-body-background;
                                }
                            }
                        }
                        .page-item.active {
                            .page-link {
                                background-color: $dark-body-background;
                            }
                        }
                        .ecommerce-widget {
                            .icon {
                                color: $dark-body-background;
                            }
                            .total-num {
                                color: $dark-all-font-color;
                                span {
                                    color: $dark-all-font-color;
                                }
                            }
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                        .flot-chart-container-small {
                            background-color: $dark-card-background;
                            border-top: 1px solid $dark-border-color;
                        }
                        .product-table {
                            h6 {
                                color: $dark-small-font-color;
                            }
                        }
                        .border-tab.nav-tabs {
                            border-bottom: 1px solid $dark-border-color;
                            .nav-item {
                                .nav-link {
                                    color: $dark-all-font-color;
                                }
                            }
                            .nav-link {
                                &.active,
                                &:focus,
                                &:hover {
                                    color: var(--theme-default);
                                }
                            }
                        }
                        .scroll-demo {
                            border: 1px solid $dark-card-border;
                        }
                        .search-form {
                            input {
                                border: 1px solid $dark-card-border;
                                color: $dark-all-font-color;
                                background-color: $dark-body-background;
                            }
                            .form-group {
                                &:before {
                                    background: $dark-card-background;
                                }
                            }
                        }
                        .breadcrumb {
                            &.bg-white {
                                background-color: $dark-card-background !important;
                            }
                        }
                        .user-status {
                            table {
                                td,
                                th {
                                    border-top: none !important;
                                }
                            }
                        }
                        #browser-uses-chart,
                        #website-visiter-chart {
                            svg {
                                opacity: 0.5;
                            }
                        }
                    }
                    .gallery-with-description {
                        h4 {
                            color: $dark-all-font-color;
                        }
                        a {
                            > div {
                                border: 1px solid $dark-card-border;
                            }
                        }
                        p {
                            color: $dark-small-font-color;
                        }
                    }
                    .sub-title {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    }
                    .navs-icon {
                        li {
                            a {
                                color: $dark-all-font-color;
                                &:hover {
                                    color: var(--theme-default);
                                }
                            }
                        }
                        .separator {
                            border-bottom: 1px solid $dark-card-border;
                        }
                    }
                    .default-according {
                        li {
                            .text-muted {
                                color: $dark-all-font-color !important;
                            }
                        }
                    }
                    .navs-icon.default-according.style-1 {
                        li {
                            button {
                                &:hover {
                                    color: var(--theme-default) !important;
                                }
                            }
                        }
                    }
                    .nav-list {
                        .nav-list-disc {
                            li {
                                a {
                                    color: $dark-all-font-color;
                                }
                                &:hover {
                                    color: var(--theme-default);
                                    a {
                                        color: var(--theme-default);
                                    }
                                }
                            }
                        }
                    }
                    .navs-dropdown {
                        .onhover-show-div {
                            background-color: $dark-body-background;
                            box-shadow: 0 0 14px 0 $dark-body-background;
                            .navs-icon {
                                li {
                                    p {
                                        color: $white;
                                    }
                                    a {
                                        svg {
                                            path,
                                            line,
                                            polyline,
                                            polygon,
                                            rect {
                                                color: $dark-all-font-color !important;
                                            }
                                        }
                                        &:hover {
                                            svg {
                                                path,
                                                line,
                                                polyline,
                                                polygon,
                                                rect {
                                                    color: var(--theme-default) !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .onhover-dropdown {
                        &:hover {
                            .onhover-show-div {
                                &:before {
                                    border-bottom: 7px solid $dark-body-background;
                                }
                            }
                        }
                    }
                    .default-according {
                        .card {
                            .btn-link {
                                color: $dark-all-font-color;
                                border: none;
                            }
                            .card-body {
                                color: $dark-small-font-color;
                            }
                            .card-body {
                                border: 0;
                            }
                        }
                    }
                    .border {
                        border: 1px solid $dark-card-border !important;
                    }
                    .blog-box {
                        .blog-date {
                            color: $dark-all-font-color;
                        }
                        .blog-details,
                        .blog-details-main {
                            .blog-social {
                                li {
                                    color: $dark-small-font-color;
                                    &:last-child {
                                        border-right: none;
                                        border-left: 1px solid $dark-card-border;
                                    }
                                }
                            }
                            p {
                                color: $dark-all-font-color;
                            }
                            .single-blog-content-top {
                                border-top: 1px solid $dark-card-border;
                                p {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                    .comment-box {
                        .media {
                            h6 {
                                span {
                                    color: $dark-small-font-color;
                                }
                            }
                            img {
                                border: 1px solid $dark-card-border;
                            }
                        }
                        p {
                            color: $dark-small-font-color;
                        }
                        .comment-social {
                            li {
                                color: $dark-small-font-color;
                                &:first-child {
                                    border-right: 1px solid $dark-card-border;
                                }
                            }
                        }
                        hr {
                            border-top: 1px solid $dark-card-border;
                        }
                    }
                    .table-hover {
                        > tbody {
                            > tr {
                                &:hover {
                                    --bs-table-accent-bg: #1d1e26;
                                    td,
                                    th {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    ul.the-icons {
                        li {
                            border: 1px dotted $dark-card-border;
                            color: $sidebar-submenu-font-color;
                            display: inline-block;
                            padding: 10px;
                            &:hover {
                                background: $black;
                                box-shadow: 0 0 3px $dark-card-background;
                            }
                            em {
                                display: none;
                            }
                        }
                    }
                    .crm-activity {
                        > li {
                            + li {
                                border-top: 1px solid $dark-card-border;
                            }
                            h6 {
                                color: $dark-all-font-color;
                            }
                        }
                        ul.dates {
                            li {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .custom-card {
                        .card-profile {
                            img {
                                background-color: $dark-card-background;
                            }
                        }
                        .profile-details {
                            h6 {
                                color: $dark-small-font-color;
                            }
                        }
                        .card-footer {
                            > div {
                                h6 {
                                    color: $dark-small-font-color;
                                }
                                + div {
                                    border-left: 1px solid $dark-card-border;
                                }
                            }
                        }
                    }
                    .form-control {
                        background-color: $dark-card-background;
                        color: $dark-all-font-color;
                        border-color: $dark-card-border;
                    }
                    .form-control.b-light {
                        background-color: $dark-card-background;
                    }
                    .checkbox,
                    .radio {
                        label {
                            &::before {
                                background-color: $dark-body-background;
                                border: 1px solid $dark-card-border;
                                color: $dark-all-font-color;
                            }
                        }
                    }
                    .dropdown-basic {
                        .dropdown {
                            .dropdown-content {
                                background-color: $dark-body-background;
                                a {
                                    color: $dark-all-font-color;
                                    background-color: $transparent-color;
                                    border-color: $dark-card-background;
                                    &:hover {
                                        background-color: $dark-card-background;
                                    }
                                }
                                .dropdown-header {
                                    color: $dark-all-font-color;
                                }
                                .dropdown-divider {
                                    border: 1px solid $dark-card-border;
                                }
                            }
                        }
                    }
                    .dropup-basic {
                        .dropup {
                            .dropup-content {
                                background-color: $dark-body-background;
                                a {
                                    color: $dark-all-font-color;
                                    &:hover,
                                    &.active {
                                        background-color: $dark-card-background;
                                    }
                                }
                            }
                        }
                    }
                    .dropzone.dz-clickable {
                        .dz-message {
                            h6 {
                                color: $dark-all-font-color;
                            }
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .dropzone {
                        .dz-preview {
                            background-color: $dark-body-background;
                            .dz-details {
                                background-color: $dark-card-background;
                            }
                        }
                    }
                    .browser-widget {
                        .media-body {
                            column-rule: 1px solid $dark-card-border;
                            h4 {
                                color: $dark-all-font-color;
                                span {
                                    color: $dark-all-font-color;
                                }
                            }
                            span {
                                color: $dark-small-font-color;
                            }
                        }
                    }
                    .email-wrap {
                        .inbox {
                            .media:hover {
                                background-color: unset;
                            }
                            .media-body {
                                h6 {
                                    color: $primary-color;
                                }
                            }
                        }
                        .email-wrapper {
                            .emailread-group {
                                &:nth-child(n + 2) {
                                    border-top: 1px solid $dark-body-background;
                                }
                            }
                        }
                        .email-app-sidebar {
                            .media-body {
                                h6 {
                                    color: $primary-color;
                                }
                            }
                            .media {
                                img {
                                    border: 2px solid $dark-body-background;
                                }
                            }
                            .main-menu {
                                > li {
                                    a {
                                        color: $dark-all-font-color;
                                        &:hover {
                                            background-color: $dark-body-background;
                                            span {
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
                            }
                            ul {
                                li {
                                    a {
                                        > .title {
                                            color: $dark-all-font-color;
                                        }
                                    }
                                }
                            }
                        }
                        .email-right-aside {
                            .email-body {
                                .row {
                                    .col-xl-4 {
                                        border-right: 1px solid rgba(0, 0, 0, 0.1);
                                    }
                                }
                                .inbox {
                                    .media.active {
                                        background-color: $dark-body-background;
                                    }
                                }
                            }
                        }
                        .media-body {
                            h6 {
                                small {
                                    color: $dark-small-font-color;
                                    span {
                                        color: $dark-small-font-color;
                                    }
                                }
                            }
                            p {
                                color: $dark-small-font-color;
                            }
                        }
                        .email-top {
                            border-bottom: 1px solid $dark-card-border;
                        }
                        p {
                            color: $dark-small-font-color;
                        }
                        .email-content {
                            .email-top {
                                .user-emailid:after {
                                    border: 1px solid $dark-card-border;
                                }
                            }
                        }
                    }
                    .dropdown-menu {
                        background-color: $dark-body-background;
                        border: 1px solid $dark-card-background;
                        a {
                            color: $dark-all-font-color;
                            background: $transparent-color;
                            border-top-color: $dark-card-background;
                            &:hover {
                                background-color: $dark-card-background;
                            }
                        }
                    }
                    .icon-lists {
                        div {
                            i {
                                color: $dark-small-font-color;
                            }
                            &:hover {
                                background-color: $dark-body-background;
                            }
                        }
                    }
                    .widget-joins {
                        .media {
                            .media-body {
                                > span {
                                    color: $dark-small-font-color;
                                }
                            }
                            .details {
                                border-left: 1px solid $dark-card-border;
                            }
                        }
                        &:before,
                        &:after {
                            background-color: $dark-card-border;
                        }
                    }
                    .social-widget-card {
                        h6 {
                            color: $dark-small-font-color;
                        }
                        .media {
                            .social-font {
                                background-color: $dark-body-background;
                            }
                        }
                    }
                    .b-b-light {
                        border-bottom: 1px solid $dark-card-border !important;
                    }
                    .b-r-dark {
                        border-right: 1px solid $white !important;
                    }
                    .testimonial {
                        i {
                            color: $dark-card-border;
                        }
                        p {
                            color: $dark-all-font-color;
                        }
                        h5 {
                            color: $dark-all-font-color;
                        }
                        span {
                            color: $dark-small-font-color;
                        }
                    }
                    .grid-showcase {
                        span {
                            background-color: $dark-card-background;
                            border: 1px solid $dark-card-border;
                        }
                    }
                    .grid-align {
                        .row {
                            background-color: $dark-card-border;
                            border: 1px solid $dark-card-border;
                        }
                    }
                    .search-page {
                        .info-block + .info-block {
                            border-top: 1px solid $dark-card-border;
                        }
                    }
                    .card-absolute {
                        .bg-primary,
                        .bg-secondary {
                            h5 {
                                color: $white;
                            }
                        }
                    }
                }
                .footer {
                    p {
                        color: $dark-all-font-color;
                    }
                    border-top: 1px solid $dark-body-background;
                    background-color: $dark-card-background;
                }
                .custom-select,
                .custom-file-label {
                    background: $dark-body-background;
                    color: $dark-all-font-color;
                }
                .footer-fix {
                    background-color: $dark-card-background;
                    border-top: 1px solid $dark-card-border;
                }
            }
            .note {
                textarea {
                    color: $dark-all-font-color;
                }
            }
            .chat-box {
                .about {
                    .name {
                        color: $dark-all-font-color;
                        a {
                            color: $dark-all-font-color;
                        }
                    }
                }
                .chat-menu {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    .nav-tabs {
                        border-bottom: 1px solid $dark-card-border;
                        .nav-item {
                            .nav-link {
                                &.active {
                                    color: $dark-all-font-color !important;
                                    background-color: $dark-body-background;
                                }
                            }
                        }
                    }
                    .user-profile {
                        .image {
                            .icon-wrapper {
                                background-color: $dark-card-background;
                                box-shadow: 1px 1px 3px 1px $dark-body-background;
                            }
                            .avatar {
                                img {
                                    border: 5px solid $dark-card-border;
                                }
                            }
                        }
                        .social-media {
                            a {
                                color: $dark-small-font-color;
                            }
                        }
                        .digits {
                            border-top: 1px solid $dark-card-border;
                        }
                        .follow {
                            border-top: 1px solid $dark-card-border;
                            span {
                                color: $dark-small-font-color;
                            }
                            .follow-num {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
                .status {
                    color: $dark-small-font-color;
                    p {
                        color: $dark-all-font-color !important;
                    }
                }
                .chat-right-aside {
                    .chat {
                        .chat-header {
                            border-bottom: 1px solid $dark-card-border;
                            .chat-menu-icons {
                                li {
                                    a {
                                        i {
                                            color: $dark-small-font-color;
                                        }
                                    }
                                }
                            }
                            img {
                                box-shadow: 1px 1px 4px 1px $dark-body-background;
                            }
                        }
                        .chat-msg-box {
                            .my-message {
                                border: 1px solid $dark-card-border;
                            }
                            .message {
                                color: $dark-all-font-color;
                            }
                            .other-message {
                                background-color: $dark-body-background;
                                border: 1px solid $dark-body-background;
                            }
                        }
                        .chat-message {
                            background-color: $dark-card-background;
                            border-top: 1px solid $dark-card-border;
                            .input-group {
                                .form-control {
                                    background-color: $dark-card-background;
                                }
                            }
                        }
                    }
                }
                .chat-history {
                    .call-icons {
                        ul {
                            li {
                                border: 1px solid $dark-card-border;
                                a {
                                    color: $dark-small-font-color;
                                }
                            }
                        }
                    }
                }
            }
            .social-status {
                form {
                    .form-group {
                        .form-control-social {
                            background-color: $dark-body-background;
                            border: unset;
                        }
                    }
                }
            }
            .social-app-profile {
                .card {
                    .socialprofile {
                        span {
                            .badge {
                                background-color: $dark-body-background;
                            }
                        }
                    }
                }
            }
            pre {
                background-color: $dark-card-border;
                color: $dark-all-font-color;
            }
            .scorlled {
                background-color: $dark-body-background;
            }
            .input-group-air {
                box-shadow: 0 3px 20px 0 $dark-card-border;
            }
            .input-group-text {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
                border-right: none;
                color: rgba(255, 255, 255, 0.6);
                i {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            .input-group-solid {
                .input-group-text,
                .form-control {
                    background: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    color: $dark-all-font-color;
                }
            }
            .semilight-bg-color,
            .header-bg-color {
                background-color: $white;
            }
            .masonry-with-dec {
                .list-group-item {
                    background-color: $dark-body-background;
                }
            }
            .list-group-item {
                color: $dark-all-font-color;
                + .list-group-item {
                    border-top-width: 0;
                }
                &.active {
                    background-color: var(--theme-default) !important;
                    border-color: var(--theme-default);
                    color: $white;
                }
                &:hover,
                &:focus {
                    z-index: 1;
                    text-decoration: none;
                }
            }
            .list-group-item-action {
                &:hover:not(.active),
                &:focus {
                    background-color: $dark-body-background;
                }
            }
            .list-group-flush {
                .list-group-item {
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0;
                }
            }
            .auth-bg {
                background-blend-mode: overlay;
                background-color: rgba(54, 64, 74, 0.9);
                .authentication-box {
                    .card {
                        background-color: $dark-card-background;
                        .theme-form {
                            .form-group {
                                input {
                                    color: $dark-all-font-color;
                                    background-color: $dark-body-background;
                                    border: 1px solid $dark-border-color;
                                }
                                label {
                                    color: $dark-all-font-color;
                                }
                            }
                            .checkbox {
                                label {
                                    color: $dark-all-font-color;
                                    &::before {
                                        background-color: $dark-body-background;
                                        border: 1px solid $dark-border-color;
                                    }
                                }
                            }
                        }
                    }
                    h3,
                    h4,
                    h6 {
                        color: $white;
                    }
                }
            }
            .auth-bg-video {
                background-blend-mode: overlay;
                background-color: rgba(54, 64, 74, 0.7);
                .authentication-box {
                    h4,
                    h3 {
                        color: $white;
                    }
                    h6 {
                        color: $dark-small-font-color;
                    }
                    .card {
                        background-color: $dark-card-background;
                        .theme-form {
                            .form-group {
                                input[type="text"],
                                input[type="password"] {
                                    background-color: $dark-body-background;
                                    border: 1px solid $dark-border-color;
                                    color: $dark-all-font-color;
                                }
                            }
                            .checkbox {
                                label {
                                    &::before {
                                        background-color: $dark-body-background;
                                        border: 1px solid $dark-border-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .error-wrapper {
                .maintenance-icons {
                    li {
                        i {
                            color: $dark-border-color;
                        }
                        &:nth-child(3) {
                            i {
                                color: $dark-border-color;
                            }
                        }
                    }
                }
            }
            .modal-content {
                background-color: $dark-card-background;
                .modal-header {
                    border-bottom: 1px solid $dark-border-color;
                    .btn-close {
                        color: $dark-small-font-color;
                    }
                }
                .modal-footer {
                    border-top: 1px solid $dark-border-color;
                }
            }
            .comingsoon {
                background-blend-mode: overlay;
                background-color: rgba(54, 64, 74, 0.9);
                .comingsoon-inner {
                    h5 {
                        color: $dark-all-font-color;
                    }
                    .countdown {
                        border-top: 1px solid $dark-border-color;
                        border-bottom: 1px solid $dark-border-color;
                        .title {
                            color: $dark-all-font-color;
                        }
                    }
                    .coming-soon-bottom-link {
                        a {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            .authentication-main {
                background-color: $dark-border-color;
                .auth-innerright {
                    .reset-password-box {
                        .card {
                            background-color: $dark-card-background;
                        }
                        .reset-password-link {
                            color: $dark-small-font-color;
                        }
                        .theme-form {
                            .form-group {
                                label {
                                    color: $dark-small-font-color;
                                }
                                .form-control {
                                    background-color: $dark-body-background;
                                    color: $dark-all-font-color;
                                    border-color: $dark-border-color;
                                }
                            }
                            .opt-box {
                                background-color: $dark-border-color;
                            }
                        }
                    }
                    .authentication-box {
                        h4 {
                            color: $dark-all-font-color;
                        }
                        h6 {
                            color: $dark-small-font-color;
                        }
                        h3 {
                            color: $dark-all-font-color;
                        }
                        .card {
                            background-color: $dark-card-background;
                            .theme-form {
                                .form-group {
                                    input[type="text"],
                                    input[type="password"] {
                                        background-color: $dark-body-background;
                                        border: 1px solid $dark-border-color;
                                        color: $dark-all-font-color;
                                    }
                                }
                                .checkbox {
                                    label {
                                        &::before {
                                            background-color: $dark-body-background;
                                            border: 1px solid $dark-border-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mega-menu {
                .title {
                    color: $dark-all-font-color;
                    border-bottom: 1px solid $dark-border-color;
                }
            }
            .default-according {
                .card {
                    background-color: $dark-card-background;
                    .btn-link {
                        background-color: $dark-card-background;
                        border: 1px solid $dark-card-border;
                        border-bottom: none;
                        color: $white;
                    }
                    .text-muted {
                        color: $dark-small-font-color !important;
                    }
                }
                .bg-primary {
                    .btn-link {
                        background-color: var(--theme-default);
                        border: 1px solid var(--theme-default);
                    }
                }
                .bg-secondary {
                    .btn-link {
                        background-color: var(--theme-secondary);
                        border: 1px solid var(--theme-secondary);
                    }
                }
            }
            .collapse {
                .card-body {
                    background-color: $dark-card-background;
                }
            }
            @media screen and (max-width: 1660px) {
                .chat-box {
                    .chat-history {
                        .call-icons {
                            ul {
                                li {
                                    border: 1px solid $dark-border-color;
                                }
                            }
                        }
                        .total-time {
                            h2 {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
                .caller-img {
                    img {
                        opacity: 0.7;
                    }
                }
                .chat-box {
                    .chat-history {
                        .call-content {
                            > div {
                                background-color: rgba(0, 0, 0, 0.75);
                                background-blend-mode: overlay;
                            }
                        }
                    }
                }
            }
            @media only screen and (max-width: 1199px) {
                .job-sidebar .job-left-aside {
                    background-color: $dark-card-background;
                }
                .mobile-title {
                    &.d-none {
                        border-bottom: 1px solid $dark-card-border;
                    }
                }
                .chat-menu {
                    border-top: 1px solid $dark-border-color;
                    background-color: $dark-card-background;
                }
                .md-sidebar.job-sidebar {
                    .md-sidebar-aside.job-left-aside {
                        background-color: $dark-body-background;
                        border: 1px solid $dark-card-border;
                    }
                }
                .error-wrapper {
                    .maintenance-icons {
                        li {
                            i {
                                color: $dark-color;
                            }
                        }
                    }
                }
            }
            @media only screen and (max-width: 575.98px) {
                .user-profile {
                    .hovercard {
                        .info {
                            .user-designation {
                                border-top: 1px solid $dark-border-color;
                                border-bottom: 1px solid $dark-border-color;
                            }
                        }
                    }
                }
                .widget-joins {
                    .border-after-xs {
                        &:after {
                            background-color: $dark-border-color;
                        }
                    }
                }
                .page-wrapper {
                    &.compact-wrapper {
                        .page-header {
                            .header-wrapper {
                                .toggle-sidebar {
                                    border-right: 1px solid $dark-card-border;
                                }
                            }
                        }
                    }
                }
            }
        }
        .select2-dropdown {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
        }
        .theme-form {
            .form-group {
                select.form-control:not([size]):not([multiple]) {
                    background-color: $dark-body-background;
                    color: $dark-all-font-color;
                    border-color: $dark-border-color;
                }
                input[type="text"],
                input[type="email"],
                input[type="search"],
                input[type="password"],
                input[type="number"],
                input[type="tel"],
                input[type="date"],
                input[type="datetime-local"],
                input[type="time"],
                input[type="datetime-local"],
                input[type="month"],
                input[type="week"],
                input[type="url"],
                input[type="file"],
                select {
                    border-color: $dark-border-color;
                    background-color: $dark-body-background;
                    color: $dark-all-font-color;
                    &::-webkit-input-placeholder {
                        color: $dark-small-font-color;
                    }
                }
                .form-control {
                    background-color: $dark-body-background;
                    color: $dark-all-font-color;
                }
                textarea {
                    border-color: $dark-border-color;
                }
            }
        }
        .CodeMirror {
            background: $dark-card-background;
            border: 1px solid $dark-card-border;
            color: $dark-all-font-color;
            .CodeMirror-code {
                pre {
                    background: $dark-card-background;
                }
            }
        }
        .editor-toolbar {
            border-top: 1px solid $dark-card-border;
            border-left: 1px solid $dark-card-border;
            border-right: 1px solid $dark-card-border;
            button,
            button:hover {
                &.active,
                &:hover {
                    background: $dark-body-background;
                    border-color: transparent;
                }
            }
            a,
            i {
                color: $dark-all-font-color !important;
                &:hover,
                &.active {
                    background: $dark-border-color;
                }
            }
            i.separator {
                border-left: 1px solid $dark-small-font-color;
                border-right: 1px solid $dark-small-font-color;
            }
            &.fullscreen {
                &::before {
                    background: linear-gradient(to right, $dark-body-background 0, rgba(0, 0, 0, 0) 100%);
                }
                &::after {
                    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, $dark-body-background 100%);
                }
            }
        }
        .editor-toolbar.disabled-for-preview {
            a {
                &:not(.no-disable) {
                    background: $dark-border-color;
                }
            }
        }
        .editor-preview {
            background-color: $dark-card-background;
        }
        .editor-toolbar.fullscreen,
        .editor-preview-side {
            background: $dark-card-background;
            border: 1px solid $dark-small-font-color;
        }
        .u-step {
            background: $dark-border-color;
            &.active,
            &.current {
                background: var(--theme-default);
                color: $white;
            }
        }
        .u-step-title,
        .u-pearl-title {
            color: $dark-all-font-color;
        }
        .u-step-number {
            background-color: $dark-card-background;
        }
        .u-pearl {
            &:before {
                background-color: $dark-border-color;
            }
        }
        .u-pearl-number,
        .u-pearl-icon {
            background: $dark-body-background;
            border: 2px solid $dark-body-background;
        }
        .u-pearl.disabled {
            .u-pearl-icon,
            .u-pearl-number {
                background: $dark-border-color;
                border: 2px solid $dark-border-color;
            }
            &:after {
                background-color: #334053;
            }
        }
        .u-pearl.error {
            &:after {
                background-color: $dark-body-background;
            }
        }
        .note-editor.note-frame {
            border-color: $dark-border-color;
            .note-editing-area {
                .note-editable {
                    background-color: $dark-card-background;
                    color: $dark-all-font-color;
                }
            }
        }
        .swal-modal {
            background-color: $dark-card-background;
            .swal-title {
                color: $dark-all-font-color;
            }
            .swal-text {
                color: $dark-small-font-color;
            }
            .swal-content__input {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
            }
        }
        .nav-tabs {
            border-bottom: 1px solid $dark-card-border;
            .nav-link {
                color: $dark-all-font-color;
                &.active {
                    background-color: $dark-card-background;
                    color: $dark-all-font-color;
                    border-color: $dark-card-border $dark-card-border $dark-card-background;
                }
                &:hover,
                &:focus {
                    border-color: $dark-card-border $dark-card-border $dark-card-inbox;
                }
            }
            .nav-item.show {
                .nav-link {
                    background-color: $dark-card-background;
                    color: $dark-all-font-color;
                    border-color: $dark-border-color $dark-border-color $dark-card-background;
                }
            }
        }
        .nav-tabs.nav-bottom {
            .nav-item {
                .nav-link {
                    &.active,
                    &.focus,
                    &.hover {
                        border-color: $dark-card-background $dark-border-color $dark-border-color;
                    }
                }
            }
        }
        .border-tab.nav-left,
        .border-tab.nav-right {
            .nav-link {
                color: $dark-all-font-color;
                &.active {
                    color: var(--theme-default);
                }
            }
            .show {
                > .nav-link {
                    color: var(--theme-default);
                }
            }
        }
        .border-tab.nav-left.nav-secondary {
            .nav-link {
                &.active,
                &:focus,
                &:hover {
                    border-left-color: var(--theme-secondary);
                    color: var(--theme-secondary) !important;
                }
            }
            .show > .nav-link {
                border-left-color: var(--theme-secondary);
                color: var(--theme-secondary) !important;
            }
            .nav-item.show {
                color: var(--theme-secondary) !important;
                border-left-color: var(--theme-secondary);
            }
        }
        .border-tab.nav-left.nav-success {
            .nav-link {
                &.active,
                &:focus,
                &:hover {
                    color: $success-color !important;
                }
            }
            .show > .nav-link {
                border-left-color: $success-color;
                color: $success-color !important;
            }
            .nav-item.show {
                color: $success-color !important;
                border-left-color: $success-color;
            }
        }
        .border-tab.nav-right.nav-info {
            .nav-link {
                &.active,
                &:focus,
                &:hover {
                    color: $info-color !important;
                }
            }
            .show > .nav-link {
                border-left-color: $info-color;
                color: $info-color !important;
            }
            .nav-item.show {
                color: $info-color !important;
                border-left-color: $info-color;
            }
        }
        .border-tab.nav-secondary {
            .nav-link {
                &.active,
                &:focus,
                &:hover {
                    color: var(--theme-secondary) !important;
                }
            }
            .nav-item {
                &.show {
                    color: var(--theme-secondary) !important;
                }
            }
        }
        .border-tab.nav-success {
            .nav-link {
                &.active,
                &:focus,
                &:hover {
                    color: $success-color !important;
                }
            }
            .nav-item {
                &.show {
                    color: $success-color !important;
                }
            }
        }
        .dropdown-divider {
            border-top: 1px solid $dark-border-color;
        }
        .icon-hover-bottom {
            background-color: $dark-card-background;
            box-shadow: 0px 0px 1px 1px $dark-border-color;
            .icon-title {
                color: $dark-all-font-color;
            }
            span {
                color: $dark-small-font-color;
            }
            .form-group {
                input {
                    background-color: $dark-body-background;
                    color: $dark-all-font-color;
                    border: 1px solid $dark-border-color;
                }
            }
            .icon-first {
                i {
                    color: $dark-small-font-color;
                }
            }
        }
        code {
            background-color: $dark-body-background;
            border-radius: 2px;
        }
        .todo {
            .todo-list-wrapper {
                #todo-list {
                    li {
                        .task-container {
                            background: $dark-card-background;
                            border-bottom: 1px solid $dark-border-color;
                            .task-label {
                                color: $dark-all-font-color;
                            }
                            &:hover {
                                h4 {
                                    color: $white;
                                }
                            }
                            .task-action-btn {
                                .action-box {
                                    &:hover:not(.active) {
                                        background: $dark-card-background;
                                        border: 1px solid $dark-border-color;
                                    }
                                }
                            }
                        }
                        &.completed {
                            .task-container {
                                .task-label {
                                    color: var(--theme-default);
                                }
                                .complete-btn {
                                    &:hover {
                                        border: 1px solid $success-color !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .todo-list-footer {
                    .new-task-wrapper {
                        textarea {
                            background-color: $dark-card-background;
                            border: 1px solid $dark-border-color;
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        .user-profile {
            .hovercard {
                .info {
                    .title {
                        a {
                            color: $dark-all-font-color;
                        }
                    }
                }
                .user-image {
                    .avatar {
                        img {
                            border: 10px solid $dark-card-background;
                        }
                    }
                    .icon-wrapper {
                        background-color: $dark-card-background;
                    }
                }
                .tabs-scoial {
                    border-bottom: none !important;
                }
            }
            .follow {
                .follow-num {
                    color: $dark-all-font-color;
                }
            }
            .profile-img-style {
                .user-name {
                    color: $dark-all-font-color;
                }
                p {
                    color: $dark-small-font-color;
                }
            }
        }
        span.twitter-typeahead {
            .tt-menu {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
            }
            .tt-suggestion {
                color: $dark-all-font-color;
                background-color: $dark-body-background;
                &:hover,
                &:focus {
                    background-color: $dark-card-background;
                }
            }
        }
        .typeahead {
            span.twitter-typeahead {
                .tt-suggestion {
                    border-top: 1px solid $dark-border-color;
                }
            }
        }
        .typography {
            small {
                color: $dark-all-font-color;
            }
        }
        .note {
            textarea {
                color: $dark-all-font-color;
            }
        }
        pre {
            background-color: $dark-body-background;
        }
        .error-wrapper {
            background-color: rgba(54, 64, 74, 0.8);
            .sub-content {
                color: $dark-all-font-color;
            }
        }
        .b-light {
            border: 1px solid $dark-border-color !important;
        }
        .modal-content {
            background-color: $dark-card-background;
            .modal-header {
                border-bottom: 1px solid $dark-card-border;
                .btn-close {
                    color: $dark-all-font-color;
                    font-weight: 400;
                }
            }
            .ui-front {
                .form-control {
                    background-color: $dark-card-background;
                    color: $dark-all-font-color;
                    border: 1px solid $dark-card-border;
                }
            }
        }
        .modal {
            .theme-close {
                background-color: $dark-card-background !important;
                color: $light-all-font-color;
            }
        }
        .loader-box {
            &.card-loader {
                background-color: $dark-card-background;
            }
        }
        .my-gallery {
            &.gallery-with-description {
                img {
                    border: 1px solid $dark-card-border !important;
                    border-bottom: none !important;
                }
            }
        }
        @each $var in $alert-name {
            $i: index($alert-name, $var);
            .alert-#{$var}.inverse {
                color: $dark-all-font-color;
            }
        }
        .alert-theme {
            span {
                + span {
                    + span {
                        border-left: 5px solid var(--theme-default);
                        background-color: $dark-body-background;
                        border-radius: 4px;
                    }
                }
            }
            i {
                color: $white;
                padding: 20px;
                margin-right: 20px !important;
            }
        }
        .user-card {
            .user-deatils {
                h6 {
                    color: $dark-small-font-color;
                }
            }
            .card-footer {
                > div {
                    &:first-child {
                        border-right: 1px solid $dark-border-color;
                    }
                }
                .user-footer {
                    h6 {
                        color: $dark-small-font-color;
                    }
                    svg {
                        path,
                        rect {
                            color: $dark-small-font-color;
                        }
                    }
                }
            }
        }
        .order-history {
            .product-name {
                h6 {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            table {
                thead {
                    tr {
                        th {
                            background-color: $dark-body-background;
                        }
                    }
                }
                tr {
                    td,
                    th {
                        border-top: none !important;
                    }
                }
            }
            .title-orders {
                background-color: $dark-body-background;
            }
        }
        .navigation-option {
            ul {
                li {
                    a {
                        color: $dark-all-font-color;
                    }
                    &:hover {
                        background-color: $dark-body-background;
                    }
                }
            }
        }
        .product-box {
            .product-details {
                h6 {
                    color: $dark-all-font-color;
                }
                span {
                    color: $dark-small-font-color;
                }
            }
            .product-img {
                .product-hover {
                    ul {
                        li {
                            background-color: $dark-card-background;
                            &:hover {
                                background-color: $black;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .browse {
            .browse-articles {
                ul {
                    li {
                        a {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
        }
        @each $btn-name, $btn-color in (primary, var(--theme-default)), (secondary, $secondary-color),
            (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
            (dark, $dark-color), (warning, $warning-color)
        {
            .radio-#{$btn-name} {
                input[type="radio"] {
                    & + label {
                        &::before {
                            border-color: $btn-color !important;
                        }
                        &::after {
                            background-color: $btn-color;
                        }
                    }
                    &:checked {
                        & + label {
                            &::before {
                                border-color: $btn-color !important;
                            }
                            &::after {
                                background-color: $btn-color;
                            }
                        }
                    }
                }
            }
        }
        @each $btn-name, $btn-color in (primary, var(--theme-default)), (secondary, $secondary-color),
            (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
            (dark, $dark-color), (warning, $warning-color)
        {
            .checkbox-#{$btn-name} {
                label {
                    &::before {
                        border-color: $btn-color !important;
                    }
                }
            }
        }
        @each $btn-name, $btn-color in (primary, var(--theme-default)), (secondary, $secondary-color),
            (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
            (dark, $dark-color), (warning, $warning-color)
        {
            .checkbox-solid-#{$btn-name} {
                label {
                    &:before {
                        background-color: $btn-color !important;
                        border-color: $btn-color !important;
                        color: $white !important;
                    }
                }
                input[type="checkbox"] {
                    &:checked {
                        & + label {
                            &::before {
                                background-color: $btn-color !important;
                                border-color: $btn-color !important;
                                color: $white !important;
                            }
                            &::after {
                                color: $white !important;
                            }
                        }
                    }
                }
            }
        }
        .bootstrap-datetimepicker-widget {
            ul {
                li {
                    color: $dark-all-font-color;
                    border-bottom: 1px solid $dark-border-color;
                }
            }
            tr {
                th,
                td {
                    &:hover {
                        background-color: $dark-card-background;
                    }
                    span {
                        &:hover {
                            background-color: $dark-card-background;
                        }
                    }
                    &.prev,
                    &.next {
                        &:hover {
                            background-color: $dark-card-background;
                        }
                    }
                }
                &:first-child {
                    th {
                        &:hover {
                            background-color: $dark-card-background;
                        }
                    }
                }
            }
        }
        .btn-transparent {
            color: $dark-all-font-color;
        }
        .categories {
            ul {
                li {
                    a {
                        color: $dark-small-font-color;
                    }
                    &:hover {
                        background-color: $dark-body-background;
                    }
                }
            }
        }
        .socialprofile {
            .likes-profile {
                h5 {
                    span {
                        color: $dark-all-font-color;
                    }
                }
            }
            span {
                color: $dark-small-font-color;
            }
        }
        .social-status {
            .media {
                .media-body {
                    span {
                        + span {
                            color: $dark-small-font-color;
                        }
                    }
                    p,
                    .light-span {
                        color: $dark-small-font-color;
                    }
                }
            }
        }
        .filter-cards-view,
        .timeline-content {
            p {
                color: $dark-small-font-color;
            }
            .comment-number {
                i {
                    color: $dark-small-font-color;
                }
            }
            .comments-box {
                .input-group {
                    .form-control {
                        border: 1px solid $dark-card-border;
                    }
                    .input-group-append {
                        background-color: $dark-body-background;
                    }
                }
            }
        }
        .social-app-profile {
            .my-gallery.gallery-with-description {
                figure {
                    .img-thumbnail {
                        background-color: $dark-card-background !important;
                    }
                }
            }
        }
        .socialprofile {
            .social-btngroup {
                .btn-light {
                    color: $black !important;
                }
            }
        }
        .social-chat {
            .media-body {
                border: 1px solid $dark-card-border;
                &:after {
                    border-right: 7px solid $dark-body-background;
                }
            }
            span {
                span {
                    color: $dark-small-font-color;
                }
            }
        }
        .details-about {
            .your-details {
                p {
                    color: $dark-small-font-color;
                }
            }
        }
        .activity-log {
            .my-activity {
                p {
                    color: $dark-small-font-color;
                }
            }
        }
        .bookmark {
            ul {
                li {
                    a {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        @media only screen and (max-width: 991.98px) {
            .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
                border-right: 1px solid $dark-card-border;
            }
        }
        .apexcharts-tooltip.light {
            border-color: $dark-card-border;
            background-color: $dark-body-background;
            .apexcharts-tooltip-title {
                border-color: $dark-card-border;
            }
            .apexcharts-tooltip-text-label,
            .apexcharts-tooltip-text-value {
                color: $white;
            }
        }
        .note-editor {
            .note-toolbar-wrapper {
                .note-toolbar.card-header {
                    .note-btn-group {
                        button {
                            background-color: $dark-body-background !important;
                            border-color: $dark-card-border !important;
                        }
                    }
                }
            }
        }
        .blog-box {
            .blog-details-main {
                h6.blog-bottom-details {
                    a {
                        color: $dark-all-font-color;
                    }
                }
            }
            h5 {
                a {
                    color: $dark-all-font-color;
                }
            }
        }
        .blog-box {
            .blog-details-date {
                background-color: $dark-body-background;
            }
            .blog-details {
                .blog-social {
                    li {
                        &:first-child {
                            border-color: $gray-60 !important;
                        }
                    }
                }
                .content-date {
                    color: $dark-small-font-color;
                }
            }
        }
        .blog-box {
            .blog-details-main {
                .blog-social {
                    li {
                        &:first-child {
                            border-color: $gray-60 !important;
                        }
                        + li {
                            border-color: $gray-60 !important;
                        }
                    }
                }
            }
        }
        .product-box {
            .product-details {
                h4 {
                    a {
                        color: $dark-all-font-color;
                    }
                }
            }
        }
        .product-box {
            .product-img {
                .product-hover {
                    ul {
                        li {
                            i {
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
        }
        .product-box {
            .modal {
                .modal-header {
                    .product-box {
                        .product-details {
                            .product-qnty {
                                fieldset {
                                    .input-group {
                                        .btn-primary {
                                            background-color: $dark-body-background !important;
                                            border: 1px solid $dark-body-background !important;
                                            color: #ddd;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-page-main {
            p {
                color: $dark-all-font-color;
            }
            .product-main {
                border-color: $dark-card-border;
            }
        }
    }
    &.dark-sidebar {
        img.for-dark.media {
            display: block;
        }
        .for-light {
            display: none;
        }
        .toggle-sidebar {
            svg {
                stroke: var(--theme-default);
            }
        }
        .page-wrapper {
            .sidebar-main-title {
                p {
                    color: $dark-all-font-color;
                }
            }
            &.compact-wrapper {
                .page-body-wrapper {
                    div.sidebar-wrapper {
                        background-color: $dark-card-background;
                        .sidebar-main {
                            .sidebar-links {
                                li {
                                    a {
                                        span {
                                            color: $dark-all-font-color;
                                        }
                                        svg {
                                            path {
                                                stroke: $dark-all-font-color;
                                            }
                                        }
                                    }
                                }
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    .sidebar-link {
                                                        &.active {
                                                            background-color: rgba($primary-color, 0.2);
                                                            svg {
                                                                color: var(--theme-default);
                                                                stroke: var(--theme-default);
                                                            }
                                                        }
                                                    }
                                                    .mega-menu-container {
                                                        .mega-box {
                                                            .link-section {
                                                                .submenu-title {
                                                                    h5 {
                                                                        color: $dark-all-font-color;
                                                                    }
                                                                }
                                                                .submenu-content {
                                                                    &.opensubmegamenu {
                                                                        li {
                                                                            a {
                                                                                color: $dark-all-font-color;
                                                                                font-weight: 400;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &::after {
                                                            background-color: $light-all-font-color;
                                                        }
                                                    }
                                                    .sidebar-submenu {
                                                        li {
                                                            a {
                                                                color: $dark-all-font-color;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .sidebar-list {
                                    ul.sidebar-submenu {
                                        li {
                                            a {
                                                span {
                                                    color: $dark-all-font-color;
                                                }
                                                &.active {
                                                    color: var(--theme-default);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .according-menu {
                        i {
                            color: $dark-all-font-color;
                        }
                    }
                }
            }
            &.horizontal-wrapper {
                .page-body-wrapper {
                    .sidebar-wrapper {
                        background: $dark-card-background;
                        .sidebar-main {
                            background-color: $dark-card-background;
                            .sidebar-links {
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                > li {
                                                    > a {
                                                        color: $dark-all-font-color;
                                                    }
                                                    .sidebar-submenu {
                                                        background: $dark-card-background;
                                                        li {
                                                            a {
                                                                color: $dark-all-font-color;
                                                                &.active,
                                                                &:hover {
                                                                    color: var(--theme-default);
                                                                }
                                                            }
                                                            .nav-sub-childmenu {
                                                                background: $dark-card-background;
                                                            }
                                                        }
                                                    }
                                                    .mega-menu-container {
                                                        background: $dark-card-background;
                                                        .mega-box {
                                                            .link-section {
                                                                .submenu-content {
                                                                    li {
                                                                        a {
                                                                            color: $dark-all-font-color;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .left-arrow,
                            .right-arrow {
                                background-color: $dark-card-background;
                                color: $dark-all-font-color;
                            }
                        }
                    }
                }
            }
        }
    }
    img.for-dark.media {
        display: none;
    }
}
// =======================  Dark Body only  ======================= //
/**=====================
  70. theme layout CSS ends
==========================**/
