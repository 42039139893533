.popper {
    > span:before {
        border-bottom-color: rgba(0, 0, 0, 0.4) !important;
    }
}

.tooltip_body {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
}
