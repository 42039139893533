.post_card > .post > .post_content > p {
    color: #2c323f;
    font-weight: 300;
    letter-spacing: normal;
    font-size: 15px;
}

.image_preview__container {
    object-fit: contain;

    .image_preview {
        height: 100%;
        width: 100%;
        object-fit: inherit;
    }
}
