.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 13px 30px !important;
    margin: 0 !important;

    .left_content {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .right_content {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 15px;

        .market_short_info__container {
            position: relative;

            .cart_icon {
                cursor: pointer;
                &:hover {
                    path {
                        stroke: var(--theme-default);
                    }
                }
            }

            .products_count {
                border-radius: 50%;
                position: absolute;
                bottom: -5px;
                right: -10px;
                padding: 3px;
                aspect-ratio: 1;
                width: 20px;
                line-height: normal;
            }
        }
    }

    .user_profile {
        width: 58px;
        height: 58px;
        cursor: pointer;
    }

    .collapse_menu {
        background-color: white;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top: 1px solid #e3e3f3;
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 280px;
        visibility: hidden;
        overflow: hidden;
        transition: all 0.2s;
        padding: 25px 35px 25px 25px;
        font-size: 16px;

        &.opened {
            visibility: visible;
        }

        .information__container {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 10px;

            .information {
                display: flex;
                flex-direction: column;
                //gap: 5px;

                .name {
                    font-size: 18px;
                    color: #6362e7;
                    cursor: pointer;
                }

                .username {
                    color: #999999;
                }
            }
        }

        > div:not(.information__container) {
            cursor: pointer;
            display: flex;
            gap: 10px;

            &:hover {
                color: #6362e7;
            }
        }
    }
}
