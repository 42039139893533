.categories_wrapper {
    margin: 10px 0;
    margin-bottom: 20px;

    .categories {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin: 10px 0;
    }
}
