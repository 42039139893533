.input__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.label {
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.textarea {
    resize: none;
    overflow-y: auto;
}

.input {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    display: block;
    font-size: 16px;
    font-weight: 400;
    font-family: Rubik, sans-serif;
    width: 100%;
    padding: 10px;
    outline: 0;
    transition: 0.2s;

    &:focus {
        border-color: #6362e7;
    }

    &.invalid {
        border-color: #dd5555;
    }
}

.error_message {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #dd5555;
}
